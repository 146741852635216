// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import Button from './buttons/ButtonA'
import logoPng from '../assets/img/flicfit-logo.png'
import titlePng from './img/try-list/title.png'
import { normalize, schema }  from 'normalizr'
import { hot } from 'react-hot-loader'
import { Review } from '../types'
import ArrowButton from './iconButtons/Arrow'
import type {RouterHistory, Match} from 'react-router-dom'
import SelectButtons from './input/SelectButtons'
import TwoSelections from './input/TwoSelections'
import Modal from './Modal'
import MemoModal from './input/MemoModal'

// $FlowFixMe, 
const { shoeReviewTags } = process.env.CONFIG
const questionId = new schema.Entity("questionId", {},   { idAttribute: 'questionId' });
const questionIds = new schema.Array(questionId);
const questions = normalize(shoeReviewTags, questionIds);
const memoQuestionId = 'fitting_log_shoe_6'


type Props = {
  appActions: Object,
  recommendActions: Object,
  item: Object,
  review: ?Review,
  history:RouterHistory,
  match: Match
} 

type State = {
  review: Review,
  selectedMemo: boolean,
  errors: Array<string>
}

class ReviewImpressions extends Component<Props, State> {
  state = {
    review: this.props.review,
    selectedMemo: false,
    errors: []
  }

  handleSelect =  (questionId: string, value: string)  => () => {
    let tmpShoeReview =  this.state.review? [...this.state.review.content.shoeReview] : []
    
    const idx =  tmpShoeReview.findIndex((e) => e.questionId == questionId)

    // エラー
    if ( idx == -1) return;

    let tmpResult =   tmpShoeReview[idx]
    if ( !tmpResult.answer) {
      // ADD
      if ( tmpResult.type == 'multi_select' ) { 
        tmpResult['answer'] =  [value]
      } else {
        tmpResult['answer'] =  value
      }
    }  else {
      if ( tmpResult.type == 'multi_select' ) { 
        if ( tmpResult.answer.includes(value) ) {
          // 削除
          tmpResult.answer = tmpResult.answer.filter(e => e !== value )
        } else{
          tmpResult.answer.push(value)
        } 
      } else {
          tmpResult.answer = value
      }
    }
    tmpShoeReview[idx] = tmpResult 
    let tmpReview = {...this.state.review}
    tmpReview.content.shoeReview  = tmpShoeReview
    this.setState({review: tmpReview })
  }

  handleSelectPart =  (questionId: string, value: 'yes' | 'no')  => () => {
    if( value == 'no' ) return
    const { recommendActions,  history} = this.props
    const itemId = this.state.review && this.state.review.itemId ?  
          this.state.review.itemId : ( this.state.review && this.state.review.unknownShoeName ? this.state.review.unknownShoeName : '')

    recommendActions.onUpdateReview(this.state.review)
    
    switch(questionId){
      case 'fitting_log_shoe_4':
        history.push(`/review/tightpart/${itemId}`)
        break;
      case 'fitting_log_shoe_5':
        history.push(`/review/blisters/${itemId}`)
        break;
      default:
      break
    }
  }

  handleBack =  ()  => () => {
    this.props.history.push('/trylist')
  }
  handleNext = () => () => {
    const { recommendActions, match: {params}, history} = this.props
    const itemId = this.state.review && this.state.review.itemId ?  
          this.state.review.itemId : ( this.state.review && this.state.review.unknownShoeName ? this.state.review.unknownShoeName : '')
    if (itemId == '')  {
      console.log("itemIdが存在しないため、次に進めない")
      return;
    }
    recommendActions.onUpdateReview(this.state.review)
    if ( !this.state.review ) return

    // required　チェック
    const errors =  this.validate()
    if ( errors.length == 0 ) {
      //history.push(`/review/complete/${itemId}`)
      // 合う/合わない選択をつけたのでcompleteはいらない
      history.push('/trylist')
    }
  }

  handleModalClose = () => {
    this.setState({ selectedMemo: false })
  }

  changeMemoText = (e: any) => {
    let tmpShoeReview =  this.state.review? [...this.state.review.content.shoeReview] : []
    
    const idx =  tmpShoeReview.findIndex((e) => e.questionId == memoQuestionId)

    // エラー
    if ( idx == -1) return;

    let tmpResult =   tmpShoeReview[idx]
    tmpResult['answer'] =  e.target.value
    tmpShoeReview[idx] = tmpResult 
    let tmpReview = {...this.state.review}
    tmpReview.content.shoeReview  = tmpShoeReview
    this.setState({review: tmpReview })
  }
  
  handleModalOpen = () => {
    this.setState({ selectedMemo: true })
  }

  validate = () => {
    if (!this.state.review) return []
    const errors = this.state.review.content.shoeReview.reduce((accumulator, e) => {
      // リコメンド・購入フラグはここではチェックしない
      if (e.questionId == 'fitting_log_shoe_7' || e.questionId == 'fitting_log_shoe_8') return accumulator
      if (e.required) {
        if ( !e.answer || e.answer.length == 0 ) {
          accumulator.push(e.questionId);
        }
      }
      return accumulator;
       }, []);
    this.setState({errors: errors})
    return errors
  }

  componentDidMount() {
    this.validate()
    /*
    */
  }

  render() {
    const { appActions, item, review} = this.props
    const {entities: { questionId } } = questions 
    const memo = this.state.review ? this.state.review.content.shoeReview.find( v => v.questionId == memoQuestionId) : {}
    let unknownShoe = null
    if (review && review.unknownShoeName) {
      unknownShoe = review.unknownShoeName.split(',');
    }
    let fitting1Values = this.state.review? 
            {...this.state.review.content.shoeReview.find( v => v.questionId == 'fitting_log_shoe_1')}: {}
    let tmpOptions = []
    if (fitting1Values.answer && fitting1Values.answer == fitting1Values.options[1] ) {
      tmpOptions.push(fitting1Values.options[1])
    } else {
      tmpOptions.push(fitting1Values.options[0])
      tmpOptions.push(fitting1Values.options[2])
    }
    fitting1Values.options = tmpOptions
    
    return (
      <Root>
        <Top>
          <Title>
             履き心地の入力
          </Title>
          <Questions>
            <dl>
            <Qtitle error={this.state.errors.includes('fitting_log_shoe_1')}>{questionId.fitting_log_shoe_1.title}</Qtitle>
            <dd>
            <SelectButtons 
              handleSelect={this.handleSelect}
              values={fitting1Values}
              width={200}
            />
            </dd>
            <Qtitle error={this.state.errors.includes('fitting_log_shoe_2')}>{questionId.fitting_log_shoe_2.title}</Qtitle>
            <dd>
            <SelectButtons 
              handleSelect={this.handleSelect}
              values={this.state.review?  
              this.state.review.content.shoeReview.find( v => v.questionId == 'fitting_log_shoe_2'): {}}
              width={200}
            />
            </dd>
            <Qtitle error={this.state.errors.includes('fitting_log_shoe_3')}>{questionId.fitting_log_shoe_3.title}</Qtitle>
            <dd>
            <SelectButtons 
              handleSelect={this.handleSelect}
              values={this.state.review?  
              this.state.review.content.shoeReview.find( v => v.questionId == 'fitting_log_shoe_3'): {}}
              width={200}
            />
            </dd>
            <Qtitle error={this.state.errors.includes('fitting_log_shoe_4')}>{questionId.fitting_log_shoe_4.title}</Qtitle>
            <dd>
            <TwoSelections 
              handleSelect={this.handleSelectPart}
              values={this.state.review?  
                this.state.review.content.shoeReview.find( v =>  v.questionId == 'fitting_log_shoe_4'): 
                {}}
              width={200}
            />
            </dd>
            <Qtitle error={this.state.errors.includes('fitting_log_shoe_5')}>{questionId.fitting_log_shoe_5.title}</Qtitle>
            <dd>
            <TwoSelections 
              handleSelect={this.handleSelectPart}
              values={this.state.review?  
                this.state.review.content.shoeReview.find( v =>  v.questionId == 'fitting_log_shoe_5'): 
                {}}
              width={200}
            />
            </dd>
            </dl>
          </Questions>
          <Thumbnail>
            { this.props.item && this.props.item.pictureUri[0] && (
            <img src={this.props.item.pictureUri[0]} alt="" />
            )}
          </Thumbnail>
          { item && (
          <Description>
            <dl>
               <dt>ブランド</dt>
               <dd>{item.brandName}</dd>
               <dt>品番</dt>
               <dd>{item.productNumber}</dd>
               <dt>サイズ</dt>
               <dd>{`${item.makerSize}${item.unit}`}</dd>
               <dt>色</dt>
               <dd>{item.makerColor}</dd>
            </dl>
          </Description>
          )}
          { unknownShoe && (
          <Description>
            <dl>
               <dt>ブランド</dt>
               <dd>{unknownShoe[0]}</dd>
               <dt>品番</dt>
               <dd>{unknownShoe[2]}</dd>
               <dt>サイズ</dt>
               <dd>{`${unknownShoe[1]}`}</dd>
               <dt>色</dt>
               <dd>{unknownShoe[3]}</dd>
            </dl>
          </Description>
          )}

          {/* 
          <Memo onClick={this.handleModalOpen}>
          <dl>
            <dt>{memo.title}</dt>
            <dd>
              {memo.answer}
            </dd>
            </dl>
          </Memo>
          */}
        </Top>
        <NextButton onClick={this.handleNext()} color="yellow" >完了</NextButton>
        <BackButton dir="previous" onClick={this.handleBack() }  color="grey2" >
          戻る
        </BackButton>

        {this.state.selectedMemo  && (
          <Modal
            handleOutsideClick={this.handleModalClose}
            render={() => (
              <MemoModal
                changeMemoText={this.changeMemoText}
                handleClose={this.handleModalClose}
                memo={memo.answer}
                isEdit={this.state.selectedMemo}
              />
            )}
          />
        )}
      </Root>
    )
  }
}

export default hot(module)(ReviewImpressions)

const Root = styled.div`
  top: 0;
  left: 0;
  background-color: #f3f3f3;
  color: #707070;
  width: 100vw;
  height: 100vh;
`

const NextButton = styled(Button)`
  position: fixed;
  bottom: 25px;
  right: 25px;
`


const BackButton = styled(Button)`
  position: fixed;
  bottom: 15px;
  left: 15px;
`

const TopButton = styled(Button)`
  position: fixed;
  bottom: 37px;
  left: 15px;
`

const Top = styled.div`
`

const Title = styled.div`
  padding: 25px 0 20px 0;
  text-align: center;
  font-size: 30px;
`

const Questions = styled.div`
  margin-left: 80px;
  > dl > dd {
    font-weight: normal;
  }
`
const Memo = styled.div`
  background-color: #fff;
  width: 360px;
  height: 130px;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: absolute;
  bottom: 108px;
  right: 66px;
  > dl > dt {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin: 0 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;

  }
  > dl > dd {
    margin: 10px;
    font-weight: normal;
  }
`
const Qtitle = styled.dt`
    color: ${({ error }) => (error ? '#f00' : '')};
    font-size: 18px;
    margin: 20px 0 5px;
    font-weight: bold;
`
const Thumbnail = styled.figure`
  position: absolute;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 0 5px;
  overflow: hidden;
  bottom: 400px;
  right: 100px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

const Description = styled.div`
  margin-left: 100px;
  position: absolute;
  bottom: 300px;
  right: 100px;
  margin: auto 0 auto 100px;
  font-size: 14px;
  font-weight: bold;
  > dl > dt {
	  float: left;
	  clear: left;
	  margin-right: 0.5em;
	  width: 60px;
  }
  > dl > dd {
	    float: left;
	    margin-left: 1em;
  }  
`