// @flow
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  children: React.Node
}

const ButtonC: React$StatelessFunctionalComponent<Props> = React.memo(
  ({ children, ...props }) => <ButtonStyle {...props}>{children}</ButtonStyle>
)

export default ButtonC

const ButtonStyle = styled.button`
  background-color: ${({ theme }) => theme.color.grey};
  font-size: 13px;
  height: 30px;
  padding: 0 16px;
  border-radius: 15px;
  white-space: nowrap;
`
