// @flow
import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import DialogMain from './main'

let resolve

interface Props {}

interface State {
  isOpen: boolean;
  message: string;
}

class Confirm extends Component<Props, State> {
  static create() {
    const containerElement = document.createElement('div')
    if (document.body) {
      document.body.appendChild(containerElement)
    }
    return render(<Confirm />, containerElement)
  }

  state = { isOpen: false, message: '' }

  constructor(props: any) {
    super(props)
  }

  handleCancel = () => {
    this.setState({ isOpen: false })
    resolve(false)
  }

  handleConfirm = () => {
    this.setState({ isOpen: false })
    resolve(true)
  }

  show = (message: string): Promise<boolean> => {
    this.setState({ message, isOpen: true })
    return new Promise(res => {
      resolve = res
    })
  }

  render() {
    const { isOpen } = this.state
    if (!isOpen) return <Fragment />
    return (
      <DialogMain
        message={this.state.message}
        onCancel={this.handleCancel}
        onConfirm={this.handleConfirm}
      />
    )
  }
}

export default Confirm
