import vert from './shader/base.vert';
import frag from './shader/makingItem.frag';

import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import glConf from './glConf';


export default class makingItem extends object3d {

  constructor(opt) {

    super(opt);

    this._container = undefined
    this._hideContainer = undefined
    this._img = undefined
    this._bg = undefined

  }


  init() {

    super.init();

    this.opt.isLast = false;
    this.opt.angle = glUtils.range(180);
    this.opt.speed = glUtils.random2(1, 2) * 1;

    this._container = new THREE.Object3D();
    this.add(this._container)

    this._hideContainer = new THREE.Object3D();
    this._container.add(this._hideContainer)

    this._img = new THREE.Mesh(
      this.opt.geo,
      new THREE.ShaderMaterial({
        transparent:true,
        vertexShader:vert,
        fragmentShader:frag,
        side:THREE.DoubleSide,
        depthTest:true,
        uniforms:{
          tDiffuse:{value:this.opt.tex},
          alpha:{value:1},
          radius:{value:0.5}
        }
      })
    );
    this._hideContainer.add(this._img);

    this.visible = false;

    this.resize();

  }


  setTex(tex) {

    this._img.material.uniforms.tDiffuse.value = tex;

  }


  setSize(val) {

    this._img.scale.set(val, val, 1);

  }


  show(opt) {

    this._container.visible = true;

    switch (opt.type) {

      case 1:
        // ぺらっと
        this._showB(opt);
        break;

      default:
        // 拡大
        this._showA(opt);
    }

  }


  _showA(opt) {

    TweenMax.killTweensOf(this._container.scale);

    const duration = 1.5;
    const ease = Elastic.easeOut.config(1, 0.3);

    this._container.visible = false;
    this._container.scale.set(glConf.MIN_SCALE, glConf.MIN_SCALE, 1)
    TweenMax.to(this._container.scale, duration, {
      x:1,
      y:1,
      delay:opt.delay,
      ease:ease,
      onStart:() => {
        this._container.visible = true;
      }
    });

  }


  _showB(opt) {

    TweenMax.killTweensOf(this._container.scale);

    const duration = 2;
    const ease = Elastic.easeOut.config(1, 0.75);

    this._container.visible = false;
    this._container.scale.set(1, 1, 1);

    this._container.rotation.x = glUtils.radian(270);
    this._container.rotation.y = glUtils.radian(270);
    TweenMax.to(this._container.rotation, duration, {
      x:0,
      y:0,
      delay:opt.delay,
      ease:ease,
      onStart:() => {
        this._container.visible = true;
      }
    });

  }


  jump(opt) {

    TweenMax.killTweensOf(this.scale);
    TweenMax.killTweensOf(this.rotation);

    const s = 2;
    TweenMax.set(this.scale, {x:1, y:1});
    TweenMax.to(this.scale, 0.5, {
      x:s,
      y:s,
      ease:Back.easeIn,
      delay:opt.delay,
      onComplete: () => {
        TweenMax.to(this.scale, 1, {
          x:1,
          y:1,
          ease:Elastic.easeOut.config(1, 0.8),
          onComplete:() => {
            opt.onComplete(this);
          }
        });
      }
    })

    let z = glUtils.radian(-360);
    TweenMax.set(this.rotation, {z:0});
    TweenMax.to(this.rotation, 0.8, {
      z:z,
      delay:opt.delay,
      ease:Power3.easeInOut
    });

  }


  hide(opt) {

    TweenMax.to(this._hideContainer.scale, 0.75, {
      x:glConf.MIN_SCALE,
      y:glConf.MIN_SCALE,
      delay:0,
      ease:Power4.easeInOut,
      onComplete:() => {
        this.reset()
        if(opt.onComplete != null) {
          opt.onComplete()
        }
      }
    });

  }


  setLast() {

    this.opt.isLast = true;

  }


  reset() {

    TweenMax.killTweensOf(this._container.scale);
    TweenMax.killTweensOf(this._hideContainer.scale);
    TweenMax.killTweensOf(this.scale);
    TweenMax.killTweensOf(this.rotation);

    this.visible = false;

    this.position.z = 0;
    this.scale.set(1,1,1);

    this.setDepthTest(true);

  }


  setDepthTest(bool) {

    this._img.material.depthTest = bool;

  }




  update() {

    super.update();



  }


  resize() {

    super.resize();



  }





}
