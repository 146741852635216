// @flow
import React from 'react'
import iconNextPng from './img/icon-next.png'
import iconPrevPng from './img/icon-prev.png'
import styled from 'styled-components'

function ButtonF({ dir, ...rest }: { dir: 'next' | 'prev' }) {
  const iconSrc = dir === 'next' ? iconNextPng : iconPrevPng
  return (
    <Root {...rest}>
      <img src={iconSrc} width="44" height="44" alt="" />
    </Root>
  )
}

export default ButtonF

const Root = styled.button``
