// @flow
import React, { Fragment, Component } from 'react'
import BeatLoader from 'react-spinners/RotateLoader'
import styled from 'styled-components'

type Props = {
  loaded: boolean,
  size?: number,
  color?: string
}

export default class ThumbnailLoading extends Component<Props> {
  render() {
    const { loaded, ...rest } = this.props
    const size = this.props.size ? this.props.size : 20
    const color = this.props.color ? this.props.color : '#fff000'
    if (loaded) return <Fragment />
    return (
      <LoadingWrapper {...rest}>
        <BeatLoader size={size} color={color} />
      </LoadingWrapper>
    )
  }
}

const LoadingWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
