// @flow
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  children: React.Node
}

const ButtonB: React$StatelessFunctionalComponent<Props> = React.memo(
  ({ children, ...props }) => <ButtonStyle {...props}>{children}</ButtonStyle>
)

export default ButtonB

const ButtonStyle = styled.button`
  height: 50px;
  background-color: ${({ theme }) => theme.color.yellow};
  padding: 0 35px;
  border-radius: 50px;
  pointer-events: auto;
  &:disabled {
    background-color: ${({ theme }) => theme.color.grey4};
    img {
      opacity: 0.2;
    }
  }
`
