// @flow
import React, { Component } from 'react'
import ScrollLock from 'react-scrolllock'
import styled from 'styled-components'

type Props = {
  handleOutsideClick: () => void,
  render: (*) => React$Element<*>
}

export default class Modal extends Component<Props> {
  render() {
    const { handleOutsideClick } = this.props

    return (
      <Root>
        <Overlay onClick={handleOutsideClick} />
        {this.props.render()}
        {/* 
        <ScrollLock />
        */}
      </Root>
    )
  }
}

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
`
