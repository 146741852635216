import webgl from './index';
import glConf from './glConf';
import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import attentionFootNextBtn from './attentionFootNextBtn';
import attentionFootPosition from './attentionFootPosition';

import readyDesc from './readyDesc'

export default class attentionFoot extends object3d {


  constructor(opt) {

    super(opt);

    this._foot = undefined

    this._desc = undefined
    this._nextBtn = undefined
    this._btn = undefined

    this.onClick = undefined
    this.onComplete = undefined

  }


  init() {
    super.init();

    this._desc = new image({
      tex:glTextures.get(glConf.PATH_IMG + 'attentionFoot_desc.png')
    })
    this._desc.init();
    this._desc.setSize(689, 58);
    this._desc.alpha(0);
    this.add(this._desc)

    this._nextBtn = new attentionFootNextBtn();
    this._nextBtn.init();
    this.add(this._nextBtn);

    this._foot = new attentionFootPosition()
    this._foot.init()
    this.add(this._foot);

    this._btn = document.createElement('div');
    this._btn.setAttribute('id', 'webgl-nextAttentionHemLengthBtn');
    this._btn.style.position = 'absolute';
    //this._btn.style.backgroundColor = '#FF0000';
    this._btn.style.opacity = 0;
    this._btn.style.display = 'none'
    this._btn.addEventListener('click', () => {
      this.hide()
      webgl.startMesurement()
    })
    document.getElementById('app-root').appendChild(this._btn);

    this.resize();

  }

    show() {
      const duration = 1;
      const delay = 0.5;
      const ease = Power4.easeInOut;

      this._foot.show()

      this._desc.fadeIn({
        duration:duration,
        delay:delay,
        ease:ease
      })
      this._btn.style.display = 'block'

  }

  // 消す
  hide() {

    document.getElementById('app-root').removeChild(this._btn)

    const duration = 1;
    const dx = -this.sw;
    const interval = 0.075;
    const ease = Power4.easeInOut;

    TweenMax.to(this._desc.position, duration, {
      x:this._desc.position.x + dx,
      delay:interval * 1,
      ease:ease
    });

    this._desc.fadeOut({
      duration:duration,
      delay:interval * 1,
      ease:ease
    });

    this._foot.hide({
      duration : duration, 
      dx : dx,
      interval : interval * 2,
      ease : ease
    });


    this._nextBtn.hide({
      delay: interval * 2.5,
      onComplete: () => {
        this._eHided()
      }
    });

  }


  _eHided() {

    if(this.onComplete != undefined) {
      this.onComplete()
    }

  }



  update() {

    super.update();
  }


  resize() {

    super.resize();

    this._btn.style.width = '270px';
    this._btn.style.height = '270px';
    this._btn.style.bottom = '96px';
    this._btn.style.right = '40px';

    this._desc.position.set( ((this.sw * 0.5 ) - 115 ) * -1, ( this.sh * 0.5 ) - 80 , 0);
    this._nextBtn.position.set( 334, -154, 0);

  }





}
