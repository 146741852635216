// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as appActions } from '../redux/modules/app'
import Login from '../components/Login'
import { selecteFavorites } from '~/redux/modules/recommend'
import { actions as authActions } from '~/redux/modules/auth'

const mapStateToProps = state => ({
  auth: state.auth
})

const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators({ ...authActions }, dispatch),
  appActions: bindActionCreators({ ...appActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
