// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as appActions } from '~/redux/modules/app'
import Recommend from '~/components/Recommend'
import {
  actions as recommendActions,
  selectItems,
  selecteFavorites
} from '~/redux/modules/recommend'
import { actions as webglActions } from '~/redux/modules/webgl'

const mapStateToProps = state => ({
  footId: state.app.footId,
  staffName: state.app.staffName,
  digitizerCode: state.app.digitizerCode,
  visitDate: state.app.visitDate,
  items: selectItems(state),
  favoriteItems: selecteFavorites(state),
  favorites: state.recommend.favorites,
  itemEntities: state.entities.recommendItems,
  measurement: state.app.measurement,
  jis: state.app.jis,
  jisWidthDisplay: state.app.jisWidthDisplay,
  tags: state.recommend.availableTags,
  nonSelectTagItems: state.recommend.nonSelectTags,
  selectedTagItems: state.recommend.selectedTags
})

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators({ ...appActions }, dispatch),
  webglActions: bindActionCreators({ ...webglActions }, dispatch),
  recommendActions: bindActionCreators({ ...recommendActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Recommend)
