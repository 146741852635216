export default (tags: Tags, items: Array<Object>) => {
  const tagsFromItems = {}
  const validatedItems = {}
  const availableTags = []

  tags.forEach((tag: Tag) => {
    tagsFromItems[tag] = []
  })
  items.forEach((item: Object) => {
    Object.keys(item).forEach((key: string) => {
      const value = item[key]
      const entityArray = tagsFromItems[key]
      if (entityArray && !entityArray.includes(value)) {
        if (key === 'heelHeight' && value === 0) {
          return
        } else if (key !== 'heelHeight' && value === 'なし') {
          return
        } else {
          entityArray.push(value)
        }
      }
    })
  })
  Object.keys(tagsFromItems).forEach(tag => {
    const value = tagsFromItems[tag]
    if (value.length > 1) {
      validatedItems[tag] = value
      availableTags.push(tag)
    }
  })
  return {
    tagItems: validatedItems,
    tagList: tags.filter(t => availableTags.includes(t))
  }
}
