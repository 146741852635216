// @flow
import React, { Component } from 'react'
import InputConfiguration from '~/components/input/Configuration'
import styled from 'styled-components'
import { Review,ReviewQnA } from '../types'
import type {RouterHistory, Match} from 'react-router-dom'

const questionId = 'fitting_log_shoe_7'
type Props = {
  appActions: Object,
  recommendActions: Object,
  history:RouterHistory,
  match: Match,
  review: ?Review
}

type State = {
}

export default class ReviewComplete extends Component<Props, State> {

  state = {
  }

  handleSelect =  (value: string)  => () => {
    // エラー（
    this.props.history.push(`/${value}`)
  }

  async componentDidMount() {
  }

  render() {
    const  options = [
      {value: "recommend", label : "Recommned"},
      {value: "", label : "TOPに戻る"},
      ]
    return (
      <Root>
      <Select>
       <Text>レビュー登録完了</Text>
       <SelectButtons>
      { options.map(( v, idx) => {
          return (
            <Item
              key={idx}
              onClick={ this.handleSelect(v.value)}
            >
              {v.label}
            </Item>
          )
      })}
       </SelectButtons>
      </Select>
      </Root>
    )
  }
}

const Root = styled.div`
  position: relative;
  background-color: #404040;
  width: 100vw;
  height: 100vh;
`


const Select = styled.div`
  position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`

const SelectButtons = styled.div`
    display: flex;
  flex-wrap: wrap;
  margin: -5px;
`



const Text = styled.p`
  margin-top: 18px;
  color: #fff;
  font-size: 18px;
  font-wiehgt: bold;
  margin-bottom: 18px;
`

const Item = styled.div`
  line-height: 40px;
  background: #fff;
  padding: 5px 10px;
  color: #707070;
  border-radius: 25px;
  margin: 5px;
  width: 200px;
  text-align: center;
  font-size: 17px;
`

