import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';


export default class introCircle extends object3d {



  constructor(opt) {

    super(opt)

    this._mesh = undefined
    this._angle = 0
    this.isRender = true
    this._angle = glUtils.randomInt(0, 360)

  }


  init() {

    super.init();

    this._mesh = new THREE.Mesh(
      this._makeGeo(0),
      new THREE.MeshBasicMaterial({
        color:this.opt.color
      })
    );
    this.add(this._mesh);

    this.resize();

  }


  scaleOut(opt) {

    const s = Math.max(this.sw, this.sh) * 2;
    TweenMax.to(this.scale, opt.duration, {
      x:s,
      y:s,
      delay:opt.delay,
      ease:Back.easeInOut.config(1),
      onComplete: () => {
        if(opt.onComplete != null) {
          opt.onComplete()
        }
      }
    })


  }

 scaleIn(opt) {

    TweenMax.to(this.scale, opt.duration, {
      x:0,
      y:0,
      delay:opt.delay,
      ease:Back.easeInOut.config(1),
      onComplete: () => {
        if(opt.onComplete != null) {
          opt.onComplete()
        }
      }
    })


  }




  update() {

    super.update();

    if(!this.isRender) {
      return;
    }

    this._angle += this.opt.speed;
    this._mesh.geometry.dispose();
    this._mesh.geometry = this._makeGeo(this._angle);

  }


  resize() {

    super.resize();

  }


  setSize(radius) {

    this.scale.set(radius * 2, radius * 2, 1);

  }


  _makeGeo(ang) {

    const arr = [];

    const radius = 0.5 + this._sin2(glUtils.radian(ang * 2)) * 0.05;

    const speed = 1;
    const num = 6;
    for(let i = 0; i < num; i++) {

      const radian = glUtils.radian((360 / num) * i);
      const radius2 = radius + this._sin2(ang * 0.05 + i * 0.5) * 0.05 * speed;
      const x = Math.sin(radian) * radius2;
      const y = Math.cos(radian) * radius2;

      arr.push(new THREE.Vector3(x, y, 0));

    }

    const curve = new THREE.CatmullRomCurve3(arr);
    curve.closed = true;

    const points = curve.getPoints(50);

    const shape = new THREE.Shape();

    for(let i = 0; i < points.length; i++) {
      const val = points[i];
      if(i === 0) {
        shape.moveTo(val.x, val.y);
      } else {
        shape.lineTo(val.x, val.y);
      }
    }

    return new THREE.ShapeBufferGeometry(shape);

  }





}
