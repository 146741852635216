
class glUtils {

  constructor() {
  }

  random(min, max) {
    return Math.random() * (max - min) + min;
  }


  random2(min, max) {
    let r = Math.random() * (max - min) + min;
    if(Math.random() > 0.5) {
      r *= -1;
    }
    return r;
  }


  randomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }


  radian(degree) {
    return degree * Math.PI / 180;
  }


  degree(radian) {
    return radian * 180 / Math.PI;
  }


  range(val) {
    return this.random(-val, val);
  }


  map(num, toMin, toMax, fromMin, fromMax){

    if(num <= fromMin) {
      return toMin;
    }

    if(num >= fromMax) {
      return toMax;
    }

    const p = (toMax - toMin) / (fromMax - fromMin);
    return ((num - fromMin) * p) + toMin;

  }


  shuffle(arr) {

    let i = arr.length;
    while(--i) {
      let j = Math.floor(Math.random() * (i + 1));
      if(i == j) {
        continue
      }
      let k = arr[i];
      arr[i] = arr[j];
      arr[j] = k;
    }

  }





}

export default new glUtils();
