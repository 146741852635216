import React from 'react'
import styled from 'styled-components'

type Props = {
  onCancel: () => void,
  onConfirm: () => void,
  message: string
}

function Dialog({ onCancel, onConfirm, message }: Props) {
  return (
    <Wrapper>
      <Root>
        <Text dangerouslySetInnerHTML={{ __html: message }} />
        <Buttons>
          <li>
            <Button onClick={onCancel}>キャンセル</Button>
          </li>
          <li>
            <Button yellow onClick={onConfirm}>
              OK
            </Button>
          </li>
        </Buttons>
      </Root>
    </Wrapper>
  )
}

export default Dialog

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Root = styled.div`
  width: 520px;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Text = styled.p`
  font-size: 20px;
  text-align: center;
  padding: 35px 0;
  letter-spacing: 0.1em;
`

const Buttons = styled.ul`
  display: flex;
  padding-bottom: 35px;
`

const Button = styled.button`
  height: 50px;
  min-width: 120px;
  font-weight: bold;
  border-radius: 25px;
  margin: 0 20px;
  background-color: ${({ yellow }) => (yellow ? '#fff000' : '#e9e9e9')};
`
