import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import glConf from './glConf';
import readyDotItem from './readyDotItem';
import readyLine2 from './readyLine2';


export default class readyOkBtn extends object3d {

  constructor(opt) {

    super(opt)

    this._container = undefined
    this._bg = undefined
    this._text = undefined
    this._line = undefined

  }


  init() {

    super.init();

    this._line = new readyLine2();
    this._line.init();
    this.add(this._line);
    this._line.position.z = -1;

    this._container = new THREE.Object3D()
    this.add(this._container);

    this._bg = new THREE.Mesh(
      new THREE.CircleBufferGeometry(0.5, 32),
      new THREE.MeshBasicMaterial({color:0xffffff})
    );
    this._container.add(this._bg);
    this._bg.scale.set(200, 200, 1);

    this._text = new image({
      tex:glTextures.get(glConf.PATH_IMG + 'ready_ok.png'),
      origin:'center'
    })
    this._text.init();
    this._text.setSize(64, 64);
    this._container.add(this._text);

     this.visible = false;

    this.resize();

  }


  show(opt) {

    const d = this.sw * 0.5;
    const bezier = [{x:d * 0.75, y:0}, {x:0, y:0}];
    const duration = 2;

    this._container.position.set(d, d, 0);
    this._container.scale.set(1,1,1)

    this.visible = true;
    this._text.alpha(1)
    this._text.fadeIn({
      duration:0.5,
      delay:0
    });

    TweenMax.to(this._container.position, duration, {
      bezier:{values:bezier},
      ease:Power4.easeInOut,
      delay:opt.delay,
      onComplete: () => {
        if(opt.onComplete != null) {
          opt.onComplete()
        }
      }
    });

    this._line.show({
      d:d,
      duration:duration,
      ease:Power4.easeInOut,
      delay:opt.delay,
      bezier:bezier
    });

  }


  hide(opt) {

    this._text.fadeOut({
      duration:0.5,
      delay:opt.delay
    });

    const s = glConf.MIN_SCALE;
    TweenMax.to(this._container.scale, 0.75, {
      x:s,
      y:s,
      delay:opt.delay,
      ease:Back.easeIn.config(1),
      onComplete: () => {
        this.visible = false;
        if(opt.onComplete != null) {
          opt.onComplete()
        }
      }
    })

  }



  update() {

    super.update();

  }


  resize() {

    super.resize();

  }

}
