import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';

export default class makingFinish extends object3d {

  constructor(opt) {

    super(opt)

    this._mesh = undefined
    this._angle = 0
    this.isRender = false
    this._angle = glUtils.randomInt(0, 360)
    this._rate = 0

  }


  init() {

    super.init();

    this._mesh = new THREE.Mesh(
      this._makeGeo(0),
      new THREE.MeshBasicMaterial({
        color:this.opt.color
      })
    );
    this.add(this._mesh)

    this.visible = false

    this.resize()

  }


  hide(opt) {

    this.isRender = true
    this.visible = true

    TweenMax.to(this, 0.5, {
      _rate:1,
      delay:0,
      ease:Power4.easeInOut
    })

    TweenMax.to(this.position, 2, {
      y:window.innerHeight * 1.5,
      delay:0,
      ease:Power4.easeInOut,
      onComplete:() => {
        if(opt.onComplete != null) {
          opt.onComplete()
        }
      }
    })

    this.update()

  }


  update() {

    super.update();

    if(!this.isRender) {
      return
    }

    this._angle += 3
    this._mesh.geometry.dispose()
    this._mesh.geometry = this._makeGeo(this._angle)

  }


  resize() {

    super.resize();

  }


  _makeGeo(ang) {

    const arr = []

    const sw = window.innerWidth
    const sh = window.innerHeight

    const radius = sh * 0.1

    const num = 8
    for(let i = 0; i < num; i++) {

      const radian = glUtils.radian(ang * 2 + (180 / num) * i);
      const radius2 = (radius * 0 + Math.sin(ang * 0.005 + i * 0.05) * radius * 1) * this._rate
      const x = (sw / (num - 1)) * i - sw * 0.5
      const y = -sh * 0.5 + glUtils.map(Math.sin(glUtils.radian(glUtils.map(i, 0, 180, 0, num - 1))), 0, Math.sin(radian) * radius2, 0, 1)

      arr.push(new THREE.Vector3(x, y, 0))

    }

    const curve = new THREE.CatmullRomCurve3(arr)
    const points = curve.getPoints(50)
    const shape = new THREE.Shape()

    for(let i = 0; i < points.length; i++) {
      const val = points[i];
      if(i === 0) {
        shape.moveTo(val.x, val.y)
      } else {
        shape.lineTo(val.x, val.y)
      }
    }

    // shape.lineTo(sw * 0.5, -sh * 0.5)
    shape.lineTo(sw * 0.5, sh * 0.5)
    shape.lineTo(sw * -0.5, sh * 0.5)
    shape.lineTo(sw * -0.5, -sh * 0.5)

    return new THREE.ShapeBufferGeometry(shape)

  }

}
