
class glType {

  constructor() {

    this.SCENE = {
      INTRO:0,
      ATTENTIONFOOT:2,
      ATTENTIONHEM:1,
      READY:3,
      MAKING:4,
      SHOES:6,
      NONE:5,
      RECOMMEND:5
    };

  }

}

export default new glType();
