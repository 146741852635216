// @flow
import React, { Component } from 'react'
import styled from 'styled-components'

export default class IntroCanvas extends Component<{}> {
  shouldComponentUpdate() {
    return false
  }

  render() {
    return (
      <Canvas
        id="canvas"
        width={window.innerWidth}
        height={window.innerHeight}
      />
    )
  }
}

const Canvas = styled.canvas`
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
`
