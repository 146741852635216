// @flow
import React from 'react'
import styled from 'styled-components'
import { Fit, BrandName } from '../../types'
import label0Png from '~/assets/img/label-good.png'
import CartPng from '../iconButtons/img/cart.png'
import { addComma } from '~/helpers/number'
import CheckButton from '../iconButtons/Check'
import CloseButton from '../iconButtons/Close'
import TryButton from '../iconButtons/Try'

type Props = {
  data: {
    itemId: ?number,
    unknownShoeName: ?string,
    maker: BrandName,
    productNumber: string,
    size: string,
    color: string,
    price: ?number,
    width: ?string,
  },
  image: ?string,
  purchase: boolean,
  handlePurchase: (itemId: string ) => () => void
}

function Product({ data,  image, purchase, handlePurchase }: 
Props) {
  const {
    itemId,
    unknownShoeName,
    maker,
    productNumber,
    size,
    color,
    price,
    width
  } = data
  const id = itemId? `${itemId}`: ( unknownShoeName ?  unknownShoeName : '')

  return (
    <Root>
      <Item>
      <Thumbnail>
        <img src={(image)? image: label0Png} alt="" />
      </Thumbnail>
      <Description>
        <dl>
          <dt>ブランド</dt>
          <dd>{maker}</dd>
          <dt>品番</dt>
          <dd>{productNumber}</dd>
          <dt>サイズ</dt>
          <dd>{size}{(width)? `(${width})`: ``}</dd>
          <dt>色</dt>
          <dd>{color}</dd>
          { price &&
          <dt>価格</dt>
          }
          { price &&
          <dd>
            <Price>
              <span>{addComma(price)}</span>
            </Price>
          </dd>
          }
        </dl>
      </Description>
      <StyledTryButton onClick={handlePurchase(id)} 
        color={purchase? 'yellow': 'grey'}
      >
        <img src={CartPng} width="170" alt="" />
      </StyledTryButton>
      </Item>
    </Root>
  )
}

export default Product

const Root = styled.div`
  position: relative;
  display: flex;
`    
const Item = styled.div`
  display: flex;
  background-color: #fff;
  border-radius: 60px;
  width: 760px;
`    

const StyledCheckButton = styled(CheckButton)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: -80px;
`
const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: -80px;
`
const StyledTryButton = styled(TryButton)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: -1px;
`

const Thumbnail = styled.figure`
  position: relative;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 0 5px;
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

const Label = styled.div`
  text-align: center;
  width: 130px;
  margin: 0 auto;
  position: relative;
  top: 15px;
`

const Description = styled.div`
  margin-left: 100px;
  margin: auto 0 auto 100px;
  font-size: 14px;
  font-weight: bold;
  > dl > dt {
	  float: left;
	  clear: left;
	  margin-right: 0.5em;
	  width: 60px;
  }
  > dl > dd {
	    float: left;
	    margin-left: 1em;
  }  
`

const Price = styled.div`
  span {
    font-size: 17px;
    font-family: ${({ theme }) => theme.font.english};
    &:before {
      content: '¥';
    }
  }
  &:after {
    content: '(税抜)';
    font-size: 10px;
    margin-left: 2px;
  }
`
