
class glConf {

  constructor() {

    this.IS_PREVIEW = true
    this.PATH_IMG = '/img/webgl/'
    this.MIN_SCALE = 0.0000001
    this.CMN_RADIUS = 0.235107422
    this.NUM_ITEM = 50

    this.PEAK_INTERVAL = 80
    this.SCENE_INTERVAL = 3

    this.KEY_COLOR = 0xfff000

    this.BASE_CANVAS_WIDTH = 1024
    this.BASE_CANVAS_HEIGHT = 768

  }

}

export default new glConf();
