// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import webgl from '~/webgl'
import Menu from './HomeMenu'
import Modal from './Modal'
import helpPng from '~/assets/img/help.png'
import IconClose from '~/components/iconButtons/Close'

type Props = {
  appActions: Object,
  fetchDigitizer: () => Promise<any>,
  fetchInitialize: (digitizerCode: string) => Promise<any>,
  registerWebglData: (data: {
    shoeImages: Array<string>,
    target: Array<string>
  }) => void,
  shoeImages: Array<string>,
  introImages: Array<string>
}

type State = {
  help: boolean
}

export default class Home extends Component<Props, State> {
  state = {
    help: false
  }

  handleHelpClose = () => {
    this.setState({ help: false })
  }

  async initializeWebgl() {
    const { shoeImages, introImages } = this.props
    // WebGLの初期化
    const $canvas = document.getElementById('canvas')
    webgl.initialize({
      el: $canvas
    })
    await webgl.preload({ shoeImages, introImages }).catch(err => {
      console.error(err)
    })
    // ローディング停止
    this.props.appActions.hideLoading()
    // イントロ開始
    webgl.start()
  }

  async componentDidMount() {
    const {
      digitizerCode
    } = await this.props.fetchDigitizer()
    const { clientCode,shoeImages, tags, target, jisWidthDisplay, karteDataLevel } = await this.props.fetchInitialize(digitizerCode)
    // デジタイザコードとクライアントコードをstoreに登録
    this.props.appActions.setDigitizerAndClientCode({
      digitizerCode,
      clientCode,
      jisWidthDisplay,
      karteDataLevel
    })
    // webglの描画に必要な情報をstoreに登録
    this.props.registerWebglData({
      shoeImages,
      target
    })
    this.initializeWebgl()
    this.props.appActions.dataLayerPush({ pageID: 'home' })
  }

  render() {
    return (
      <Root>
        {/* {loggedIn && (
          <LogoutButton onClick={this.handleLogout}>ログアウト</LogoutButton>
        )} */}
        <HomeMenu
          onHelp={() => {
            this.setState({ help: true })
            this.props.appActions.dataLayerPush({ pageID: 'modal-help' })
          }}
        />
        {this.state.help && (
          <Modal
            handleOutsideClick={this.handleHelpClose}
            render={() => (
              <Help>
                <CloseButton onClick={this.handleHelpClose} />
                <img src={helpPng} width={750} alt="" />
              </Help>
            )}
          />
        )}
      </Root>
    )
  }
}

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const HomeMenu = styled(Menu)`
  position: fixed;
  bottom: 15px;
  right: 25px;
`

const Help = styled.div`
  position: relative;
`

const CloseButton = styled(IconClose)`
  position: absolute;
  top: 12px;
  right: 12px;
`

// const LogoutButton = styled(Button)`
//   position: fixed;
//   top: 15px;
//   left: 15px;
// `
