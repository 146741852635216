// @flow
import { cloudApi } from '~/api'
import humps from 'humps'
import { actions as appActions } from './app'

// Actions
export const fetchInitialize = (digitizerCode: string) => async (
  dispatch: any
) => {
  return new Promise(async (resolve, reject) => {
    const { data } = await cloudApi({
      method: 'get',
      url: `/storeapp/${digitizerCode}/init`
    })
    if (data.statusCode === 200) {
      const { shoeImages, tags, storeInfo } = data.data
      const {code} = data.data.clientInfo
      resolve({ 
        clientCode:code, 
        shoeImages, 
        tags ,
        target: (storeInfo.target? storeInfo.target: []), 
        jisWidthDisplay:  ('jisWidthDetailDisplay' in  storeInfo) ? storeInfo.jisWidthDetailDisplay: true,
        karteDataLevel: ('karteDataLevel' in  storeInfo) ? storeInfo.karteDataLevel: "1"  
      })

    } else {
      console.error(data)
      //dispatch(appActions.networkError(data.message))
      dispatch(appActions.networkError("初期設定がされていません。<br/>サポートセンターまでご連絡下さい。</br>"))
      reject()
    }
  })
}



