import React, { Component } from 'react'
import styled from 'styled-components'
import Button from './buttons/ButtonA'
import type {RouterHistory} from 'react-router-dom';

type Props = {
  authActions: Object,
  appActions: Object,
  auth: Object,
   history:RouterHistory
}

type State ={
  firstLoad : boolean,
  email: string,
  password: strng
}


export default class Login extends Component {
  state = {
    firstLoad : false,
    email: '',
    password: ''
  }
  nextStep = async () => {
    const  {jwt} = await this.props.authActions.Login({
      mailAdress: this.state.email , password: this.state.password 
    })
    if (jwt) { 
    if ( this.props.auth.redirectURL ) {
      this.props.history.push(this.props.auth.redirectURL)
    } else {
      this.props.history.push('/')
    }
    }

  }

  changeText = (e: any) => {
    this.setState({email: e.target.value});
  }
    
  changeText = (e: any) => {
    this.setState({email: e.target.value});
  }

  changeTextPswd = (e: any) => {
    this.setState({password: e.target.value});
  }
   async componentDidMount() {
    const { authActions, appActions } = this.props
    try {

    const { token } = await authActions.checkLsToken()
    console.log("checkLSToken --> ", token)
    if ( !token ) return
    if ( this.props.auth.redirectURL ) {
      this.props.history.push(this.props.auth.redirectURL)
    } else {
      this.props.history.push('/')
    }
    } catch {

    }
    this.setState({firstLoad: true})
    appActions.hideLoading()

  }

  render() {
    const errorFlg = false
    
    return (
      <Root>
        {this.state.firstLoad && 
        <Wrapper>
            <Text>LOGIN</Text>
            {errorFlg && 
            <ErrorMsg>{error}</ErrorMsg>
            }
          <InputTextBox>
            <Input>
            <InputTitle>MailAddress:</InputTitle>
            <InputText type="text" name="text" value={this.state.email}
                  onChange={this.changeText}
                />
            </Input>
            <Input>
            <InputTitle>Password:</InputTitle>
            <InputText type="text" name="text" value={this.state.passwrd}
                  onChange={this.changeTextPswd}
                  placeholder='パスワード入力'
                />
            </Input>
          </InputTextBox>

          <ButtonBox>
            <LoginButton dir="next" onClick={this.nextStep} >
              LOGIN
            </LoginButton>
          </ButtonBox>
        </Wrapper>
        }
      </Root>
    )
  }
}

const Root = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit- transform: translateY(-50%) translateX(-50%);
  background-color: #f3f3f3;
  justify-content: center;
  align-items: center;
`

const Wrapper = styled.div`
  text-align: center;
`



const InputTextBox = styled.div`
`

const Text = styled.p`
  margin-top: 18px;
  color: ${({ color }) => (color ? color : '#707070')};
  font-size: 18px;
  font-wiehgt: bold;
  margin-bottom: 18px;
`
const ErrorMsg = styled.p`
  margin-top: 10px;
  color: #f00;
  font-size: 12px;
  margin-bottom: 10px;
`
const Input = styled.div`
  display: flex;
  height:60px;
  margin: 10px  0  ;
`
const InputTitle = styled.p`
 line-height: 60px;
 width: 100px;
`
const InputText = styled.input`
  border:none;
  border-radius:30px;
  box-shadow: none;
  padding: 2px 8px;
  font-size: 30px;
  width: 640px;
  height: 60px;
  text-align: center;
  `

const ButtonBox = styled.div`
`

const LoginButton = styled(Button)`
`


