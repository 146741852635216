import React, { Fragment } from 'react'
import styled from 'styled-components'
import FitImg from './img/review_fit.png'
import BadImg from './img/review_bad.png'

type Props = {
  makerSize: string,
  unit: string,
  fit: any,
  size: any,
  sizeOther: any,
  memo: any,
  star:  any
}

function ReviewComment ({ makerSize, unit, fit, size, sizeOther, memo, star, ...rest }: Props) {
  const fitFlg = ( fit.answer &&  fit.answer == '良い') ?  true: false ;
  return (
        <Root  {...rest}>
          <Title>
            {fit && 
              <img src={FitImg} alt="" />
            }
            {!fit && 
              <img src={BadImg} alt="" />
            }
            {/*
            <FitSize>{size.answer ? size.answer: (sizeOther.sizeOther? sizeOther.sizeOther : '')}</FitSize>
             */}
            <FitSize>{makerSize}{unit}</FitSize>
          </Title>
          <Answer>
          <div>
          <Star>{star.answer}</Star>
          <Memo>{memo.answer}</Memo>
          </div>
          </Answer>
        </Root>
  )
}

export default ReviewComment

const Root = styled.div`
  display: flex;
  margin: 10px;
`
const Title = styled.div`
  position: relative; 
	margin: 5px 10px;
  width:100px;
`
const FitSize = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 800;
  font-size: 15px;
`

const Answer = styled.div`
  display: -webkit-flex;
  display: flex;
  width: 100%;
`

const Star = styled.div`
  font-size: 15px;
  /*
  color: ${({ theme }) => theme.color.yellow};
  */
  color: #ffd10f;
  font-size: 20px;
`
const Memo  = styled.div`
  font-size: 12px;
`