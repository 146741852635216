import vert from './shader/base.vert';
import frag from './shader/dest.frag';

import glConf from './glConf';
import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import glParam from './glParam';
import glType from './glType';
import capture from './capture';
import webgl from './index'
import makingItemMgr from './makingItemMgr';
import makingLoading from './makingLoading';
import makingItem from './makingItem';
import makingSceneA from './makingSceneA';
import makingSceneB from './makingSceneB';
import makingSceneC from './makingSceneC';
import makingSceneD from './makingSceneD';
import makingSceneE from './makingSceneE';
import makingSceneF from './makingSceneF';
import makingSceneG from './makingSceneG';
import makingSceneH from './makingSceneH';
import makingFinish from './makingFinish';


export default class making extends object3d {



  constructor(opt) {

    super(opt);

    this._itemMgr = undefined

    this._itemsDestScene = undefined
    this._itemsDest = undefined

    this._scene = undefined
    this._showScene = undefined
    this._nowScene = undefined
    this._finish = undefined
    this._loading = undefined
    this._isShowed = undefined
    this._peakCnt = undefined

    this._isHide = false

    this._isPreRender = false
    this._isReady = false

    this.onStartFinish = undefined
    this.onComplete = undefined

  }


  init() {

    super.init();

    this._isShowed = false;
    this._peakCnt = 0;
    this._itemsDestScene = new capture();
    this._itemsDestScene.init();

    this._itemsDest = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(1, 1),
      new THREE.ShaderMaterial({
        transparent:true,
        vertexShader:vert,
        fragmentShader:frag,
        uniforms:{
          tDiffuse:{value:this._itemsDestScene.texture()},
          alpha:{value:1}
        }
      })
    );
    this.add(this._itemsDest);

    this._finish = new makingFinish({
      color:glConf.KEY_COLOR
    })
    this._finish.init()
    this.add(this._finish)
    this._finish.position.z = 1

    this._loading = new makingLoading();
    this._loading.init()
    this.add(this._loading);
    this._loading.position.z = 1.1;

    // ここで大量のアイテムを作って、各シーンはこのオブジェクトからアイテムを引っ張ってくるようにする
    this._itemMgr = new makingItemMgr({
      images:webgl.preloadImages.shoeImages
    });
    this._itemMgr.init();

    this._nowScene = 0;
    this._scene = [];

    this._addScene('A');
    this._addScene('B');
    this._addScene('D'); // よこならぶ
    this._addScene('E'); // たてならぶ
    this._addScene('F'); // 3Dぐるぐる
    this._addScene('H');

    // glUtils.shuffle(this._scene);


    this._changeScene();

    // this.show({
    //   delay:1
    // });

    this.resize();

  }



  _addScene(type) {

    let scene;
    switch (type) {

      case 'A':
        scene = new makingSceneA({itemMgr:this._itemMgr, loading:this._loading});
        break;

      case 'B':
        scene = new makingSceneB({itemMgr:this._itemMgr, loading:this._loading});
        break;

      case 'C':
        scene = new makingSceneC({itemMgr:this._itemMgr, loading:this._loading});
        break;

      case 'D':
        scene = new makingSceneD({itemMgr:this._itemMgr, loading:this._loading});
        break;

      case 'E':
        scene = new makingSceneE({itemMgr:this._itemMgr, loading:this._loading});
        break;

      case 'F':
        scene = new makingSceneF({itemMgr:this._itemMgr, loading:this._loading});
        break;

      case 'G':
        scene = new makingSceneG({itemMgr:this._itemMgr, loading:this._loading});
        break;

      case 'H':
        scene = new makingSceneH({itemMgr:this._itemMgr, loading:this._loading});
        break;

      default:
        scene = new makingSceneA({itemMgr:this._itemMgr, loading:this._loading});

    }

    scene.init();
    this._itemsDestScene.add(scene);
    scene.visible = false;
    this._scene.push(scene);

  }


  show(opt) {

    this._resetScene();
    this._isShowed = false;

    this._loading.show({
      delay:opt.delay,
      onComplete:() => {
        this._cnt = 0;
        this._isShowed = true;
        this._nowScene = 0;
        this._changeScene();
      }
    });

    this._isPreRender = opt.isPre

  }


  hide(opt) {

    if(this._isHide || !this._isShowed) {
      return
    }
    this._isHide = true

    if(this._showScene != null) {
      this._showScene.hide({
        onComplete:() => {
          this._loading.hide({
            onComplete:() => {
              this._itemsDest.visible = false
              if(this.onStartFinish != null) {
                this.onStartFinish()
              }
              this._finish.hide({
                onComplete:this.onComplete
              })
            }
          })
        }
      })
    }

  }


  update() {

    if(!glParam.isRender[glType.SCENE.MAKING]) {
      return;
    }

    super.update();

    if(!this._isHide && this._isShowed && this._cnt % glConf.PEAK_INTERVAL == 0) {
      if(this._peakCnt >= glConf.SCENE_INTERVAL) {
        this._changeScene();
      } else {
        this._showScene.peakAction();
        this._peakCnt++;
      }
    }

    webgl.renderer.setClearColor(glConf.KEY_COLOR, 1);
    this._itemsDestScene.render(webgl.renderer, webgl.camera);

  }


  _changeScene() {

    this._peakCnt = 0;

    this._resetScene();
    const scene = this._scene[this._nowScene];
    scene.start();
    this._showScene = scene;

    this._nowScene++;
    if(this._nowScene >= this._scene.length) {
      this._nowScene = 0;
    }
    
    if(!this._isReady && !this._isPreRender && this._nowScene > 1) {
      this._isReady = true
      webgl.dispatchReadyMaking()
    }


  }


  _resetScene() {

    for(let i = 0; i < this._scene.length; i++) {
      this._scene[i].reset();
    }

  }


  resize() {

    super.resize();

    this._itemsDestScene.size(this.sw, this.sh);
    this._itemsDest.scale.set(this.sw, this.sh, 1);

  }

}
