// @flow
import React from 'react'
import styled from 'styled-components'

import { Fit, BrandName } from '../../types'
import label0Png from '~/assets/img/label-good.png'
import label1Png from '~/assets/img/label-yuttari.png'
import label2Png from '~/assets/img/label-shikkari.png'
import { addComma } from '~/helpers/number'
import { Review } from '../../types'
import CheckButton from '../iconButtons/Cart'
import CloseButton from '../iconButtons/Close'
import ZoomButton from '../iconButtons/Zoom'
import TryButton from '../iconButtons/Try'
import Button from '../buttons/ButtonA'
import FitButton from '../buttons/ButtonG'

// $FlowFixMe
const { shoeReviewTags  } = process.env.CONFIG

const labels = {
  fit: label0Png,
  loose: label1Png,
  tight: label2Png
}

type Props = {
  data: {
    itemId: number,
    maker: BrandName,
    productNumber: string,
    size: string,
    color: string,
    price: number,
    width: string,
    name: string,
    material: string
  },
  label: Fit,
  image: string,
  handleFitStart: (itemId: string, value: string ) => () => void,
  handleUnfitStart: (itemId: string, value: string ) => () => void,
  handleDetailStart: (uuid: string ) => () => void,
  handleAddTrylist: (itemId: number ) => () => void,
  handleDeleteTrylist: (itemId: number ) => () => void,
  handlePurchase: (itemId: number ) => () => void,
  review:  Review,
  purchase:  boolean
}

function Product({ data, label, image, handleAddTrylist, handleFitStart, handleUnfitStart, handleDetailStart ,handleDeleteTrylist, handlePurchase, review, purchase }: 
Props) {
  const {
    itemId,
    maker,
    productNumber,
    size,
    color,
    price,
    width,
    name,
    material
  } = data
  let  recommendReviewAnswer = null
  if ( review) {
    // 合う, 合わない
    const recommendReview = review.content.shoeReview.find(e => e.questionId == 'fitting_log_shoe_7')
    recommendReviewAnswer = recommendReview.answer? recommendReview.answer: ''

  } 
  // 買う買わないフラグ
  const purchaseReviewAnswer = purchase == true ? 'on': 'off'

  const reviewTags = JSON.parse(JSON.stringify(shoeReviewTags))
  const recommendReviewTag = reviewTags.find(e => e.questionId == 'fitting_log_shoe_7')

  return (
    <Root>
     <StyledCheckButton dir={purchaseReviewAnswer} color="#f00" onClick={handlePurchase(itemId)} />
      <Item>
      {/** 
      {label != null && (
        <Label>
          <img src={labels[label]} width={107} alt="" />
        </Label>
      )}
      */}
      <Thumbnail>
        <img src={image} alt="" />
      </Thumbnail>
      <StyledZoomButton  onClick={handleAddTrylist(itemId)} />
      <Size>{`${size}(${width})`}</Size>
      <Description>
        <dl>
          <dt>ブランド</dt>
          <dd>{maker}</dd>
          <dt>品番</dt>
          <dd>{productNumber}</dd>
          {/* 
          <dt>サイズ</dt>
          <dd>{`${size}(${width})`}</dd>
          */}
          <dt>色</dt>
          <dd>{color}</dd>
          <dt>価格</dt>
          <dd>
            <Price>
              <span>{addComma(price)}</span>
            </Price>
          </dd>
        </dl>
      </Description>
      <StyledFitButtons>
      <StyledFitButton onClick={handleFitStart(`${itemId}`, `${recommendReviewTag.options[0]}`)} color={(recommendReviewAnswer &&  recommendReviewTag.options[0] == recommendReviewAnswer ) ? 'yellow': 'gray'} >
        {recommendReviewTag.options[0]}
      </StyledFitButton>
      <StyledFitButton onClick={handleUnfitStart(`${itemId}`, `${recommendReviewTag.options[1]}`)} color={(recommendReviewAnswer && recommendReviewTag.options[1] == recommendReviewAnswer) ? 'yellow': 'gray'} >
        {recommendReviewTag.options[1]}
      </StyledFitButton>
      </StyledFitButtons>

      {/*
      { recommendReviewAnswer == null && 
      <StyledDetailButton onClick={handleDetailStart(`${itemId}`)} color='gray2' >
          詳細
      </StyledDetailButton>
      }
      { recommendReviewAnswer != null && 
      <StyledDetailButton onClick={handleDetailStart(`${itemId}`)} color='gray2' disabled={true}>
          詳細
      </StyledDetailButton>
      }
      */}

      </Item>
     <StyledCloseButton dir="on" onClick={handleDeleteTrylist(itemId)} />
    </Root>
  )
}

export default Product

const Root = styled.div`
  position: relative;
  display: flex;
`    
const Item = styled.div`
  display: flex;
  background-color: #fff;
  border-radius: 60px;
  width: 760px;
`    
const Size = styled.div`
  position: absolute;
  top: 50%;
  left: 160px;
  transform: translate(0, -50%);
  font-size: 14px;
  font-weight: bold;
`    

const StyledCheckButton = styled(CheckButton)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: -80px;
`
const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: -80px;
`
const StyledZoomButton = styled(ZoomButton)`
  position: absolute;
  top: 80px;
  left: 100px;
`
const StyledTryButton = styled(TryButton)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: -1px;
`
const StyledDetailButton = styled(FitButton)`
  position: absolute;
  width: 270px;
  bottom: 10px;
  right: 30px;
`
const StyledFitButtons = styled.div`
  position: absolute;
  right: 30px;
  top: 10px;
`

const StyledFitButton = styled(FitButton)`
  margin: 0 5px;
`

const Thumbnail = styled.figure`
  position: relative;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 0 5px;
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

const Label = styled.div`
  text-align: center;
  width: 130px;
  margin: 0 auto;
  position: relative;
  top: 15px;
`

const Description = styled.div`
  margin-left: 120px;
  margin: auto 0 auto 120px;
  font-size: 14px;
  font-weight: bold;
  > dl > dt {
	  float: left;
	  clear: left;
	  margin-right: 0.5em;
	  width: 60px;
  }
  > dl > dd {
	    float: left;
	    margin-left: 1em;
  }  
`

const Price = styled.div`
  span {
    font-size: 17px;
    font-family: ${({ theme }) => theme.font.english};
    &:before {
      content: '¥';
    }
  }
  &:after {
    content: '(税抜)';
    font-size: 10px;
    margin-left: 2px;
  }
`
