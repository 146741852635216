import glConf from './glConf';
import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glParam from './glParam';
import glType from './glType';
import introCircle from './introCircle';


export default class introStartBtn extends object3d {

  constructor(opt) {

    super(opt);

    this._text = undefined
    this._circle = []

  }


  init() {

    super.init();

    for(let i = 0; i < 2; i++) {
      const mesh = new introCircle({
        color:[0xfff88b, 0xfff000][i],
        speed:0.75
      });
      mesh.init();
      this.add(mesh);
      this._circle.push(mesh);
    }

    this._text = new image({
      tex:glTextures.get(glConf.PATH_IMG + 'intro_1.png'),
      origin:'center'
    })
    this._text.init();
    this._text.setSize(256, 256);
    this._text.position.z = 1;
    this.add(this._text);

    this.resize();

  }


  hide(opt) {
    const duration = 1;
    const dx = -this.sw;
    const interval = 0.075;
    const ease = Power4.easeInOut

    // STARTのテキストを消す
    TweenMax.to(this._text.position, duration, {
      x:this._text.position.x + dx,
      delay:opt.delay,
      ease:ease
    });

    this._text.fadeOut({
      duration:duration,
      delay:opt.delay,
      ease:Power2.easeOut
    });
    

    /*
    this._text.fadeOut({
      duration:0.5,
      delay:opt.delay + 0.4,
      ease:Power2.easeOut
    })
    */

    for(let i = 0; i < this._circle.length; i++) {
      if(i == this._circle.length - 1) {
        TweenMax.to(this._circle[i].position, duration, {
          x:this._circle[i].position.x + dx,
          delay:opt.delay,
          ease:ease,
          onComplete:() => {
            if(opt.onComplete != null) {
              opt.onComplete()
            }
          }
        });
      } else { 
        TweenMax.to(this._circle[i].position, duration, {
          x:this._circle[i].position.x + dx,
          delay:opt.delay,
          ease:ease
        });

      }
    }
    // ここを他のテキストなどの演出に変更する。
    /*
    for(let i = 0; i < this._circle.length; i++) {

      if(i == this._circle.length - 1) {

        this._circle[i].scaleOut({
          duration:1.25,
          delay:opt.delay + i * 0.1,
          onComplete:() => {
            if(opt.onComplete != null) {
              opt.onComplete()
            }
          }
        })

      } else {

        this._circle[i].scaleOut({
          duration:1.25,
          delay:opt.delay + i * 0.1
        })

      }

    }
    */

  }


  update() {

    super.update();

    const isRender = glParam.isRender[glType.SCENE.INTRO];
    const len = this._circle.length;
    for(let i = 0; i < len; i++) {
      this._circle[i].isRender = isRender;
    }

  }


  resize() {

    super.resize();

    for(let i = 0; i < this._circle.length; i++) {
      this._circle[i].setSize(135);
    }

  }





}
