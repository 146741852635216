// @flow
import React from 'react'
import styled from 'styled-components'
import zoomImg from './img/zoom.png'

type Props = {}

function Zoom({ ...props }: Props) {
  return (
    <Icon {...props}>
      <img src={zoomImg} alt="" width={44} height={44} />
    </Icon>
  )
}

export default Zoom

const Icon = styled.button`
  width: 44px;
  height: 44px;
`
