// @flow
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch} from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { ThemeProvider } from 'styled-components'
import { actions as appActions } from './redux/modules/app'
import Loading from './components/Loading'
import ErrorDialog from './components/ErrorDialog'
import AutoRefresh from './components/AutoRefresh'
import IntroCanvas from './components/IntroCanvas'

import StaffContainer from './containers/StaffContainer'
import FootidContainer from './containers/FootidContainer'
import FootidRevisitContainer from './containers/FootidRevisitContainer'
import DigitizerContainer from './containers/DigitizerContainer'
import RecommendContainer from './containers/RecommendContainer'
import TryListContainer from './containers/TryListContainer'
import PurchaseContainer from './containers/PurchaseContainer'
import ReviewImpressionsContainer from './containers/ReviewImpressionsContainer'
import ReviewTightpartContainer from './containers/ReviewTightpartContainer'
import ReviewBlisterContainer from './containers/ReviewBlisterContainer'
import ReviewCompleteContainer from './containers/ReviewCompleteContainer'
import FootSurveyContainer from './containers/FootSurveyContainer'
import KarteContainer from './containers/KarteContainer'
import LoginContainer from './containers/LoginContainer'

import HomeContainer from './containers/HomeContainer'
import MeasurementContainer from './containers/MeasurementContainer'
import MeasurementReadyContainer from './containers/MeasurementReadyContainer'
import AttentionFootPositionContainer from './containers/AttentionFootPositionContainer'
import AttentionHemLengthContainer from './containers/AttentionHemLengthContainer'



import GlobalStyle from './components/GlobalStyle'
import theme from './theme'
import { actions as recommendActions } from './redux/modules/recommend'
import type { AppState } from './redux/modules/app'
import PrivateRoute from './containers/PrivateRoute'

type Props = {
  app: AppState,
  handleRefresh: Function,
  refresh: boolean,
  loggedIn: boolean,
  appActions: Object
}

// $FlowFixMe
const { refreshTimer } = process.env.CONFIG

class App extends Component<Props, {}> {

  render() {
    const {
      app: { loading, networkError },
      appActions
    } = this.props
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <GlobalStyle />
          <Fragment>
          <Switch>
              <Route exact path="/login" component={LoginContainer} />
              <PrivateRoute exact path="/" component={FootidContainer} />
              <PrivateRoute exact path="/staff" component={StaffContainer} />
              <PrivateRoute exact path="/digitizer" component={DigitizerContainer} />
              <PrivateRoute exact path="/footid" component={FootidContainer} />
              <PrivateRoute exact path="/footidRevisit" component={FootidRevisitContainer} />
              <PrivateRoute exact path="/recommned" component={RecommendContainer} />
              <PrivateRoute exact path="/trylist" component={TryListContainer} />
              <PrivateRoute exact path="/purchase" component={PurchaseContainer} />
              <PrivateRoute path="/review/footsurvey" component={FootSurveyContainer} />
              <PrivateRoute path="/review/impressions/:itemId" component={ReviewImpressionsContainer} />
              <PrivateRoute path="/review/tightpart/:itemId" component={ReviewTightpartContainer} />
              <PrivateRoute path="/review/blisters/:itemId" component={ReviewBlisterContainer} />
              <PrivateRoute path="/review/complete" component={ReviewCompleteContainer} />
              <PrivateRoute path="/karte" component={KarteContainer} />
              <PrivateRoute component={FootidContainer} />
              <Route path="/about" component={HomeContainer} />
              {/* 
              <Route path="/category/1" 
                render={ () => <Redirect to="/all" /> }
              />
              <Route path="/category/:id" 
                render={ ({match}) => <Ranking categoryId={match.params.id} /> }
              />
              */}
            </Switch>
          </Fragment>
          <Loading loading={loading} />
          <ErrorDialog error={networkError} appActions={appActions} />
        </Fragment>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = state => ({
  app: state.app,
  auth: state.auth
})

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators({ ...appActions }, dispatch),
  recommendActions: bindActionCreators({ ...recommendActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
