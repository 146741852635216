import React, { Fragment } from 'react'
import styled from 'styled-components'

type Props = {
  list: Array<{l:string, v:string}>,
  value: string,
  keyName: string,
  width: number,
  handleChangeData: (key: string, value: string ) => () => void,
}

function SelectTabs({list, value, keyName, handleChangeData, width, ...rest }: Props) {


  console.log("SelectTabs", keyName)

  
  return (
    <Root {...rest}>
      {list.map(( v, idx) => {
          return (
            <Item
              key={idx}
              onClick={ handleChangeData(keyName, v.v)}
              selected={( value == v.v)? true: false}
              width={width}
            >
              {v.l}
            </Item>
          )
      })}
    </Root>
  )
}

export default SelectTabs

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
`

const Item = styled.div`
  line-height: 24px;
  background: ${({ theme, selected }) => (selected ? theme.color.yellow : '#fff')};
  padding: 5px 10px;
  border: ${({ theme, selected }) => (selected ? 'none' : '1px solid #ccc')};
  color: #000;
  border-radius: 17px;
  margin: 5px;
  width: ${({ width }) => (width ? `${width}px` : '80px')};
  text-align: center;
`