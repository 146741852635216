// @flow
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import ThumbnailLoading from '~/components/ThumbnailLoading'

type Props = {
  picture: string
}

type State = {
  loaded: boolean
}

export default class SliderItem extends PureComponent<Props, State> {
  state = {
    loaded: false
  }

  render() {
    const { picture } = this.props

    return (
      <Root>
        <ThumbnailLoading loaded={this.state.loaded} />
        <img
          src={picture}
          onLoad={() => {
            this.setState({ loaded: true })
          }}
        />
      </Root>
    )
  }
}

const Root = styled.div`
  width: 612px;
  height: 306px;
  position: relative;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`
