// @flow
// 一定時間操作がなかったらリロードする
import React, { Component, Fragment } from 'react'

const EVENTS = [
  'focus',
  'blur',
  'click',
  'mousemove',
  'keypress',
  'scroll',
  'touchMove',
  'touchEnd'
]

type Props = {
  refresh: boolean,
  onRefresh: Function,
  refreshMs: number
}

class AutoRefresh extends Component<Props> {
  lastUserAction: number
  hasFocus: boolean
  lostFocus: boolean

  constructor(props: Props) {
    super(props)
    this.lastUserAction = 0
    this.hasFocus = false
    this.lostFocus = true
  }

  windowHasFocus() {
    // this.lostFocus = false
    // console.log('%c <~ Has Focus', 'color: blue')
  }

  windowLostFocus() {
    // this.lostFocus = true
    // console.log('%c <~ Lost Focus', 'color: blue')
  }

  reset() {
    this.lastUserAction = 0
  }

  canRefresh() {
    return (
      this.lastUserAction >= this.props.refreshMs / 1000 &&
      // this.lostFocus &&
      this.props.refresh
    )
  }

  refreshCheck() {
    if (!this.canRefresh()) return
    this.reset()
    this.props.onRefresh()
  }

  componentDidMount() {
    EVENTS.forEach(eventName => {
      if (eventName.indexOf('touch') >= 0) {
        document.addEventListener(eventName, () => this.reset(), false)
      } else if (eventName === 'focus') {
        window.addEventListener(eventName, () => this.windowHasFocus(), false)
      } else if (eventName === 'blur') {
        window.addEventListener(eventName, () => this.windowLostFocus(), false)
      } else {
        window.addEventListener(eventName, () => this.reset(), false)
      }
    })
    setInterval(() => {
      this.lastUserAction++
      this.refreshCheck()
    }, 1000)
  }

  componentDidUpdate() {
    this.refreshCheck()
  }

  render() {
    return <Fragment />
  }
}

export default AutoRefresh
