import React from 'react'
import styled from 'styled-components'
import checkOnPng from './img/check.png'

type Props = {
  dir: 'on' | 'off',
  color?: string
}

function Check({ dir, color, ...rest }: Props) {
  return (
    <Root dir={dir} color={color} {...rest}>
      <img src={checkOnPng} alt="" width={40} height={40} />
    </Root>
  )
}

export default Check

const Root = styled.button`
  width: 40px;
  height: 40px;
`
