import React, { Fragment } from 'react'
import styled from 'styled-components'

type Props = {
  items: Array<{
    label?: string,
    tag: string,
    key: string,
    colorId?: number
  }>,
  handleSelectTag: (selectedTag: string, selectedLabel: string) => void
}

function Categories({ items, handleSelectTag, ...rest }: Props) {
  return (
    <Root {...rest}>
      {items.map(({ label, tag, colorId, key }, idx) => {
        if (label) {
          return (
            <Item
              key={idx}
              onClick={() => {
                handleSelectTag(tag, key)
              }}
            >
              {label}
            </Item>
          )
        }
        if (colorId) {
          return (
            <Color
              key={idx}
              src={`/img/colors/${colorId}.png`}
              width={24}
              height={24}
              onClick={() => {
                handleSelectTag(tag, key)
              }}
            />
          )
        }
        return <Fragment key={idx} />
      })}
    </Root>
  )
}

export default Categories

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
`

const Item = styled.div`
  line-height: 24px;
  background: #fff;
  padding: 0 10px;
  border: 1px solid #666;
  color: #000;
  border-radius: 12px;
  margin: 5px;
`

const Color = styled.img`
  margin: 5px;
`
