// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as appActions } from '../redux/modules/app'
import TryList from '../components/TryList'
import { selecteFavorites } from '~/redux/modules/recommend'
import { actions as recommendActions } from '~/redux/modules/recommend'

const mapStateToProps = state => ({
  choicesAndKarteQrCodeUrl: state.app.choicesAndKarteQrCodeUrl,
  favoriteItems: selecteFavorites(state),
  favorites: state.recommend.favorites,
  reviews: state.recommend.reviews,
  unknownItems: state.recommend.unknownItems,
  itemEntities: state.entities.recommendItems,
})

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators({ ...appActions }, dispatch),
  recommendActions: bindActionCreators({ ...recommendActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TryList)
