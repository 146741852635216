// @flow
import React from 'react'
import styled from 'styled-components'
import closeImg from './img/close.png'

type Props = {}

function Close({ ...props }: Props) {
  return (
    <Icon {...props}>
      <img src={closeImg} alt="" width={24} height={24} />
    </Icon>
  )
}

export default Close

const Icon = styled.button`
  width: 44px;
  height: 44px;
`
