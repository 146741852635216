// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as appActions } from '../redux/modules/app'
import FootSurvey from '../components/FootSurvey'

const mapStateToProps = state => ({
  resultOfSurvey: state.app.footSurvey

})

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators({ ...appActions }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FootSurvey)
