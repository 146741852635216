// @flow
import React from 'react'
import Button from './buttons/ButtonA'
import styled from 'styled-components'

function RefreshButton({ onClick }: { onClick: () => void }) {
  return <HomeButton onClick={onClick}>TOPに戻る</HomeButton>
}

export default RefreshButton

const HomeButton = styled(Button)`
  position: fixed;
  top: 15px;
  left: 15px;
`
