// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Measurement from '~/components/Measurement'
import { actions as appActions } from '~/redux/modules/app'
import { actions as webglActions } from '~/redux/modules/webgl'
import { actions as recommendActions } from '~/redux/modules/recommend'

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators({ ...appActions }, dispatch),
  webglActions: bindActionCreators({ ...webglActions }, dispatch),
  recommendActions: bindActionCreators({ ...recommendActions }, dispatch)
})

export default connect(
  null,
  mapDispatchToProps
)(Measurement)
