// @flow
import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import auth from './modules/auth'
import app from './modules/app'
import recommend from './modules/recommend'
import webgl from './modules/webgl'
import entities from './modules/entities'

const composeEnhancers = composeWithDevTools({})
const reducer = { auth, app, recommend, webgl, entities }
const middleweare = [thunkMiddleware]

export const history = createBrowserHistory()

export default (initialState: Object) => {
  const store = createStore<any, any, any>(
    combineReducers({
      ...reducer,
      router : connectRouter(history)
    }),
    initialState,
    composeEnhancers(applyMiddleware(...middleweare, routerMiddleware(history)))
  )
  return { store }
}
