// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as appActions } from '~/redux/modules/app'
import Start from '../components/Staff'

const mapStateToProps = state => ({
  digitizerCode: state.app.digitizerCode
})

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators({ ...appActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Start)
