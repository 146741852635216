// @flow
import React, { Component } from 'react'
import InputConfiguration from '~/components/input/Configuration'
import styled from 'styled-components'
import type {RouterHistory} from 'react-router-dom';

type Props = {
  appActions: Object,
  digitizerCode: string,
  history:RouterHistory 
}

type State = {
  staffName: string,
  error: string
}

export default class Staff extends Component<Props, State> {
  state = {
    staffName: '',
    error: ''
    
  }


  async componentDidMount() {
    const inputDataJson  = localStorage.getItem('input') || '{}'
    const inputData = JSON.parse(inputDataJson)
    const { staffName } = inputData

    if (!staffName) {
      this.props.appActions.hideLoading()
      return
    }
    this.setState( {staffName: staffName} )

    // デフォルトの入力情報ををstoreに登録
    this.props.appActions.setInput(inputData)
    // ローディング停止
    this.props.appActions.hideLoading()

    //this.props.appActions.dataLayerPush({ pageID: 'home' })
  }

  changeText = (e: any) => {
    this.setState({staffName: e.target.value});
  }

  nextStep = () => {
    // デジタイザーコードが入ってない場合は、 Localstrageからセットする
    let {digitizerCode} = this.props
    if (!digitizerCode) {
      const inputDataJson  = localStorage.getItem('input') || '{}'
      const inputData = JSON.parse(inputDataJson)
      digitizerCode = inputData.digitizerCode
    }

    this.props.appActions.setInput({
      staffName: this.state.staffName,
      footId: null,
      digitizerCode: digitizerCode
    })

    if(this.state.staffName.length == 0 ) {
      this.setState({error: 'スタップコードを入力してください。'})
      return
    }

    (async () => {
      try {
        await this.props.appActions.checkStaffCode( this.state.staffName)
        // dispacherでデータ保持
        this.props.history.push('/footid')
        return
      } catch (e) {
        console.log("catch", e);
        this.setState({error: 'スタッフコードが正しくありません'})
      return
      } 
    })();


  }

  render() {
    return (
      <Root>
      <InputConfiguration
        title='スタッフコードを入力を入力してください' 
        fontColor='#fff'
        placeholder='コードを入力'
        value={this.state.staffName}
        changeText={this.changeText}
        nextStep={this.nextStep}
        error={this.state.error}
      />
      </Root>
    )
  }
}

const Root = styled.div`
  position: relative;
  background-color: #404040;
  width: 100vw;
  height: 100vh;
`

