// @flow
import React from 'react'
import styled from 'styled-components'

import { Fit, BrandName } from '../../types'
import label0Png from '~/assets/img/label-good.png'
import label1Png from '~/assets/img/label-yuttari.png'
import label2Png from '~/assets/img/label-shikkari.png'
import { addComma } from '~/helpers/number'
import CheckButton from '../iconButtons/Check'
import CloseButton from '../iconButtons/Close'
import ZoomButton from '../iconButtons/Zoom'
import TryButton from '../iconButtons/Try'

const labels = {
  fit: label0Png,
  loose: label1Png,
  tight: label2Png
}

type Props = {
  handleAddUnknownShoe: () => () => void,
}

function NewUnknownProduct({ handleAddUnknownShoe }: 
Props) {
  return (
    <Root>
      <Item>
        <StyledAddBtn onClick={handleAddUnknownShoe()} color={'grey2'}>
          試着を追加<br />(登録されていない靴)
        </StyledAddBtn>
      </Item>
    </Root>
  )
}

export default NewUnknownProduct

const Root = styled.div`
  position: relative;
`    
const Item = styled.div`
  background-color: #fff;
  border-radius: 60px;
  width: 760px;
  height: 130px;
`    

const StyledAddBtn = styled(TryButton)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: -1px;
`



const Label = styled.div`
  text-align: center;
  width: 130px;
  margin: 0 auto;
  position: relative;
  top: 15px;
`

const Description = styled.div`
  margin-left: 100px;
  margin: auto 0 auto 100px;
  font-size: 14px;
  font-weight: bold;
  > dl > dt {
	  float: left;
	  clear: left;
	  margin-right: 0.5em;
	  width: 60px;
  }
  > dl > dd {
	    float: left;
	    margin-left: 1em;
  }  
`

const Price = styled.div`
  span {
    font-size: 17px;
    font-family: ${({ theme }) => theme.font.english};
    &:before {
      content: '¥';
    }
  }
  &:after {
    content: '(税抜)';
    font-size: 10px;
    margin-left: 2px;
  }
`
