import React from 'react'
import styled from 'styled-components'
import arrowPng from './img/arrow.png'

type Props = {
  dir: 'next' | 'previous',
  color?: string
}

function Arrow({ dir, color, ...rest }: Props) {
  return (
    <Root dir={dir} color={color} {...rest}>
      <img src={arrowPng} alt="" width={11} height={19} />
    </Root>
  )
}

export default Arrow

const Root = styled.button`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: ${({ theme, color }) =>
    color ? theme.color[color] : '#fff'};
  img {
    position: relative;
    left: ${({ dir }) => (dir === 'previous' ? '-2px' : '2px')};
    transform: ${({ dir }) => (dir === 'previous' ? 'rotateY(-180deg)' : '')};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.color.grey};
    img {
      opacity: 0.3;
    }
  }
  img {
    position: relative;
    left: ${({ dir }) => (dir === 'next' ? '2px' : '-2px')};
  }
`
