import React from 'react'
import styled from 'styled-components'
import { addComma } from '~/helpers/number'

function Description({ item, ...rest }) {
  const {
    brandName,
    productNumber,
    makerSize,
    makerWidth,
    productName,
    makerColor,
    makerMaterial,
    price,
    unit
  } = item
  return (
    <Root {...rest}>
      <Title>
        {`${brandName} ${productNumber} / ${makerSize}${unit} ${makerWidth} / ${productName} / ${makerColor} ${makerMaterial}`}
      </Title>
      <Price>
        <span>{addComma(price)}</span>
      </Price>
    </Root>
  )
}

export default Description

const Root = styled.div``

const Title = styled.div`
  font-size: 13px;
`

const Price = styled.div`
  span {
    font-size: 17px;
    font-family: ${({ theme }) => theme.font.english};
    &:before {
      content: '¥';
    }
  }
  &:after {
    content: '(税抜)';
    font-size: 10px;
    margin-left: 2px;
  }
`
