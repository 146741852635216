// @flow
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import ThumbnailLoading from '~/components/ThumbnailLoading'

type Props = {
  src: string,
  index: number,
  slideIndex: number
}

type State = {
  loaded: boolean
}

class Thumbnail extends PureComponent<Props, State> {
  state = {
    loaded: false
  }

  render() {
    const { src, index, slideIndex, ...rest } = this.props

    return (
      <Root current={index === slideIndex} {...rest}>
        <Loading loaded={this.state.loaded} />
        <img
          src={src}
          alt=""
          onLoad={() => {
            this.setState({ loaded: true })
          }}
        />
      </Root>
    )
  }
}

export default Thumbnail

const Root = styled.div`
  width: 60px;
  height: 60px;
  background-color: #fff;
  position: relative;
  border: ${({ theme, current }: { theme: Object, current: boolean }) =>
    current
      ? `1px solid ${theme.color.yellow}`
      : `1px solid ${theme.color.grey6}`};
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

const Loading = styled(ThumbnailLoading)`
  transform: scale(0.5);
`
