// @flow
import React from 'react'
import styled from 'styled-components'
import guestIconJpg from '~/assets/img/icon-guest-user.jpg'

type Props = {
  onClick: () => void,
  icon?: string,
  jis: {
    jisSize: string,
    jisWidth: string,
    jisWidthJa: string
  },
  jisWidthDisplay: boolean,
  karteDataLevel: string
}

const RecordInfo: React$StatelessFunctionalComponent<Props> = React.memo(
  ({ onClick, icon, jis, jisWidthDisplay, karteDataLevel,...rest }) => (
    <Root {...rest}>
      <Icon>
        <figure>
          <img src={icon || guestIconJpg} alt="" />
        </figure>
      </Icon>
      <MySize>
        <dt>おすすめサイズ</dt>
        <dd>
          <ul>
            <li>{jis.jisSize}</li>
            <li>{jis.jisWidthJa} {jisWidthDisplay && `(${jis.jisWidth})` }</li>
          </ul>
        </dd>
      </MySize>
      <Button onClick={onClick}>足カルテを見る</Button>
    </Root>
  )
)

export default RecordInfo

const Root = styled.div`
  width: 160px;
  height: 225px;
  background-color: ${({ theme }) => theme.color.yellow};
  border-radius: 15px 0 0 15px;
  text-align: center;
`

const Icon = styled.div`
  padding: 34px 0 20px 0;
  figure {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
  }
  img {
    object-fit: cover;
  }
`

const MySize = styled.dl`
  dt {
    text-align: center;
    font-size: 11px;
    margin-bottom: 5px;
  }
  ul {
    display: flex;
    justify-content: center;
  }
  li {
    font-size: 12px;
    font-family: ${({ theme }) => theme.font.english}
    margin: 0 5px;
  }
`

const Button = styled.button`
  font-family: ${({ theme }) => theme.font.bold};
  font-size: 11px;
  background-color: #fff;
  width: 117px;
  height: 30px;
  border-radius: 15px;
  margin-top: 10px;
`
