import vert from './shader/readyLine.vert';
import frag from './shader/readyLine.frag';

import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import glParam from './glParam';
import glType from './glType';
import readyDotItem from './readyDotItem';


export default class readyLine2 extends object3d {

  constructor(opt) {

    super(opt)

    this._line = undefined
    this._pos = undefined
    this._posBuffer = undefined

    this.anm = {
      angle:{value:-1},
      radius:{value:1}
    }

  }


  init() {

    super.init();

    this._posBuffer = [];
    this._pos = new THREE.Vector3(0, 0, 0);

    this._posBuffer.push(this._pos.clone())
    this._posBuffer.push(this._pos.clone())
    this._posBuffer.push(this._pos.clone())
    this._posBuffer.push(this._pos.clone())

    this._line = new THREE.Mesh(
      this._makeGeo(),
      new THREE.ShaderMaterial({
        depthTest:false,
        transparent:true,
        vertexShader:vert,
        fragmentShader:frag,
        uniforms:{
          alpha:{value:0.5}
        }
      })
    );
    this.add(this._line);

    this.visible = false;

    this.resize();

  }


  show(opt) {

    const d = opt.d;
    this._pos.set(d, d, 0);
    for(let i = 0; i < 50; i++) {
      this._computePosition();
    }

    TweenMax.to(this._pos, opt.duration, {
      bezier:{values:opt.bezier},
      ease:opt.ease,
      delay:opt.delay,
      onStart: () => {
        this.visible = true;
      },
      onComplete: () => {
        this.visible = false;
      }
    });

    // const alpha = this._line.material.uniforms.alpha;
    // alpha.value = 1;
    // TweenMax.to(alpha, opt.duration, {
    //   value:0,
    //   delay:opt.delay,
    //   ease:opt.ease
    // })

  }


  update() {

    if(!glParam.isRender[glType.SCENE.READY] || !this.visible) {
      return;
    }

    super.update();

    this._computePosition();

    this._line.geometry.dispose();
    this._line.geometry = this._makeGeo();

  }


  _computePosition() {

    this._posBuffer.push(this._pos.clone());
    if(this._posBuffer.length >= 20) {
      this._posBuffer.shift();
    }

  }


  resize() {

    super.resize();

  }


  _makeGeo() {

    const path = new THREE.CatmullRomCurve3(this._posBuffer);
    const geo = new THREE.TubeBufferGeometry(path, 16, 95, 6, false);

    const color = [];
    const len = geo.attributes.position.count;
    for(let i = 0; i < len; i++) {
      color.push(1);
      color.push(1);
      color.push(1);
      color.push(glUtils.map(i, 0, 2, 0, len));
    }

    const colors = new Float32Array(color);
    geo.addAttribute('color', new THREE.BufferAttribute(colors, 4));

    return geo;

  }





}
