// @flow
import React from 'react'
import styled from 'styled-components'
import blankPng from './img/blank.png'

const SubBlankThumbnail: React$StatelessFunctionalComponent<{}> = React.memo(
  () => <Root src={blankPng} width={54} height={54} />
)

export default SubBlankThumbnail

const Root = styled.img``
