// @flow
import { cloudApi } from '~/api'
import humps from 'humps'
import qs from 'qs'
import confirm from '~/factories/confirm'
import { URLJoin } from '~/helpers/string'
import { formatSize } from '~/helpers/number'

// $FlowFixMe
const { USER_APP, footSurveyTags } = process.env.CONFIG

// Actions
const AUTH_TOKEN = 'auth/AUTH_TOKEN'
const AUTH_REDIRECT = 'auth/AUTH_REDIRECT'

let store
export const registerStore = (_store: any) => {
  store = _store
}
const storeFacade = {
  getAuthtoken(): ?string {
    return store.getState().auth.token
  },
}



export const actions = {
  setAuthToken: (token: string) => (
    dispatch: any
  ) =>  {
    dispatch({
          type: AUTH_TOKEN,
          payload: {token : token}
    })
  },

  Login: ({mailAdress , password }: {mailAdress: string, password: string }  ) => (
    dispatch: any
  ): Promise<any>  =>  {
      return new Promise(async (resolve: any, reject: any) => {
      const {data} = await cloudApi({
        method: 'post',
        url: '/admin/login',
        data: {
          email: mailAdress,
          password: password
        }
      })
      if (data.statusCode === 200) {
        dispatch({
          type: AUTH_TOKEN,
          payload: {
            token:  data.data.jwt
          }
        })
        localStorage.setItem('flicfitAuthToken', data.data.jwt)
        resolve({...data.data})
      } else {
        reject({...data.data})
      }
    })
  },
  setRedirect : () =>async (
    dispatch: any
  ) => {
    console.log( window.location.pathname)
        dispatch({
          type: AUTH_REDIRECT,
          payload: {
            redirectURL:  window.location.pathname
          }
        })
  },

  checkLsToken : () =>async (
    dispatch: any
  ) => {
    return new Promise(async (resolve, reject) => {

    const authToken = localStorage.getItem('flicfitAuthToken')
    if ( !authToken )  {
      return reject()
    }
    //const authToken: string = storeFacade.getAuthtoken() || ''
    const {data} = await cloudApi({
      method: 'get',
      url: '/admin',
      headers: {
        Authorization: `JWT ${authToken}`
      },
      data: {}
    })
    
    if (data.statusCode === 200) {
        dispatch({
          type: AUTH_TOKEN,
          payload: {
            token : authToken 
          }
        })
        resolve({ token: authToken })
      } else {
        console.error(data)
        //dispatch(actions.networkError('認証エラー'))
        reject(data.message)
      }
    })
  },


}

// Reducer
export type AuthState = {
  token: ?string,
  redirectURL: ?string
}

const initialState = {
  token: null,
  redirectURL: null
}

export default function reducer(
  state: AuthState = initialState,
  action: Object
) {
  switch (action.type) {
    case AUTH_TOKEN: {
      const { token} = action.payload
      return { ...state, token }
    }
    case AUTH_REDIRECT: {
      const { redirectURL} = action.payload
      return { ...state, redirectURL }
    }
  }
  return state
}

// 認証済チェック用
export const isAuthSelector = (state:Object) => state.auth.token !== null