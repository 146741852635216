// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as appActions } from '../redux/modules/app'
import { selecteFavorites, selecteReview, selectReviewItem } from '~/redux/modules/recommend'
import { actions as recommendActions } from '~/redux/modules/recommend'
import ReviewTightpart from '../components/ReviewTightpart'

const mapStateToProps = ( state, props ) => ({
  review: selecteReview(state, props),
  favoriteItems: selecteFavorites(state),
  item: selectReviewItem(state, props)
})

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators({ ...appActions }, dispatch),
  recommendActions: bindActionCreators({ ...recommendActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewTightpart)
