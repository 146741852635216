
class glResize {

  constructor() {

    this.size = {
      width : 0,
      height: 0
    };

    this._timer = null;
    this._list = [];
    window.addEventListener('resize', () => {
      this._eResize();
    }, false);

    this._setStageSize();
  }


  add(func, isCall = false) {
    this._list.push(func);
    if (isCall) {
      func(this.size);
    }
    return this._list.length - 1
  }


  remove(key) {
    this._list[key] = null
  }


  _setStageSize() {
    this.size.width = window.innerWidth;
    this.size.height = window.innerHeight;
  }


  _eResize() {
    this._setStageSize();
    if (this._timer === null) {
      clearInterval(this._timer);
      this._timer = null;
    }
    this._timer = setTimeout(() => {
      this._call();
    }, 300);
  }


  _call() {
    const len = this._list.length;
    for (let i = 0; i < len; i++) {
      if(this._list[i] != null) {
        this._list[i]();
      }
    }
  }

}

export default new glResize();
