import React, { Fragment } from 'react'
import styled from 'styled-components'
import { ReviewQnA } from '../../types'


type Props = {
  values: ReviewQnA,
  handleSelect: (value: string ) => () => void,
  width: ?number
}

function TwoSelections({ values, handleSelect, width, ...rest }: Props) {
  const list  = ['yes' , 'no']
  const  select =  ( values.answer && values.answer.length > 0 ) ? 'yes': 'no'
  return (
    <Root {...rest}>
      {list.map(( v, idx) => {
          return (
            <Item
              key={idx}
              onClick={ handleSelect( values.questionId , v)}
              selected={ v == select ? true: false}j
              width={width}
            >
              {v == 'yes'? 'あり': 'なし'}
            </Item>
          )
      })}
    </Root>
  )
}

export default TwoSelections

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
`

const Item = styled.div`
  line-height: 40px;
  background: ${({ theme, selected }) => (selected ? theme.color.yellow : '#fff')};
  padding: 5px 10px;
  border: ${({ theme, selected }) => (selected ? 'none' : '1px solid #ccc')};
  color: #707070;
  border-radius: 25px;
  margin: 5px;
  width: ${({ width }) => (width ? `${width}px` : '200px')};
  text-align: center;
  font-size: 17px;
`