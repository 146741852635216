// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import Description from '../modal/Description'
import IconClose from '../iconButtons/Close'
import Slider from 'react-slick'
import ArrowButton from '../buttons/ButtonF'
import Thumbnail from '../modal/Thumbnail'
import Categories from '../modal/Categories'
import Sizelist from '../modal/SizeList'
import SliderItem from '../modal/SliderItem'
import { RecommendEntity } from '../../types'
import labelFittingPng from '../searchResult/img/label-fitting.png'
import SelectTabs from './SelectTabs'

// $FlowFixMe
const { fitTags } = process.env.CONFIG

const colorTags = [
  {l: 'ブラック系' , v: 'black'},
  {l: 'ブルー系' , v: 'blue'},
  {l: 'レッド系' , v: 'red'},
  {l: 'イエロー系' , v: 'yellow'},
  {l: 'グリーン系' , v: 'green'},
  {l: 'パープル系' , v: 'purple'},
  {l: 'オレンジ系' , v: 'orange'},
]

const sizeTags = [
  {l: '19.0cm' , v: '190'},
  {l: '19.5cm' , v: '195'},
  {l: '22.0cm' , v: '220'},
  {l: '22.5cm' , v: '225'},
  {l: '23.0cm' , v: '230'},
  {l: '23.5cm' , v: '235'},
  {l: '24.0cm' , v: '240'},
  {l: '24.5cm' , v: '245'},
  {l: '25.0cm' , v: '250'},
  {l: '25.5cm' , v: '255'},
  {l: '26.0cm' , v: '260'},
  {l: '26.5cm' , v: '265'},
  {l: '27.0cm' , v: '270'},
  {l: '27.5cm' , v: '275'},
  {l: '28.0cm' , v: '280'},
  {l: '28.5cm' , v: '285'},
  {l: '29.0cm' , v: '290'}
]



type Props = {
  unknownShoeName: Array<string>,
  onClose: () => void,
  onAdd: () => void,
  handleChangeDataBrandText: (e: any) => void,
  handleChangeDataProductText: (e: any) => void,
  handleChangeData: (key: string, value: string) => () => void,
}

type State = {
}

export default class AddNewItemModal extends Component<Props, State> {

  state = {
  }

  componentDidMount() {

  }

  render() {
    const { unknownShoeName, onClose,onAdd, handleChangeData, handleChangeDataBrandText, handleChangeDataProductText } = this.props

    return (
      <Root>
        <CloseButton onClick={onClose} />
        <Questions>
          <dl>
          <dt>ブランドを入力してください</dt>
          <dd>
            <InputText type="text" name="text" value={unknownShoeName[0]}
              onChange={handleChangeDataBrandText}
              key={'brand'}
              placeholder={'ブランド名'}
            />
          </dd>
          <dt>品番を入力してください</dt>
          <dd>
            <InputText type="text" name="text" value={unknownShoeName[2]}
              onChange={handleChangeDataProductText}
              key={'brand'}
              placeholder={'品番'}
            />
          </dd>
          <dt>色を選択してください</dt>
          <dd>
          <StyeldSelect 
            list={colorTags}
            keyName='color'
            handleChangeData={handleChangeData}
            value={unknownShoeName[3]}
            width={150}
          />
          </dd>
          <dt>サイズを選択してください</dt>
          <dd>
          <StyeldSelect 
            list={sizeTags}
            keyName='size'
            handleChangeData={handleChangeData}
            value={unknownShoeName[1]}
            width={100}
          />
          </dd>
          </dl>
        </Questions>

          <StyledButton onClick={onAdd}>
            <Item>
            OK
            </Item>
          </StyledButton>

      </Root>
    )
  }
}

const Root = styled.div`
  width: 95%;
  height: 95%;
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  padding-bottom: 25px;
  overflow: scroll;
  
`

const CloseButton = styled(IconClose)`
  position: absolute;
  top: 12px;
  right: 12px;
`
const Questions = styled.div`
  margin-top: 10px;
  margin-left: 80px;
  > dl {
  text-align: center;
  }
  > dl > dt {
    font-size: 18px;
    margin: 20px 0 5px;
    font-weight: bold;
    color: #707070;
  }
  > dl > dd {
    font-weight: normal;
  }
`

const InputText = styled.input`
  border: 1px solid #707070;
  border-radius:30px;
  box-shadow: none;
  padding: 2px 8px;
  font-size: 30px;
  width: 380px;
  height: 60px;
  text-align: center;
  `



const CarouselWrapper = styled.div`
  width: 612px;
  height: 306px;
  margin: 25px auto;
  position: relative;
  .slick-track {
    display: flex;
  }
  .slick-slider {
    overflow: hidden;
  }
  .slick-arrow {
    position: absolute;
    top: 50%;
    margin-top: -22px;
  }
  .slick-prev {
    left: -44px;
  }
  .slick-next {
    right: -44px;
  }
`

const Thumbnails = styled.div`
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-left: 20px;
  ul {
    display: flex;
  }
  li {
    margin: 0 5px;
  }
`

const StyeldCategories = styled(Categories)`
  padding: 0 25px;
  margin-top: 10px;
`

const StyeldSelect = styled(SelectTabs)`
  padding: 0 25px;
  margin-top: 10px;
`
const StyledButton = styled.button`
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0)
`

const Item = styled.div`
  line-height: 24px;
  background: #D5D6D7;
  padding: 5px 10px;
  color: #000;
  border-radius: 17px;
  margin: 5px;
  width: 162px;
  text-align: center;
`


