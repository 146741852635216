import vert from './shader/base.vert';
import frag from './shader/introHuman.frag';

import glConf from './glConf';
import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import glParam from './glParam';
import glType from './glType';
import capture from './capture';
import webgl from './index'


export default class introHuman extends object3d {

  constructor(opt) {

    super(opt);

    this._imgScene = undefined
    this._imgContainer = undefined
    this._img = undefined
    this._last = undefined

    this._maskScene = undefined
    this._maskContainer = undefined
    this._mask = undefined

    this._dest = undefined

    this._imgSize = undefined

  }


  init() {

    super.init();

    this._imgSize = new THREE.Vector2(1024, 1024);

    this._imgScene = new capture();
    this._imgScene.init();

    this._imgContainer = new THREE.Object3D();
    this._imgScene.add(this._imgContainer);

    this._img = []
    const num = webgl.preloadImages.introImages.length
    for(let i = 0; i < num; i++) {
      const img = new image({
        tex:glTextures.get(webgl.preloadImages.introImages[i])
      })
      img.init();
      img.setSize(this._imgSize.x, this._imgSize.y);
      this._img.push(img);
      this._imgContainer.add(img);
    }
    this._nowImg = 0;
    this._updateImg();


    this._maskScene = new capture();
    this._maskScene.init();

    this._maskContainer = new THREE.Object3D();
    this._maskScene.add(this._maskContainer);

    this._mask = [];
    for(let i = 0; i < 6; i++) {
      const mask = new THREE.Mesh(
        new THREE.CircleBufferGeometry(0.5, 128),
        new THREE.MeshBasicMaterial({
          //color:0xff0000,
          transparent:true,
          opacity:1
        })
      );
      this._maskContainer.add(mask);
      this._mask.push(mask);
    }


    this._dest = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(1, 1),
      new THREE.ShaderMaterial({
        transparent:true,
        vertexShader:vert,
        fragmentShader:frag,
        uniforms:{
          tDiffuse:{value:this._imgScene.texture()},
          tMask:{value:this._maskScene.texture()},
          alpha:{value:1}
        }
      })
    );
    this.add(this._dest);
    // this._dest.position.z = -1;



    this.resize();

    this.show({
      delay:1
    });

  }


  _updateImg() {

    for(let i = 0; i < this._img.length; i++) {
      this._img[i].visible = (i == this._nowImg);
    }

  }


  show(opt) {

    glUtils.shuffle(this._mask);

    // const ease = Elastic.easeOut.config(1, 0.75);
    const ease = Power4.easeInOut;
    const interval = 0.025;
    let duration = 1;

    TweenMax.killTweensOf(this._imgContainer.position);
    this._imgContainer.position.x = -this.sw * 0.5 - 50;
    TweenMax.to(this._imgContainer.position, 2, {
      x:-this.sw * 0.5,
      ease:Power4.easeOut,
      delay:opt.delay
    });

    for(let i = 0; i < this._mask.length; i++) {

      const item = this._mask[i];

      let scale = this.sw * 0.5;
      item.position.x = this.sw * -0.25 + this.sw * glUtils.range(0.25)
      item.position.y = this.sh * glUtils.range(0.5)

      if(i == this._mask.length - 1) {
        scale = this.sw * 1.5
        item.position.x = item.position.y = 0
      }

      item.scale.set(glConf.MIN_SCALE, glConf.MIN_SCALE, 1);

      if(i == this._mask.length - 1) {
        TweenMax.to(item.scale, duration, {
          x:scale,
          y:scale,
          ease:ease,
          delay:opt.delay + i * interval,
          onComplete: () => {
            this.hide({
              delay:2
            });
          }
        });
      } else {
        TweenMax.to(item.scale, duration, {
          x:scale,
          y:scale,
          ease:ease,
          delay:opt.delay + i * interval
        });
      }

    }


  }


  hide(opt) {

    const ease = Power4.easeInOut;
    const interval = 0;
    const duration = 1.5;

    for(let i = 0; i < this._mask.length; i++) {

      const item = this._mask[i];

      if(i == this._mask.length - 1) {
        TweenMax.to(item.scale, duration, {
          x:glConf.MIN_SCALE,
          y:glConf.MIN_SCALE,
          ease:ease,
          delay:opt.delay + i * interval,
          onComplete: () => {
            this._eHided();
          }
        });
      } else {
        TweenMax.to(item.scale, duration, {
          x:glConf.MIN_SCALE,
          y:glConf.MIN_SCALE,
          ease:ease,
          delay:opt.delay + i * interval
        });
      }

    }

  }


  _eHided() {

    // つぎの画像
    if(++this._nowImg >= this._img.length) {
      this._nowImg = 0;
    }
    this._updateImg();

    if(this.visible) {
      this.show({
        delay:0
      })
    }
  }


  update() {

    super.update();

    if(!glParam.isRender[glType.SCENE.INTRO] || !this.visible) {
      return;
    }

    webgl.renderer.setClearColor(0xe6eeed, 0);
    this._imgScene.render(webgl.renderer, webgl.camera);

    this._maskScene.render(webgl.renderer, webgl.camera);

  }


  resize() {

    super.resize();

    this._imgScene.size(this.sw, this.sh);
    this._maskScene.size(this.sw, this.sh);

    this._imgContainer.position.x = -this.sw * 0.5;
    this._imgContainer.position.y = this.sh * 0.5;

    this._dest.scale.set(this.sw, this.sh, 1);

  }


}
