// @flow
import React, { Component } from 'react'
import InputConfiguration from '~/components/input/Configuration'
import styled from 'styled-components'
import type {RouterHistory} from 'react-router-dom';
import Button from './buttons/ButtonA'

type Props = {
  appActions: Object,
  history: RouterHistory,
  staffName: string,
  digitizerCode: string
}

type State = {
  footId: string,
  error : string
  
}

export default class Footid extends Component<Props, State> {
  state = {
    footId: '',
    error: ''
  }


  async componentDidMount() {
    this.props.appActions.hideLoading()
    //this.props.appActions.dataLayerPush({ pageID: 'home' })


    console.log("FootId Component Did Mount", this.props)
    if (!this.props.digitizerCode) {
      this.props.history.push('/digitizer')
    }
    if (!this.props.staffName) {
      this.props.history.push('/staff')
    }
  }

  changeText = (e: any) => {
    this.setState({footId: e.target.value});
  }

  handleDigitizer = () => {
    this.props.history.push('/digitizer')
  }

  handleStaff = () => {
    this.props.history.push('/staff')
  }

  handleUuid = () => {
    this.props.history.push('/footidRevisit')
  }

  nextStep = () => {
    let id = this.state.footId
    // バリデーション
    if( id.search(/^[0-9]+$/)  == -1) {
      this.setState({error: '半角数字を入力してください。'});
      return
    }

    id = id.padStart(4, '0')
     const inputData = {
      staffName: this.props.staffName,
      footId: id,
      digitizerCode: this.props.digitizerCode,
      visitDate: null
    }
    // dispacherでデータ保持
    this.props.appActions.setInput( inputData)
    this.props.history.push('/recommned')
  }

  render() {
    return (
      <Root>
      <InputConfiguration
        title='お客様のフリックフィットIDを入力してください' 
        placeholder='タッチしてID入力'
        value={this.state.footId}
        changeText={this.changeText}
        nextStep={this.nextStep}
        error={this.state.error}
      />
      <DigitizerButton onClick={this.handleDigitizer}>デジタイザー入力</DigitizerButton>
      <StaffButton onClick={this.handleStaff}>スタップ入力</StaffButton>
      <UuidButton onClick={this.handleUuid}>前回データを入力</UuidButton>
      </Root>
    )
  }
}

const Root = styled.div`
  position: relative;
  background-color: #F2F3F4;
  width: 100vw;
  height: 100vh;
`
const DigitizerButton = styled(Button)`
  position: fixed;
  bottom: 37px;
  left: 15px;
`
const StaffButton = styled(Button)`
  position: fixed;
  bottom: 37px;
  left: 200px;
`
const UuidButton = styled(Button)`
  position: fixed;
  bottom: 37px;
  right: 15px;
`



