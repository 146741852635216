// @flow
import topImageJson from '~/assets/json/topimage.json'
import filter from 'lodash/filter'
import flatten from 'lodash/flatten'
import pictureUrl from '~/helpers/pictureUrl'
import { URLJoin } from '../../helpers/string'

// $FlowFixMe
const { FD } = process.env.CONFIG

// types
type State = {
  // イントロで使う画像
  introImages: Array<string>,
  // 3Dデータ生成中のパーティクルで使う画像
  shoeImages: Array<string>,
  // plyデータ
  plyFileUrl: ?string
}

type TopImageJson = typeof topImageJson

// Actions
const REGISTER_INITIALIZE_DATA = 'webgl/REGISTER_INITIALIZE_DATA'
const REGISTER_FILE_URL = 'webgl/REGISTER_FILE_URL'

export const actions = {
  registerInitializeData: ({
    target,
    shoeImages
  }: {
    target: Array<string>,
    shoeImages: Array<string>
  }) => {
    const introImages: TopImageJson = filter(
      (topImageJson: TopImageJson),
      (value: Array<string>, key: string) => {
        return target.includes(key)
      }
    )
    const formattedShoeImages: Array<string> = shoeImages.map(
      (path: string) => {
        return pictureUrl(path)
      }
    )
    return {
      type: REGISTER_INITIALIZE_DATA,
      payload: {
        introImages: flatten<string, {}>(introImages),
        shoeImages: formattedShoeImages
      }
    }
  },
  registerPlyFileUrl: (plyFileUrl: string) => {
    return {
      type: REGISTER_FILE_URL,
      payload: { plyFileUrl: plyFileUrl }
    }
  }
}

// Reducer
const initialState: State = {
  introImages: [],
  shoeImages: [],
  plyFileUrl: ''
}

export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case REGISTER_INITIALIZE_DATA: {
      const { introImages, shoeImages } = action.payload
      return { ...state, introImages, shoeImages }
    }
    case REGISTER_FILE_URL: {
      const { plyFileUrl } = action.payload
      return { ...state, plyFileUrl }
    }
  }
  return state
}
