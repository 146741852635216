// @flow
import React, { Component } from 'react'
import RefreshButton from './RefreshButton'

type Props = {
  refresh: () => void,
  dataLayerPush: (params: { pageID: string }) => void
}

export default class AttentionHemLength extends Component<Props> {
  componentDidMount() {
    this.props.dataLayerPush({ pageID: 'AttentionHemLength' })
  }

  render() {
    const { refresh } = this.props
    return <RefreshButton onClick={refresh} />
  }
}
