
export default class capture extends THREE.Scene {


  constructor() {

    super();

    this._texture = undefined

  }

  // -----------------------------------
  // 初期化
  // -----------------------------------
  init() {

    // this._texture = new THREE.WebGLRenderTarget(16, 16 , {
    //   depthBuffer:false,
    //   stencilBuffer:false
    // });

    this._texture = new THREE.WebGLRenderTarget(16, 16);

    // this._texture.texture.generateMipmaps = false;
    // this._texture.texture.magFilter = THREE.LinearFilter;
    // this._texture.texture.minFilter = THREE.LinearFilter;
    // this._texture.texture.wrapS = THREE.ClampToEdgeWrapping;
    // this._texture.texture.wrapT = THREE.ClampToEdgeWrapping;

  }


  // -----------------------------------
  //
  // -----------------------------------
  texture() {

    return this._texture.texture;

  }


  // -----------------------------------
  // テクスチャにレンダリング
  // -----------------------------------
  render(renderer, camera) {

    renderer.render(this, camera, this._texture);

  }


  // -----------------------------------
  // レンダリング先テクスチャのサイズ設定
  // -----------------------------------
  size(width, height) {

    const ratio = window.devicePixelRatio || 1;
    this._texture.setSize(width * ratio, height * ratio);

  }


}
