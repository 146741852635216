// @flow
import React from 'react'
import styled from 'styled-components'
import img1 from './img/recommend.png'

type Props = {
}


function RecommenButton({ ...rest }: Props) {
  return (
    <Root {...rest}>
      <img src={img1} width={268} alt="" />
    </Root>
  )
}

export default RecommenButton

const Root = styled.button`
`
