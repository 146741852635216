import React, { Fragment } from 'react'
import styled from 'styled-components'
import FootPng from './img/foot.png'
import { normalize, schema } from 'normalizr'
import { ReviewQnA } from '../../types'

// $FlowFixMe, 
/*
const { shoeReviewTags } = process.env.CONFIG
const qSchema = new schema.Entity('question', {} , {idAttribute: 'questionId' });
const qListSchema = new schema.Array(qSchema);
const qData = normalize(shoeReviewTags, qListSchema);
const  {entities: {question}} = qData
*/


type Props = {
  values: ReviewQnA,
  handleSelect: (value: string ) => () => void,
  handleBack: () => () => void,
  width: ?number
}
function FootPartButtons({ values, handleSelect, handleBack,width, ...rest }: Props) {
  let parts =  []
  values.options.forEach( ( element, idx ) => {
  let top = '', left = ''
  switch (element) {
    case '先端' :
      top = '10'
      left = '250'
      break
    case '小指付け根' :
      top = '150'
      left = '160'
      break
    case '親指付け根' :
      top = '150'
      left = '350'
      break
    case '履き口' :
      top = '230'
      left = '250'
      break
    case '甲外側' :
      top = '330'
      left = '160'
      break
    case '土踏まず' :
      top = '330'
      left = '350'
      break
    case '外側くるぶし' :
      top = '420'
      left = '160'
      break
    case '内側くるぶし' :
      top = '420'
      left = '350'
      break
    case 'かかと' :
      top = '500'
      left = '250'
      break
    case 'なし' :
      top = '500'
      left = '450'
      break
    default :
      break
  }
  parts.push( { top: `${top}px`, left: `${left}px` } )

  })

  return (

    <Root {...rest}>
      <Illust>
        <img src={FootPng} width={174} />
      </Illust>
      {values.options.map(( v, idx) => {
          return (
            <Item
              key={idx}
              onClick={ handleSelect(v)}
              selected={values.answer && values.answer.find( e =>  e != null && e == v )? true: false }
              width={width}
              position={parts[idx]}
            >
              {v}
            </Item>
          )
      })}
    </Root>
  )
}

export default FootPartButtons

const Root = styled.div`
  position: relative;
`

const Illust = styled.div`
  position: absolute;
  left: 237px;

`

const Item = styled.div`
  position: absolute;
  top: ${({ position  }) => ( position.top ? position.top : '100px') };
  left: ${({ position  }) => ( position.left ? position.left : '100px') };
  line-height: 40px;
  background: ${({ theme, selected }) => (selected ? theme.color.yellow : '#fff')};
  padding: 5px 10px;
  border: ${({ theme, selected }) => (selected ? 'none' : '1px solid #ccc')};
  color: #707070;
  border-radius: 25px;
  margin: 5px;
  width: ${({ width }) => (width ? `${width}px` : '150px')};
  text-align: center;
  font-size: 21px;
`