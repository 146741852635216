// @flow
import { connect } from 'react-redux'
import AttentionHemLength from '~/components/AttentionHemLength'
import { actions } from '~/redux/modules/app'

const mapDispatchToProps = dispatch => ({
  refresh: () => dispatch(actions.refresh()),
  dataLayerPush: params => dispatch(actions.dataLayerPush(params))
})

export default connect(
  null,
  mapDispatchToProps
)(AttentionHemLength)
