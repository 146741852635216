// @flow
import React from 'react'
import styled from 'styled-components'

import { Fit, BrandName } from '../../types'
import label0Png from '~/assets/img/label-good.png'
import label1Png from '~/assets/img/label-yuttari.png'
import label2Png from '~/assets/img/label-shikkari.png'
import { addComma } from '~/helpers/number'
import { Review } from '../../types'
import CheckButton from '../iconButtons/Check'
import CloseButton from '../iconButtons/Close'
import ZoomButton from '../iconButtons/Zoom'
import TryButton from '../iconButtons/Try'

const labels = {
  fit: label0Png,
  loose: label1Png,
  tight: label2Png
}

type Props = {
  unknownShoeName: string,
  handleReviewStart: (uuid: string ) => () => void,
  handleBuy: (unknownShoeName: string ) => () => void,
  review:  Review
}

function UnknownProduct({ unknownShoeName,  handleBuy, handleReviewStart, review }: 
Props) {
  console.log("UnknownProduct", unknownShoeName)
  let data = ['','', '', '']
  if ( unknownShoeName) {
    data  = unknownShoeName.split('_');
  } 

  let  answer = false
  if ( review) {
    const qna = review.content.shoeReview.find(e => e.questionId == 'fitting_log_shoe_7')
    answer = qna.answer? true: false
  }

  return (
    <Root>
     <StyledCheckButton dir="on" onClick={handleBuy(unknownShoeName)} />
      <Item>
      <Thumbnail>

      </Thumbnail>
    <Description>
        <dl>
          <dt>ブランド</dt>
          <dd>{data[0]}</dd>
          <dt>品番</dt>
          <dd>{data[2]}</dd>
          <dt>サイズ</dt>
          <dd>{`${data[1]}`}</dd>
          <dt>色</dt>
          <dd>{data[3]}</dd>
        </dl>
    </Description>

      { !answer && 
      <StyledTryButton onClick={handleReviewStart(`${unknownShoeName}`)}>
          試着<br />スタート 
      </StyledTryButton>
      }

      { answer && 
      <StyledTryButton  color='gray' disabled={true}>
          試着<br />完了
      </StyledTryButton>
      }

      </Item>
     <StyledCloseButton dir="on" onClick={handleBuy(`${unknownShoeName}`)} />

    </Root>
  )
}

export default UnknownProduct

const Root = styled.div`
  position: relative;
  display: flex;
`    
const Item = styled.div`
  display: flex;
  background-color: #fff;
  border-radius: 60px;
  width: 760px;
`    

const StyledCheckButton = styled(CheckButton)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: -80px;
`
const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: -80px;
`
const StyledZoomButton = styled(ZoomButton)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: -80px;
`
const StyledTryButton = styled(TryButton)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: -1px;
`

const Thumbnail = styled.figure`
  position: relative;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) =>  theme.color.grey4};
  margin: 0 5px;
  overflow: hidden;
`

const Label = styled.div`
  text-align: center;
  width: 130px;
  margin: 0 auto;
  position: relative;
  top: 15px;
`

const Description = styled.div`
  margin-left: 100px;
  margin: auto 0 auto 100px;
  font-size: 14px;
  font-weight: bold;
  > dl > dt {
	  float: left;
	  clear: left;
	  margin-right: 0.5em;
	  width: 60px;
  }
  > dl > dd {
	    float: left;
	    margin-left: 1em;
  }  
`

const Price = styled.div`
  span {
    font-size: 17px;
    font-family: ${({ theme }) => theme.font.english};
    &:before {
      content: '¥';
    }
  }
  &:after {
    content: '(税抜)';
    font-size: 10px;
    margin-left: 2px;
  }
`
