// @flow
import React from 'react'
import styled from 'styled-components'
import img1 from './img/button1.png'

type Props = {
  type: number
}

const imgTypes = [img1]

function Button({ type, ...rest }: Props) {
  return (
    <Root {...rest}>
      <img src={imgTypes[type]} width={138} alt="" />
    </Root>
  )
}

export default Button

const Root = styled.button``
