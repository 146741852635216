// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import ArrowButton from './iconButtons/Arrow'
import Button from './buttons/ButtonA'
import QRCode from './QRcode'
import Product from './purchase/Product'
import UnknownProduct from './purchase/UnknownProduct'
import titlePng from './img/try-list/title.png'
import { hot } from 'react-hot-loader'
import { RecommendEntity, Review, ReviewQnA } from '../types'
import Modal from './Modal'
import ProductModal from './ProductModal'
import type {RouterHistory} from 'react-router-dom';
import AddNewItemModal from './tryList/AddNewItemModal'

const questionId = 'fitting_log_shoe_8'
type Props = {
  appActions: Object,
  recommendActions: Object,
  favoriteItems: Array<RecommendEntity>,
  reviews: Array<Review>,
  unknownItems: Array<string>,
  history:RouterHistory
}

type State = {
  purchases:  Array<string>,
  list: boolean,
  qna: ?ReviewQnA
}

class Purchase extends Component<Props, State> {
  state = {
    purchases: [],
    list: true,
    qna:  this.props.reviews[0]?  this.props.reviews[0].content.shoeReview.find( e =>  e.questionId == questionId): null
  }
  
  handleComplete = () => {
    this.setState({list: false})
  }

   handleSelect =   (value: string)  =>   async () => {
    const { recommendActions, history, appActions } = this.props
    // エラー（
    if (this.state.qna == null ) return

    if ( value == 'いいえ')　{
      this.setState({list: true})
      return
    } 
    let tmpReviews = this.props.reviews.map ( review => {
      let tmpReview = {...review}
      let idx = tmpReview.content.shoeReview.findIndex(e => e.questionId == questionId)
      if ( tmpReview.content.shoeReview[idx]) tmpReview.content.shoeReview[idx]['answer'] =  value
      return tmpReview
    })

    // 購入APIの実行
    let actions = []
    tmpReviews.forEach( (e) => {
      actions.push(recommendActions.onRegisterReview(e))
    })
    const res = await Promise.all(actions)

    //recommendActions.onUpdateReviews(tmpReviews)
    recommendActions.onUpdateReviews([])
    recommendActions.onResetFavorite()
    history.push(`/`)
    //history.push('/recommned')
  }

  handlePurchase =  (itemId: string)  => () => {
    let tmpPurchases = [...this.state.purchases]
    if(tmpPurchases.includes(itemId)) {
      tmpPurchases = tmpPurchases.filter(e => e !== itemId)
    } else {
      tmpPurchases.push(itemId)
    }
    this.setState({purchases: tmpPurchases})
  }

  componentDidMount() {
    //this.props.appActions.dataLayerPush({ pageID: 'trylist' })
  }

  render() {
    const { appActions, favoriteItems, unknownItems } = this.props
    return (
      <Root>
        { this.state.list && 
        <List>
          <Title>
            購入された靴を選択してください。
          </Title>
          <Products>
            {favoriteItems.map(item => {
              return (
                <li key={item.itemId}>
                  <Product
                    data={{
                      itemId: item.itemId,
                      unknownShoeName: null,
                      maker: item.brandName,
                      productNumber: item.productNumber,
                      size: `${item.makerSize}${item.unit}`,
                      width: `${item.makerWidth}`,
                      color: item.makerColor,
                      price: item.price
                    }}
                    image={item.pictureUri[0]}
                    handlePurchase={this.handlePurchase}
                    purchase={this.state.purchases.includes(`${item.itemId}`)}
                  />
                </li>
              )
            })}
            {unknownItems.map(item => {
              const d = item.split('_')
              return (
                <li key={item}>
                  <Product
                    data={{
                      itemId: null,
                      unknownShoeName: item,
                      maker:d[0],
                      productNumber: d[2],
                      size: `${d[1]}cm`,
                      width: null,
                      color: d[3],
                      price:  null,
                    }}
                    image={null}
                    handlePurchase={this.handlePurchase}
                    purchase={this.state.purchases.includes(item)}
                  />
                </li>
              )
            })}

          </Products>
        <ComplateButton onClick={this.handleComplete}>入力完了</ComplateButton>
        </List>
        }
        { !this.state.list && 
          <Select>
            <ConfirmTitle>今回お客様がご購入された靴は、<br />{`「${this.state.purchases.length}足」でお間違いないでしょうか？`}</ConfirmTitle>
            <SelectButtons>
            { this.state.qna && this.state.qna.options.map(( v, idx) => {
            return (
              <Item
                key={idx}
                onClick={ this.handleSelect(v)}
              >
              {v}
              </Item>
            )
            })}
            </SelectButtons>
          </Select>
        }
      </Root>
    )
  }
}

export default hot(module)(Purchase)

const Root = styled.div`
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.color.grey8};
  width: 100vw;
  height: 100vh;
`

const BackButton = styled(ArrowButton)`
  position: fixed;
  top: 15px;
  left: 15px;
`

const ComplateButton = styled(Button)`
  position: fixed;
  top: 25px;
  right: 25px;
`

const List = styled.div`
`

const Title = styled.div`
  padding: 80px 0 20px 0;
  text-align: center;
`
const ConfirmTitle = styled.div`
  padding: 80px 0 20px 0;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
`

const Products = styled.ul`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  &:after {
    content: ' ';
    min-width: 5px;
  }
  li {
    margin: 10px 22px;
    /* flex: 1 1 auto; */
  }
`
const Select = styled.div`
  position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`

const SelectButtons = styled.div`
    display: flex;
  flex-wrap: wrap;
  margin: -5px;
`

const Item = styled.div`
  line-height: 40px;
  background: #fff;
  padding: 5px 10px;
  color: #707070;
  border-radius: 25px;
  margin: 5px;
  width: 200px;
  text-align: center;
  font-size: 17px;
`