// @flow
import React from 'react'
import styled from 'styled-components'
import CloseButton from '../iconButtons/Close'
import { Transition } from 'react-spring'
import * as easings from 'd3-ease'

type Props = {
  src: string,
  onDelete: () => void
}

const SubThumbnail: React$StatelessFunctionalComponent<Props> = React.memo(
  ({ src, onDelete }) => (
    <Transition
      from={{ transform: 'scale(0)' }}
      enter={{ transform: 'scale(1)' }}
      leave={{ transform: 'scale(0)' }}
      items={src}
      config={{
        duration: 300,
        easing: easings.easeBackOut
      }}
    >
      {src =>
        src &&
        (props => (
          <Root style={props}>
            <ThumbnailWrapper>
              <Thumbnail src={src} />
            </ThumbnailWrapper>
            <CloseButtonWrapper>
              <CloseButton onClick={onDelete} />
            </CloseButtonWrapper>
          </Root>
        ))
      }
    </Transition>
  )
)

export default SubThumbnail

const Root = styled.div`
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  border: ${({ theme }) => `2px solid ${theme.color.yellow}`};
`

const ThumbnailWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
`

const Thumbnail = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: -17px;
  right: -17px;
  pointer-events: auto;
`
