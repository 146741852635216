import webgl from './index';
import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import readyDots from './readyDots';
import readyDesc from './readyDesc';
import readyRadar from './readyRadar';


export default class ready extends object3d {


  constructor(opt) {

    super(opt);

    this._dots = undefined
    this._desc = undefined
    this._radar = undefined
    this._btn = undefined
    this._isMeasure = false
    this._isComplete = false
    this._restart = undefined

    this.anm = undefined
    this.onClick = undefined
    this.onComplete = undefined

  }


  init() {

    super.init();

    this.anm = {
      wait:{value:0}
    }


    this._radar = new readyRadar()
    this._radar.init()
    this.add(this._radar);

    this._dots = new readyDots({
      radar:this._radar
    })
    this._dots.init()
    this.add(this._dots);

    /*
    this._desc = new readyDesc();
    this._desc.init();
    this.add(this._desc);
    */

    this._btn = document.createElement('div');
    this._btn.setAttribute('id', 'webgl-readyOkBtn');
    this._btn.style.position = 'absolute';
    this._btn.style.backgroundColor = '#FF0000';
    this._btn.style.opacity = 0;
    this._btn.style.width = '200px';
    this._btn.style.height = '200px';
    this._btn.style.display = 'none'
    this._btn.addEventListener('click', () => {
      this._dots.showText()
      this.startMeasure();
    })
    document.getElementById('app-root').appendChild(this._btn);

    this.resize();

  }

  restart() {
    this._isMeasure = false
    this._isComplete = false
    document.getElementById('app-root').appendChild(this._btn);
    this._btn.style.display = 'block'

    this._dots.stopMeasure()
    this._dots.restart({
      onComplete:() => {
        this._btn.style.display = 'block'
      }
    })

/*
    this._desc.show({
      delay:0.75
    });
    */

  }



  show() {

    this._dots.show({
      onComplete:() => {
        //this._btn.style.display = 'block'
        this.startMeasure();
      }
    });

    /*
    this._desc.show({
      delay:3.75
    });
    */

  }


  // 計測中
  startMeasure() {

    if(this._isMeasure) {
      return
    }
    this._isMeasure = true

    // 計測開始をReact側に知らせる
    if(this._dots._okBtn.visible){
      webgl.startMesurement()
    }

    // ボタン消す
    document.getElementById('app-root').removeChild(this._btn)

/*
    this._desc.hide({
      duration:2,
      delay:1
    });
    */

    this._dots.hide({
      delay:0
    });
    this._dots.startMeasure()

    this._radar.show({
      delay:0
    });

  }


  // 完了
  startComplete() {
    if(this._isComplete || !this._isMeasure) {
      return
    }
    this._isComplete = true

    this._radar.hide({
      delay:0
    });

    this._dots.startComplete({
      delay:0
    });

    /*
    this._desc.startComplete({
      delay:0.6
    });
    */

    this._dots.startNext({
      delay:1.5,
      onComplete:() => {
        if(this.onComplete != null) {
          this.onComplete()
        }
      }
    })

  }


  update() {

    super.update();

  }


  resize() {

    super.resize();

    this._btn.style.top = (this.sh - 250) + 'px';
    this._btn.style.left = (this.sw - 250) + 'px';

  }

}
