// @flow
import React from 'react'
import styled from 'styled-components'

import labelPng from './img/recommend.png'
import titlePng from './img/title.png'

type Props = {}

function SearchResultTitle({ ...props }: Props) {
  return (
    <Root {...props}>
      <Label>
        <img src={labelPng} alt="Recommend" width={243} height={56} />
      </Label>
      <Title>
        <img
          src={titlePng}
          alt="お気に入りの靴をタップして試着してみよう！"
          width={640}
        />
      </Title>
    </Root>
  )
}

export default SearchResultTitle

const Root = styled.div`
  text-align: center;
`

const Label = styled.div`
  margin-bottom: 30px;
`

const Title = styled.div``
