// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import IconClose from '~/components/iconButtons/Close'
import typeJson from '~/assets/json/foottype.json'
import webgl from '~/webgl'

type Props = {
  memo: string,
  handleClose: () => void,
  changeMemoText:() => void,
  isEdit: boolean
}

type State = {
}

class MemoModal extends Component<Props, State> {

  state = {
  }

  componentDidUpdate() {
    if( this.props.isEdit ) {
      this.refs['textarea'].focus();
    }
  }


  componentDidMount() {
  }

  componentWillUnmount() {
    //webgl.resume()
  }

  render() {
    const { handleClose  } = this.props

    return (
      <Root>
        <CloseButton onClick={handleClose} />
        <Title>お客様の感想</Title>
        <hr />
        <Contents>
        <Textarea 
          value={this.props.memo} 
          placeholder="ご意見・ご感想をご記入ください" 
          onChange={this.props.changeMemoText} 
          ref="textarea"
        />
        </Contents>
      </Root>
    )
  }
}

export default MemoModal

const Root = styled.div`
  width: 750px;
  height: 550px;
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px;
`

const CloseButton = styled(IconClose)`
  position: absolute;
  top: 12px;
  right: 12px;
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.font.bold};
  font-size: 12px;
  text-align: center;
  margin-bottom: 5px;
`

const Heading = styled.div`
  font-size: 18px;
  line-height: 2;
  margin-bottom: 25px;
`

const Contents = styled.div`
  padding: 0 25px;
  letter-spacing: 2px;
  width: 700px;
  height: 400px;
`

const Textarea = styled.textarea`
  width: 100%;
  height: 100%;
  border:none;
`