// @flow
import React from 'react'
import styled from 'styled-components'
// import iconPng from './img/icon.png'

type Props = {
  onLogin?: () => void,
  onHelp: () => void,
  user?: Object
}

// function LoginButton({ onLogin }: { onLogin: Function }) {
//   return (
//     <li onClick={onLogin}>
//       <img src={iconPng} width={44} height={44} />
//       <p>QRコードログイン</p>
//     </li>
//   )
// }

// function User({ data }: Object) {
//   return (
//     <li>
//       <UserThumbnail src={data.thumbnailUrl} width={44} height={44} />
//       <p>
//         ようこそ {data.name}
//         <span>さん</span>
//       </p>
//     </li>
//   )
// }

function HomeMenu({ onHelp, ...rest }: Props) {
  return (
    <Root {...rest}>
      {/* {user.userId != null ? (
        <User data={user} />
      ) : (
        <LoginButton onLogin={onLogin} />
      )} */}
      <li onClick={onHelp}>ヘルプ</li>
    </Root>
  )
}

export default HomeMenu

const Root = styled.ul`
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  li {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 50px;
    }
    span {
      font-size: 13px;
    }
  }
`

const UserThumbnail = styled.img`
  border-radius: 50%;
`
