// @flow
import axios from 'axios'
import { actions as appActions } from './app'
import { normalize, denormalize, schema } from 'normalizr'
import humps from 'humps'
import { cloudApi } from '~/api'
import createTagEntities from '~/helpers/createTagEntities'
import { Tags, SelectedTagItems, RecommendEntity, Review } from '../../types'
import pictureUrl from '~/helpers/pictureUrl'

// $FlowFixMe
const { CLOUDV1, reviewType, shoeReviewTags,shoeFeatureTags  } = process.env.CONFIG

// Actions
const REGISTER_TAGS = 'recommend/REGISTER_TAGS'
const REGISTER_RECOMMEND_ENTITIES = 'recommend/REGISTER_RECOMMEND_ENTITIES'
const UPDATE_REVIEW = 'recommend/UPDATE_REVIEW'
const UPDATE_UNKNOWNSHOEITEMS = 'recommend/UPDATE_UNKNOWNSHOEITEMS'
const UPDATE_CUSTOMER_DATA = 'recommend/UPDATE_CUSTOMER_DATA'
const UPDATE_FAVORITES = 'recommend/UPDATE_FAVORITES'
const SELECT_TAG = 'recommend/SELECT_TAG'
const REMOVE_TAG = 'recoomend/REMOVE_TAG'



export const recommendItemsSchema = new schema.Entity(
  'recommendItems',
  {},
  { idAttribute: 'itemId' }
)

export const actions = {

fetchInitialize : (digitizerCode: string) => async (
  dispatch: any
) => {
  return new Promise(async (resolve, reject) => {
    const { data } = await cloudApi({
      method: 'get',
      url: `/storeapp/${digitizerCode}/init`
    })
    if (data.statusCode === 200) {
      const { shoeImages, tags, storeInfo } = data.data
      const {code} = data.data.clientInfo

      resolve({ 
        clientCode:code, 
        shoeImages, 
        tags ,
        target: (storeInfo.target? storeInfo.target: []), 
        jisWidthDisplay:  ('jisWidthDetailDisplay' in  storeInfo) ? storeInfo.jisWidthDetailDisplay: true,
        karteDataLevel: ('karteDataLevel' in  storeInfo) ? storeInfo.karteDataLevel: "1"  
      })

    } else {
      console.error(data)
      //dispatch(appActions.networkError(data.message))
      dispatch(appActions.networkError("初期設定がされていません。<br/>サポートセンターまでご連絡下さい。</br>"))
      reject()
    }
  })
},

getSameSizeItemsAndReviews: (itemId: number ) => (
    dispatch: any, getState: any
  ): Promise<any> => {
    return new Promise(async resolve => {
      try {
        const {
          auth: {token}, 
          recommend: {items}, 
          entities: {recommendItems}
          }= getState()


        const authToken: string = token || ''
        const {data} = await cloudApi({
          headers: {
            Authorization: `JWT ${authToken}`
          },
          method: 'get',
          url: `/items/${itemId}`,
        })
        
        if (data.statusCode === 200) {
          const sameItems = data.product.measurements.map( v => { 
            return { itemId:  v.sameMeshItemId, label: v.makerSize } 
          })
          // itemsに靴の特徴を登録する
          const  shoeFeatures = shoeFeatureTags.map ( e => {
            let feature = {...e}
            switch(e.questionId){
            case  "shoe_feature_1":
              // 足幅の広さ
              switch(data.measurement.widthClass) {
                case "very_narrow":
                case "narrow":
                  feature['answer'] = e.options[0]
                  break
                case "standard":
                  feature['answer'] = e.options[1]
                  break
                case "wide":
                case "very_wide":
                  feature['answer'] = e.options[2]
                  break
                default:
                  break
              }
              break
            case  "shoe_feature_2":
              feature['answer'] =  data.product.cushions.map(e => e.partName)
              break
            case  "shoe_feature_3":
              feature['answer'] =  data.mesh.stretch
              break
            default:
              break
            }
            return feature
          })

          let denormalizeData =  denormalize(items, [recommendItemsSchema], { recommendItems })
          console.log("getSameSizeItemsANdReviews", denormalizeData)
          let reviews = []
          data.product.measurements.forEach(v => { 
            reviews = reviews.concat(v.reviews)
            if ( itemId == v.sameMeshItemId) return
            const tmpIndex = denormalizeData.findIndex(e => e.itemId == v.sameMeshItemId)
            if (tmpIndex != -1 ) return 
            let sizeData = JSON.parse(JSON.stringify(denormalizeData.find(e2 => e2.itemId == itemId)))
            sizeData['itemId'] = v.sameMeshItemId
            sizeData['ffMakerSize'] = v.ffMakerSize
            sizeData['makerSize'] = v.makerSize
            sizeData['makerWidth'] = v.makerWidth
            sizeData['recommendItemId'] = itemId
            denormalizeData.push(sizeData)
          })

          // 正規化
          const normalizedData = normalize(denormalizeData, [recommendItemsSchema])
          dispatch({
            type: REGISTER_RECOMMEND_ENTITIES,
            payload: { ...normalizedData }
          })
          
          resolve({ size: sameItems, reviews :reviews, shoeFeatures:  shoeFeatures})
        } else {
          dispatch(appActions.networkError(`サイズ展開の取得に失敗しました。<br/>${data.message}`)
          )
          throw new Error()
        }
      } catch (error) {
        console.error(error)
      }
    })
},

/*
getReviews: (uuid: number ) => (
    dispatch: any, getState: any
  ): Promise<any> => {
    return new Promise(async resolve => {
      try {
        const {auth: {token}}= getState()
        const authToken: string = token || ''
        console.log("")
        const {data} = await cloudApi({
          headers: {
            Authorization: `JWT ${authToken}`
          },
          method: 'get',
          url: `/items/${uuid}/reviews`,
        })
        
        if (data.statusCode === 200) {
          resolve( data.data)
        } else {
          dispatch(appActions.networkError(`Recommend詳細情報の取得に失敗しました。<br/>${data.message}`)
          )
          throw new Error()
        }
      } catch (error) {
        console.error(error)
      }
    })
},
*/



registerRecommend: ({ size, width,filter }: { size: number, width: number, filter: any }) => (
    dispatch: any,
    getState: any
  ): Promise<any> => {
    return new Promise(async resolve => {
      try {
        const {
          app: { clientCode, digitizerCode,filter },
          recommend: { tags }
        } = getState()
        // 開発時のみ固定値
        if (process.env.NODE_ENV === 'development') {
          size = 220.0
          width = 87.5
        }
        console.log( "filter" , filter )
        const response = await axios({
          method: 'post',
          url: `${CLOUDV1}/storeapp/recommendation`,
          headers: {
            'X-CLIENT-CODE': clientCode,
            'X-DIGITIZER-CODE': digitizerCode
          },
          data: { size, width, filter }
        })
        const camelizeResponse = humps.camelizeKeys(response)
        if (camelizeResponse.status !== 200) {
          dispatch(appActions.networkError('Recommend情報の取得に失敗しました。<br>再撮影してください。<br/><br/>RECOMMNEDAPIエラー'))
          throw new Error()
        }
        const { data } = camelizeResponse
        if (data.statusCode === 200) {
          // pictureUriのフォーマットをフラットな配列に直す
          const json = data.data.map(obj => {
            const tmp = { ...obj }
            const pictureUri = Object.keys(obj.pictureUri).map(key => {
              return pictureUrl(obj.pictureUri[key])
            })
            tmp.pictureUri = pictureUri
            return tmp
          })
          
          // 検索用のタグ生成
          const { tagItems, tagList } = createTagEntities(tags, json)
          dispatch({
            type: REGISTER_TAGS,
            payload: { tagItems, tagList }
          })
          // 正規化
          const normalized = normalize(json, [recommendItemsSchema])
          dispatch({
            type: REGISTER_RECOMMEND_ENTITIES,
            payload: { ...normalized }
          })
          resolve({ ok: true })
        } else {
          dispatch(appActions.networkError(`Recommend情報の取得に失敗しました。<br/>${data.message}`)
          )
          throw new Error()
        }
      } catch (error) {
        console.error(error)
      }
    })
  },
  onSelect: (itemId: number) => async (dispatch: any, getState: any) => {
    console.log("ONselect")
    const {
      recommend: { favorites }
    } = getState()
    let tmpFavorites = [...favorites]
    if (favorites.includes(itemId)) {
      tmpFavorites = favorites.filter(fid => fid !== itemId)
    } else {
      tmpFavorites.push(itemId)
    }
    dispatch({
      type: UPDATE_FAVORITES,
      payload: { favorites: tmpFavorites }
    })
  },
  onDeleteFavorite: (itemId: number) => (dispatch: any, getState: any) => {
    const {
      recommend: { favorites }
    } = getState()
    dispatch({
      type: UPDATE_FAVORITES,
      payload: { 
        favorites: favorites.filter(fid => fid !== itemId)
       }
    })

    // same_idリストも削除
  },

  onUpdateUnknownShoeName: (UnknownShoeName: string ) => (dispatch: any, getState: any) => {
    const {
      recommend: { unknownItems }
    } = getState()
    let tmpunknownItems = [...unknownItems]
    if (unknownItems.includes(UnknownShoeName)) {
      tmpunknownItems = unknownItems.filter( e => e !== UnknownShoeName)
    } else {
      tmpunknownItems.push(UnknownShoeName)
    }
    dispatch({
      type: UPDATE_UNKNOWNSHOEITEMS,
      payload: { unknownItems:  tmpunknownItems }
    })
  },

  onUpdateReview: ( review: Review) => ( dispatch: any, getState: any) => {
    const {
      recommend: { reviews }
    } = getState()
    let tmpReviews = [...reviews]
    const idx =  tmpReviews.findIndex((e) => e.itemId == review.itemId)
    if ( idx != -1) {
      tmpReviews[idx] = review
    } else {
      tmpReviews.push(review)
    }

    dispatch({
      type: UPDATE_REVIEW,
      payload: { reviews: tmpReviews}
    })
  },

  onUpdatePurchaseReview: ( itemId: string, value: string) => ( dispatch: any, getState: any) => {
    const {
      recommend: { reviews },
      app: { staffName, clientCode , footUuid, footSurvey }
    } = getState()
    if (!itemId) return null;
    let tmpReviews = [...reviews]
    let  review
    const known = itemId.search(/^[0-9]+$/) 

    if ( known !== -1 ) {
      review = reviews.find( e => e.itemId == `${itemId}` ) 
    } else {
      review = reviews.find( e => e.unknownShoeName == itemId ) 
    }
    if (!review) {
      review  =  {
        itemId: (known !== -1) ? itemId: null ,
        unknownShoeName : (known === -1)? itemId: null ,
        type: reviewType,
        reviewerCode: staffName,
        footUuid: footUuid,
        clientCode: clientCode,
        content: {
          footSurvey: [...footSurvey],
          shoeReview: JSON.parse(JSON.stringify(shoeReviewTags))
        }
      }
    }
    // 合う合わないをセット
    let fitidx = review.content.shoeReview.findIndex(e => e.questionId == 'fitting_log_shoe_8')
    // value  0: 購入, 1:未購入 
    review.content.shoeReview[fitidx]['answer'] = review.content.shoeReview[fitidx]['options'][value]

    const idx =  tmpReviews.findIndex((e) => e.itemId == review.itemId)
    if ( idx != -1) {
      tmpReviews[idx] = review
    } else {
      tmpReviews.push(review)
    }

    console.log("onUpdatePurchaseReview", tmpReviews)

    dispatch({
      type: UPDATE_REVIEW,
      payload: { reviews: tmpReviews}
    })

  },
  onUpdateFitReview: ( itemId: string, value: string) => ( dispatch: any, getState: any) => {
    const {
      recommend: { reviews },
      app: { staffName, clientCode , footUuid, footSurvey }
    } = getState()
    if (!itemId) return null;
    let tmpReviews = [...reviews]
    let  review
    const known = itemId.search(/^[0-9]+$/) 

    if ( known !== -1 ) {
      review = reviews.find( e => e.itemId == `${itemId}` ) 
    } else {
      review = reviews.find( e => e.unknownShoeName == itemId ) 
    }
    if (!review) {
      review  =  {
        itemId: (known !== -1) ? itemId: null ,
        unknownShoeName : (known === -1)? itemId: null ,
        type: reviewType,
        reviewerCode: staffName,
        footUuid: footUuid,
        clientCode: clientCode,
        content: {
          footSurvey: [...footSurvey],
          shoeReview: JSON.parse(JSON.stringify(shoeReviewTags))
        }
      }
    }
    // 合う合わないをセット
    let fitidx = review.content.shoeReview.findIndex(e => e.questionId == 'fitting_log_shoe_7')
    review.content.shoeReview[fitidx]['answer'] = value

    let impressionidx = review.content.shoeReview.findIndex(e => e.questionId == 'fitting_log_shoe_1')
    // 履き心地
    if ( review.content.shoeReview[fitidx]['options'][0] ==  value ) {
      review.content.shoeReview[impressionidx]['answer'] = review.content.shoeReview[impressionidx]['options'][1]
    } else {
      if (review.content.shoeReview[impressionidx]['answer'] && 
        review.content.shoeReview[impressionidx]['answer'] == review.content.shoeReview[impressionidx]['options'][1]
      ) {
        review.content.shoeReview[impressionidx]['answer'] = null
      }
    }

    const idx =  tmpReviews.findIndex((e) => e.itemId == review.itemId)
    if ( idx != -1) {
      tmpReviews[idx] = review
    } else {
      tmpReviews.push(review)
    }

    dispatch({
      type: UPDATE_REVIEW,
      payload: { reviews: tmpReviews}
    })

  },

  onUpdateReviews: ( reviews: Array<Review>) => ( dispatch: any, getState: any) => {
    dispatch({
      type: UPDATE_REVIEW,
      payload: { reviews: reviews}
    })
  },
  onResetFavorite: () => ( dispatch: any, getState: any) => {
    dispatch({
      type: UPDATE_FAVORITES,
      payload: { 
        favorites: []
       }
    })
  },


  onRegisterReview: (review: Review) => async ( dispatch: any,  getState: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      const {auth: {token}}= getState()
      const authToken: string = token || ''
      const response = await cloudApi({
        headers: {
          Authorization: `JWT ${authToken}`
        },
        method: 'post',
        url: `/reviews`,
        data: review
      })
      if (response.data.statusCode === 200) {
        return resolve({ ok: true })
      } else {
        dispatch(appActions.networkError(`試着リストの登録に失敗しました<br />${response.data.message}`))
        return reject()
      }
    })
  },

  onSelectTag: (selectedTag: string, selectedLabel: string) => {
    return {
      type: SELECT_TAG,
      payload: { selectedTag, selectedLabel }
    }
  },
  onRemoveTag: (selectedTag: string) => {
    return {
      type: REMOVE_TAG,
      payload: { selectedTag }
    }
  }
}

// Reducer
export type State = {
  tags: Tags,
  availableTags: Tags,
  selectedTags: SelectedTagItems,
  nonSelectTags: SelectedTagItems,
  items: ?Array<number>,
  favorites: Array<number>,
  reviews: Array<Review>,
  unknownItems: Array<string>
}

const initialState = {
  tags: [
    'brandName',
    'categoryName',
    'shoeTypeName',
    'targetName',
    'strap',
    'shoelace',
    'fit',
    'heelTypeName',
    'heelHeight',
    'ffColorId',
    'ffMaterial'
  ],
  availableTags: [],
  selectedTags: {
    brandName: null,
    categoryName: null,
    shoeTypeName: null,
    targetName: null,
    strap: null,
    shoelace: null,
    fit: null,
    heelTypeName: null,
    heelHeight: null,
    ffColorId: null,
    ffMaterial: null
  },
  nonSelectTags: {
    brandName: [],
    categoryName: [],
    shoeTypeName: [],
    targetName: [],
    strap: null,
    shoelace: null,
    fit: [],
    heelTypeName: [],
    heelHeight: [],
    ffColorId: [],
    ffMaterial: []
  },
  items: null,
  favorites: [],
  unknownItems: [],
  reviews: []
}

export default function reducer(state: State = initialState, action: any) {
  switch (action.type) {
    case REGISTER_RECOMMEND_ENTITIES: {
      const { result } = action.payload
      return { ...state, items: result }
    }
    case UPDATE_REVIEW: {
      const { reviews } = action.payload
      return { ...state, reviews }
    }
    
    case UPDATE_UNKNOWNSHOEITEMS: {
      const { unknownItems} = action.payload
      return { ...state, unknownItems }
    }
    case UPDATE_CUSTOMER_DATA: {
      const { customer } = action.payload
      return { ...state, customer }
    }
    case UPDATE_FAVORITES: {
      const { favorites } = action.payload
      return { ...state, favorites }
    }
    case REGISTER_TAGS: {
      const { tagItems, tagList } = action.payload
      return { ...state, nonSelectTags: tagItems, availableTags: tagList }
    }
    case SELECT_TAG: {
      const { selectedTag, selectedLabel } = action.payload
      const tmpState = { ...state }
      state.selectedTags[selectedTag] = selectedLabel
      return tmpState
    }
    case REMOVE_TAG: {
      const { selectedTag } = action.payload
      const tmpState = { ...state }
      state.selectedTags[selectedTag] = null
      return tmpState
    }
  }
  return state
}

// misc
export const selectItems = ({
  recommend: { items },
  entities: { recommendItems }
}: Object) => {
  return denormalize(items, [recommendItemsSchema], { recommendItems })
}

export const selecteFavorites = ({
  recommend: { favorites },
  entities: { recommendItems }
}: Object) => {
  return denormalize(favorites, [recommendItemsSchema], { recommendItems })
}


export const selecteReview = ({
  recommend: { reviews },
  app: { staffName, clientCode , footUuid, footSurvey }
}: Object, {match : { params: {itemId}}} : Object) => {

  if (!itemId) return null;

  const known = itemId.search(/^[0-9]+$/) 

  let  review
  if ( known !== -1 ) {
    review = reviews.find( e => e.itemId == `${itemId}` ) 
  } else {
    review = reviews.find( e => e.unknownShoeName == itemId ) 
  }
  if (review) return review
  let newReview  =  {
    itemId: (known !== -1) ? itemId: null ,
    unknownShoeName : (known === -1)? itemId: null ,
    type: reviewType,
    reviewerCode: staffName,
    footUuid: footUuid,
    clientCode: clientCode,
    content: {
      footSurvey: [...footSurvey],
      shoeReview: JSON.parse(JSON.stringify(shoeReviewTags))
    }
  }
  return newReview
}

// Uuid指定の
export const selectReviewItem = ({
  entities: {recommendItems}
}: Object, {match : { params: {itemId}}} : Object) => {
  if (!itemId) return null;
  if (!recommendItems) return null;

  const known = itemId.search(/^[0-9]+$/) 
  if ( known == -1 ) return null 

  return recommendItems[itemId]  
}
