// @flow
import React, { Fragment, Component } from 'react'
import styled from 'styled-components'
import BooleanList from './BooleanList'
import StringList from './StringList'
import ColorList from './ColorList'
import HeelHeightList from './HeelHeightList'
import map from 'lodash/map'
import compact from 'lodash/compact'
import { NonSelectTagItems, SelectedTagItems, Tags } from '../../types'

type Props = {
  tags: Tags,
  nonSelectTagItems: NonSelectTagItems,
  selectedTagItems: SelectedTagItems,
  handleSelectTag: (tag: string, label: string) => void,
  handleRemoveTag: (tag: string) => void,
  favoriteSize: number
}

class RecommendMenu extends Component<Props, { prevTagSize: number }> {
  ref: any

  state = {
    prevTagSize: 0
  }

  constructor(props: any) {
    super(props)
    this.ref = React.createRef()
  }

  handleSelect = (selectedTag: string, selectedLabel: string) => {
    this.props.handleSelectTag(selectedTag, selectedLabel)
  }

  handleRemove = (selectedTag: string) => {
    this.props.handleRemoveTag(selectedTag)
  }

  componentDidUpdate() {
    const selectTags = compact(
      map(this.props.selectedTagItems, value => {
        if (value !== null) return value
      })
    )
    if (selectTags.length !== this.state.prevTagSize) {
      window.scrollTo(0, 0)
      this.ref.current.scrollTo(0, 0)
      this.setState({ prevTagSize: selectTags.length })
    }
  }

  render() {
    const { tags, nonSelectTagItems, selectedTagItems, ...rest } = this.props

    return (
      <Root ref={this.ref} {...rest}>
        <Wrapper>
          {/* 選択項目 */}
          <Ul>
            {tags.map(tag => {
              const label = selectedTagItems[tag]
              if (!label) return <Fragment key={`select-${tag}`} />
              if (tag === 'shoelace' || tag === 'strap') {
                return (
                  <BooleanList
                    key={`select-${tag}`}
                    tag={tag}
                    label={label}
                    onRemove={() => this.handleRemove(tag)}
                  />
                )
              }
              if (tag === 'heelHeight') {
                return (
                  <HeelHeightList
                    key={`select-${tag}`}
                    tag={tag}
                    label={label}
                    onRemove={() => this.handleRemove(tag)}
                  />
                )
              }
              if (tag === 'ffColorId') {
                return (
                  <ColorList
                    key={`select-${tag}`}
                    colorId={label}
                    onRemove={() => this.handleRemove(tag)}
                  />
                )
              }
              return (
                <StringList
                  key={`select-${tag}`}
                  tag={tag}
                  label={label}
                  onRemove={() => this.handleRemove(tag)}
                />
              )
            })}
          </Ul>

          {/* 未選択項目 */}
          {tags.map(tag => {
            if (selectedTagItems[tag] != null) {
              return <Fragment key={`non-select-${tag}`} />
            }
            return (
              <Ul key={`non-select-${tag}`}>
                {nonSelectTagItems[tag].map(label => {
                  if (tag === 'shoelace' || tag === 'strap') {
                    return (
                      <BooleanList
                        key={`non-select-${tag}-${label}`}
                        tag={tag}
                        label={label}
                        onSelect={() => this.handleSelect(tag, label)}
                      />
                    )
                  }
                  if (tag === 'heelHeight') {
                    return (
                      <HeelHeightList
                        key={`non-select-${tag}-${label}`}
                        tag={tag}
                        label={label}
                        onSelect={() => this.handleSelect(tag, label)}
                      />
                    )
                  }
                  if (tag === 'ffColorId') {
                    return (
                      <ColorList
                        key={`non-select-${tag}-${label}`}
                        colorId={label}
                        onSelect={() => this.handleSelect(tag, label)}
                      />
                    )
                  }
                  return (
                    <StringList
                      key={`non-select-${tag}-${label}`}
                      tag={tag}
                      label={label}
                      onSelect={() => this.handleSelect(tag, label)}
                    />
                  )
                })}
              </Ul>
            )
          })}
        </Wrapper>
      </Root>
    )
  }
}

export default RecommendMenu

const Root = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  overflow-x: auto;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  padding: 0 30px;
  &:after {
    content: ' ';
    min-width: 30px;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 75px;
  margin: 0 -7px;
`

const Ul = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  li {
    margin: 0 7px;
  }
`
