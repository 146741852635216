// @flow

// $FlowFixMe
const { pollingSettings } = process.env.CONFIG

function polling(
  asyncFunc: () => Object,
  timeout: number = pollingSettings.timeout,
  interval: number = pollingSettings.interval
): Promise<any> {
  const endTime = Date.now() + timeout
  const checkCondition = async (resolve, reject) => {
    const response = await asyncFunc()
    if (response.data.statusCode === 200) {
      resolve(response)
    } else if (response.data.statusCode < 500 && Date.now() < endTime) {
      setTimeout(checkCondition, interval, resolve, reject)
    } else {
      reject(new Error('ネットワークエラーが発生しました。'))
    }
  }
  return new Promise(checkCondition)
}

export default polling