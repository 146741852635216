import React, { Fragment } from 'react'
import styled from 'styled-components'

type Props = {
  title: string,
  options: Array<string>,
  answer: string,
  type: string
}


function Review({ title, options, answer, type, ...rest }: Props) {
  const opacity = options.length == 2 ? '0%': ''
  const stepWidth = 100 / options.length
  return (
        <Root  {...rest}>
          <Title>{title}</Title>
          <Answer>
         {options.map((item, index) => (
           
            <Step key={index} width={stepWidth}> 
              <Progress index={ index == 0 ? 'first' : (index == (options.length -1) ? 'last' : '') }  opacity={opacity} />
              <Dot selected={item == answer || answer.includes(item)? true :false }/>
              <Info>{item}</Info>
            </Step> 
          ))}
          </Answer>
        </Root>
  )
}
export default Review

const Root = styled.div`
  display: flex;
  margin: 10px;
`
const Title = styled.div`
	margin-right: 0.5em;
  width:25%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Answer = styled.div`
  display: -webkit-flex;
  display: flex;
  width: 100%;
`

const Step = styled.div`
  padding: 0; 
  position: relative;
  min-width: ${({width}) => width? `${width}%`: '33%' };
`
const Progress = styled.div`
  position: relative; 
  border-radius: 0px; 
  height: 1px; 
  box-shadow: none; 
  margin: 20px 0;
  display: flex;
  overflow: hidden;
  background-color: #707070;
  width: ${({ index  }) => index? '50%' : ''}
  left: ${({ index  }) => index && index == 'first'? '50%' : ''}
  opacity: ${({ opacity  }) => opacity ? opacity : ''}
`

const Dot = styled.span`
  position: absolute; 
  width: 10px; 
  height: 10px; 
  display: block; 
  background: #fff; 
  border: 1px solid #707070;
  top:  20px; 
  left: 50%; 
  margin-top: -5px; 
  margin-left: -5px; 
  border-radius: 50%;
  :after {
    display: ${({ selected }) => selected? 'block': 'none'};
    content: ' '; 
    width: 15px; 
    height: 15px; 
    background: #ff0; 
    border-radius: 510px; 
    position: absolute; 
    top: -3px; 
    left: -3px;
    opacity: 70%; 
  }
`
const Info = styled.div`
  color: #707070; 
  font-size: 12px;
  text-align: center;
`
