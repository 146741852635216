import glConf from './glConf';
import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import glType from './glType';
import glParam from './glParam';
import makingItem from './makingItem';
import makingSceneBase from './makingSceneBase';


export default class makingSceneF extends makingSceneBase {

  constructor(opt) {

    super(opt)

    this._num = undefined
    this._lineNum = undefined
    this._offset = undefined

  }


  init() {

    super.init();

    this._num = 14;
    this._lineNum = 3;
    this._offset = 0;

    for(let i = 0; i < this._num * this._lineNum; i++) {
      this._addItem(i);
    }
    this._getLastItem().setLast();

    this.resize();

  }


  _startBefore() {

    super._startBefore();

    this.rotation.z = glUtils.radian(glUtils.range(45));

  }


  update() {

    if(!glParam.isRender[glType.SCENE.MAKING] || !this.isUpdate) {
      return;
    }

    super.update();

    const radius = this.sw * 0.45;
    const num = this._items.length;
    for(let i = 0; i < num; i++) {

      const item = this._items[i];

      const key = i % this._num;
      const line = ~~(i / this._num);

      const intervalY = this.sh * 0.3;
      let offset = this._offset;
      if(line % 2 == 0) {
        offset *= -1;
      }

      const radian = glUtils.radian(offset + (360 / this._num) * key);

      item.position.x = Math.sin(radian) * radius;
      item.position.z = Math.cos(radian) * radius;
      item.position.y = line * intervalY - (intervalY * (this._lineNum - 1) * 0.5);

      item.position.y += Math.sin(radian) * radius * 0.25;

      item.setSize(100);
    }



    this._offset += 1;

  }


  resize() {

    super.resize();

  }


}
