// @flow
import React, { Component } from 'react'
import InputConfiguration from '~/components/input/Configuration'
import InputConfigurationRevist from '~/components/input/ConfigurationRevisit'
import styled from 'styled-components'
import type {RouterHistory} from 'react-router-dom';
import Button from './buttons/ButtonA'

type Props = {
  appActions: Object,
  history: RouterHistory,
  staffName: string,
  digitizerCode: string
}

type State = {
  visitdate: string,
  footId: string,
  error : string,
  errorDate: string
}

export default class FootidRevisit extends Component<Props, State> {
  state = {
    visitdate: '',
    footId: '',
    error: '',
    errorDate: ''
  }


  async componentDidMount() {
    this.props.appActions.hideLoading()
    //this.props.appActions.dataLayerPush({ pageID: 'home' })


    console.log("FootId Component Did Mount", this.props)
    if (!this.props.digitizerCode) {
      this.props.history.push('/digitizer')
    }
    if (!this.props.staffName) {
      this.props.history.push('/staff')
    }
  }

  changeDateText = (e: any) => {
    this.setState({visitdate: e.target.value});
  }

  changeText = (e: any) => {
    this.setState({footId: e.target.value});
  }

  handleDigitizer = () => {
    this.props.history.push('/digitizer')
  }

  handleStaff = () => {
    this.props.history.push('/staff')
  }

  handleUuid = () => {
    this.props.history.push('/footid')
  }

  nextStep = () => {
    let id = this.state.footId
    // バリデーション
    if( id.search(/^[0-9]+$/)  == -1) {
      this.setState({error: '半角数字を入力してください。'});
      return
    }
    console.log("DATE --> " , this.state.visitdate)
    if( this.state.visitdate.search(/^[0-9]+$/)  == -1 || this.state.visitdate.length != 8  ) {
      this.setState({errorDate: '半角数字8桁を入力してください。'});
      return
    }

    id = id.padStart(4, '0')
     const inputData = {
      staffName: this.props.staffName,
      footId: id,
      digitizerCode: this.props.digitizerCode,
      visitDate:this.state.visitdate
    }
    // dispacherでデータ保持
    this.props.appActions.setInput( inputData)
    this.props.history.push('/recommned')
  }

  render() {
    return (
      <Root>
      <InputConfigurationRevist
        title='お客様の前回の撮影日付（8桁)で入力してください。' 
        placeholder='タッチして日付を入力'
        value={this.state.visitdate}
        changeText={this.changeDateText}
        error={this.state.errorDate}
      />
      <InputConfiguration
        title='お客様のフリックフィットIDを入力してください' 
        placeholder='タッチしてID入力'
        value={this.state.footId}
        changeText={this.changeText}
        nextStep={this.nextStep}
        error={this.state.error}
      />
      <DigitizerButton onClick={this.handleDigitizer}>デジタイザー入力</DigitizerButton>
      <StaffButton onClick={this.handleStaff}>スタップ入力</StaffButton>
      <UuidButton onClick={this.handleUuid}>当日データを入力</UuidButton>
      </Root>
    )
  }
}

const Root = styled.div`
  position: relative;
  background-color: #F2F3F4;
  width: 100vw;
  height: 100vh;
`
const DigitizerButton = styled(Button)`
  position: fixed;
  bottom: 37px;
  left: 15px;
`
const StaffButton = styled(Button)`
  position: fixed;
  bottom: 37px;
  left: 200px;
`
const UuidButton = styled(Button)`
  position: fixed;
  bottom: 37px;
  right: 15px;
`