import vert from './shader/base.vert';
import frag from './shader/image.frag';

import object3d from './object3d';

export default class image extends object3d {

  constructor(opt) {

    super(opt)

    this.mesh = undefined
    this.width = 0
    this.height = 0

  }


  init() {

    super.init();

    this.mesh = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(1, 1),
      new THREE.ShaderMaterial({
        transparent:true,
        vertexShader:vert,
        fragmentShader:frag,
        uniforms:{
          tDiffuse:{value:this.opt.tex},
          alpha:{value:1},
          bright:{value:0}
        }
      })
    );
    this.add(this.mesh);

  }


  alpha(val) {

    this.mesh.material.uniforms.alpha.value = val;

  }


  fadeIn(opt) {

    TweenMax.to(this.mesh.material.uniforms.alpha, opt.duration, {
      value:1,
      delay:opt.delay,
      ease:opt.ease,
      onComplete:() => {
        if(opt.onComplete != null) {
          opt.onComplete()
        }
      }
    });

  }


  fadeOut(opt) {

    TweenMax.to(this.mesh.material.uniforms.alpha, opt.duration, {
      value:0,
      delay:opt.delay,
      ease:opt.ease,
      onComplete:() => {
        if(opt.onComplete != null) {
          opt.onComplete()
        }
      }
    });

  }


  zoomIn(opt) {

    this.scale.set(opt.scale, opt.scale, 1);
    TweenMax.to(this.scale, opt.duration, {
      x:1,
      y:1,
      delay:opt.delay,
      ease:opt.ease,
      onStart:opt.onStart,
      onComplete:opt.onComplete
    })

    this.fadeIn(opt);

  }



  zoomOut(opt) {

    TweenMax.to(this.scale, opt.duration, {
      x:opt.scale,
      y:opt.scale,
      delay:opt.delay,
      ease:opt.ease,
      onStart:opt.onStart,
      onComplete:opt.onComplete
    })

    this.fadeOut(opt);

  }



  slideIn(opt) {

    this.position.set(opt.x, opt.y, 1);
    TweenMax.to(this.position, opt.duration, {
      x:0,
      y:0,
      delay:opt.delay,
      ease:opt.ease,
      onStart:opt.onStart,
      onComplete:opt.onComplete
    })

    // this.mesh.material.uniforms.bright.value = 4
    // TweenMax.to(this.mesh.material.uniforms.bright, opt.duration, {
    //   value:0,
    //   delay:opt.delay,
    //   ease:opt.ease
    // });

    this.fadeIn(opt);

  }



  update() {

    super.update();

  }


  resize() {

    super.resize();

  }


  setSize(w, h) {

    this.mesh.scale.set(w, h, 1);

    this.width = w;
    this.height = h;

    switch(this.opt.origin) {
      case 'center':
        this.mesh.position.set(0, 0, 0);
        break;
      default:
        this.mesh.position.set(w * 0.5, -h * 0.5, 0);
    }

  }



}
