// @flow
import React from 'react'
import styled from 'styled-components'
import ArrowButton from '../iconButtons/Arrow'


type Props = {
  title: string,
  changeText: () => void ,
  nextStep?: () => void ,
  placeholder: string,
  value: string,
  fontColor?: ?string,
  error: string
}

function InputConfiguration({ title, placeholder, changeText,value, nextStep, fontColor, error}: Props) {
  const errorFlg = error? true: false
  return (
    <Root>
      <InputTextBox>
        <Text color={fontColor}>{title}</Text>
        {errorFlg && 
        <ErrorMsg>{error}</ErrorMsg>
        }
        <InputText type="text" name="text" value={value}
              onChange={changeText}
              placeholder={placeholder}
            />
      </InputTextBox>
      {nextStep && 
      <NextButtonBox>
        <NextButton
          dir="next"
          onClick={nextStep}
        />
      </NextButtonBox>
      }
    </Root>
  )
}

export default InputConfiguration

const Root = styled.div`
/*
  text-align: center;
  */
  display:flex;
  position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`

const InputTextBox = styled.div`
  text-align: center;
`

const Text = styled.p`
  margin-top: 18px;
  color: ${({ color }) => (color ? color : '#707070')};
  font-size: 18px;
  font-wiehgt: bold;
  margin-bottom: 18px;
`
const ErrorMsg = styled.p`
  margin-top: 10px;
  color: #f00;
  font-size: 12px;
  margin-bottom: 10px;
`
const InputText = styled.input`
  border:none;
  border-radius:30px;
  box-shadow: none;
  padding: 2px 8px;
  font-size: 30px;
  width: 640px;
  height: 60px;
  text-align: center;
  `

const NextButtonBox = styled.div`
`

const NextButton = styled(ArrowButton)`
  position: absolute;
  bottom: 10px;
  margin-left: 18px;
`


