// @flow
import * as React from 'react'
import styled from 'styled-components'
import checkedIcon from '../../assets/img/icon-checked-small.png'
import labelFittingPng from './img/label-fitting.png'
import TryButton from '../buttons/ButtonB'
import SubBlankThumbnail from '../thumbnail/SubBlankThumbnail'
import SubThumbnail from '../thumbnail/SubThumbnail'
import { RecommendEntity } from '../../types'

type Props = {
  empty: boolean,
  //items: Array<{ itemId: string, src: string }>,
  favoriteSize: number,
  onDelete: (itemId: number) => () => void,
  onTry: () => void
}

const SelectedItems: React$StatelessFunctionalComponent<Props> = React.memo(
  ({ empty, favoriteSize, onDelete, onTry, ...rest }) => {
    //const gradientCls = items.length >= 9 ? 'scroll' : ''
    const gradientCls = ''

    return (
      <Root {...rest}>
        <Wrapper>
          <img src={checkedIcon} width="24" height="24" />
          <Gradient className={gradientCls}>
          <Text>
            {favoriteSize}件
          </Text>
            {/* 
            <Scroll className={gradientCls}>
              <Thumbnails>
                {empty ? (
                  <li>
                    <SubBlankThumbnail />
                  </li>
                ) : (
                  items.map(({ itemId, pictureUri }: RecommendEntity) => (
                    <li key={itemId}>
                      <SubThumbnail
                        src={pictureUri[0]}
                        onDelete={onDelete(itemId)}
                      />
                    </li>
                  ))
                )}
              </Thumbnails>
            </Scroll>
            */}
          </Gradient>
          <TryButton disabled={empty} onClick={onTry()}>
            <img src={labelFittingPng} width={83} height={21} alt="試着する" />
          </TryButton>
        </Wrapper>
      </Root>
    )
  }
)

export default SelectedItems

const Root = styled.div`
  display: inline-block;
`

const Wrapper = styled.div`
  height: 74px;
  min-width: 342px;
  background: #fff;
  border-radius: 37px;
  display: flex;
  align-items: center;
  max-width: 814px;
  padding: 0 15px 0 30px;
  > :last-child {
    margin-left: auto;
    flex-shrink: 0;
  }
`

const Gradient = styled.div`
  position: relative;
  max-width: 544px;
  min-width: 100px;
  height: 100%;
  margin-right: 20px;
  margin-left: 20px;
  pointer-events: auto;
  &.scroll {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 100%;
      background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #fff);
      pointer-events: none;
      transform: translateZ(0);
      z-index: 1;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 100%;
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
      pointer-events: none;
      transform: translateZ(0);
    }
  }
`
const Text = styled.div`
  width: 100%;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform : translate(-50%,-50%);
  transform : translate(-50%,-50%);
  text-align: center;
`

const Scroll = styled.div`
  width: 100%;
  height: 100%;
  &.scroll {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
`

const Thumbnails = styled.ul`
  display: flex;
  align-items: center;
  min-width: 88px;
  height: 100%;

  li {
    margin: 0 7px;
  }
`
