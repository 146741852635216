// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import IconClose from '~/components/iconButtons/Close'
import typeJson from '~/assets/json/foottype.json'
import webgl from '~/webgl'

type Props = {
  handleClose: () => void,
  dataLayerPush: (param: { pageID: string }) => void,
  type:
    | 'EGYPTIAN_TOE'
    | 'GREEK_TOE'
    | 'ROMAN_TOE'
    | 'GERMANIC_TOE'
    | 'CELTIC_TOE'
}

class FootTypeModal extends Component<Props> {
  componentDidMount() {
    this.props.dataLayerPush({ pageID: `modal-foot-type/${this.props.type}` })
    webgl.pause()
  }

  componentWillUnmount() {
    webgl.resume()
  }

  render() {
    const { handleClose, type } = this.props
    const { name, text } = typeJson[type.replace('Foot','')]

    return (
      <Root>
        <CloseButton onClick={handleClose} />
        <Title>足のタイプと特徴</Title>
        <Contents>
          <Heading>{name}</Heading>
          <TextGroup>
            <Text dangerouslySetInnerHTML={{ __html: text }} />
            <Illust>
              <img src={`/img/footType/${type.replace('Foot','')}.png`} width={261} />
            </Illust>
          </TextGroup>
        </Contents>
      </Root>
    )
  }
}

export default FootTypeModal

const Root = styled.div`
  width: 750px;
  height: 550px;
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px;
`

const CloseButton = styled(IconClose)`
  position: absolute;
  top: 12px;
  right: 12px;
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.font.bold};
  font-size: 12px;
  text-align: center;
  margin-bottom: 50px;
`

const Heading = styled.div`
  font-size: 18px;
  line-height: 2;
  margin-bottom: 25px;
`

const Contents = styled.div`
  padding: 0 25px;
  letter-spacing: 2px;
`

const TextGroup = styled.div`
  display: flex;
`

const Text = styled.div`
  width: calc(100% - 246px);
  p {
    margin-bottom: 1em;
  }
`

const Illust = styled.div`
  width: 246px;
  transform: translateY(-20px);
`
