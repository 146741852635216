import glConf from './glConf';
import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import glType from './glType';
import glParam from './glParam';
import makingItem from './makingItem';
import makingSceneBase from './makingSceneBase';

export default class makingSceneB extends makingSceneBase {

  constructor(opt) {

    super(opt)

    this._num = undefined
    this._circleNum = undefined

  }


  init() {

    super.init();

    this._num = 15;
    this._circleNum = 2;

    this._showInterval = 0.01;

    for(let i = 0; i < this._num * this._circleNum; i++) {
      this._addItem(i);
    }
    this._getLastItem().setLast();

    this.resize();

  }


  _startBefore() {

    super._startBefore();

    for(let i = 0; i < this._items.length; i++) {
      this._items[i].setDepthTest(false);
    }

  }


  update() {

    if(!glParam.isRender[glType.SCENE.MAKING] || !this.isUpdate) {
      return;
    }

    super.update();

    let radius = this.sh * 0.35;
    let key = 0;
    let offset = this._cnt * 0.3;

    for(let i = 0; i < this._circleNum; i++) {

      for(let l = 0; l < this._num; l++) {

        const item = this._getItem(key++);

        const r = 0.4;
        const center = new THREE.Vector2(
          [-this.sw * r, this.sw * r][i],
          [this.sh * r, -this.sh * r][i]
        );

        const radian = glUtils.radian(offset + l * (360 / this._num));
        // if(i % 2 != 0) {
        //   radian *= -1;
        // }
        // radian *= -1;
        item.position.set(
          center.x + Math.sin(radian) * radius,
          center.y + Math.cos(radian) * radius,
          0
        );
        item.setSize(90);

      }
      offset += 45;
    }

  }


  resize() {

    super.resize();

  }


}
