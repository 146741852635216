// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import ArrowButton from './iconButtons/Arrow'
import Button from './buttons/ButtonA'
import AccountPr from './AccountPr'
import KarteTitle from './karte/Title'
import KarteButton from './karte/Button'
import KarteData from './karte/DataList'
import RecommendButton from './karte/RecommendButton'
import FootTypeModal from './FootTypeModal'
import LogoPng from '~/assets/img/flicfit-logo.png'
import Modal from './Modal'
import KarteCanvas from './KarteCanvas'
import webgl from '~/webgl'
import { formatSize } from '~/helpers/number'
import { FootMeasurement } from '../types/'
import type {RouterHistory} from 'react-router-dom';

type Props = {
  appActions: Object,
  recommendActions: Object,
  webglActions: Object,
  plyFileUrl: ?string,
  choicesAndKarteQrCodeUrl: ?string,
  left: ?FootMeasurement,
  right: ?FootMeasurement,
  jis: {
    jisSize: ?string,
    jisWidth: ?string
  },
  heelWidthJa: ?Object, 
  instepHeightJa: ?Object,
  jisWidthDisplay: ?boolean,
  karteDataLevel: ?string,
  introAnimationed: ?boolean,
  footUuid: ?string,
  history: RouterHistory
}

type State = {
  modal: boolean,
  loadData: boolean
}

class Karte extends Component<Props, State> {
  state = {
    modal: false,
    loadData: false
  }

  handleLogout = () => {
    this.props.history.push('/')
    //this.props.appActions.refresh()
  }

  handleToggleModal = (bool: boolean) => {
    this.setState({ modal: bool })
  }
  async componentDidMount() {

    //　データがない場合は取得する
    if (!this.props.footUuid ){
      const { appActions, recommendActions, footUuid, webglActions } = this.props
      appActions.showLoading()

      // LSからFootUuidのデータを生成するのです....
      const inputDataJson  = localStorage.getItem('input') || '{}'
      const inputData = JSON.parse(inputDataJson)
      const digitizerCode = inputData['digitizerCode']
      const footId = inputData['footId']
      this.props.appActions.setInput( inputData)

      // initデータ取得
      const { clientCode,shoeImages, tags, target, jisWidthDisplay, karteDataLevel } = await recommendActions.fetchInitialize(digitizerCode)
      appActions.setStoreInfo({clientCode, jisWidthDisplay, karteDataLevel })

      // footUudiの作成
      const now = new Date();
      const today = now.getFullYear()+ ( "0"+( now.getMonth()+1 ) ).slice(-2)+ ( "0"+now.getDate() ).slice(-2)
      // PROD
      const fuid = `${clientCode}-${digitizerCode}-${today}-${footId}`
      // DEBUG
      //const fuid = "81099990010004-000050-20200325-0003"

      // measturementデータの取得
      appActions.setFootUuid(fuid)
      const { left, right, file } = await appActions.getMeasurement(fuid)
      //webglActions.registerPlyFileUrl(threeUrl)

      // JISデータを取得
      await appActions.getJisSize(fuid)
    }

    this.setState({loadData : true})

    // webglの表示
    webgl.showShoes({
      el: document.getElementById('canvas-karte'),
      plyFileUrl: this.props.plyFileUrl
    })
    
    this.props.appActions.dataLayerPush({ pageID: 'karte' })
  }

  componentWillUnmount() {
    // webglを消去
    webgl.hideShoes()
  }

  componentDidUpdate() {
    if (this.props.introAnimationed) {
      webgl.showShoes({
        el: document.getElementById('canvas-karte'),
        plyFileUrl: this.props.plyFileUrl
      })
    }
  }

  render() {
    const {
      appActions,
      choicesAndKarteQrCodeUrl,
      left,
      right,
      jis,
      instepHeightJa,
      heelWidthJa,
      jisWidthDisplay,
      karteDataLevel,
      introAnimationed,
      footUuid
    } = this.props
    console.log(this.props.plyFileUrl)

    return (
      <Root>
        <KarteCanvas />
      {this.state.loadData && (
        <div style={{ position: 'relative' }}>
          <QRCode value={choicesAndKarteQrCodeUrl} />
          <Logo src={LogoPng} width={117} />
          <Title jis={jis} jisWidthDisplay={jisWidthDisplay} />
          <ModalButton type={0} onClick={() => this.handleToggleModal(true)} />
          <KarteDataBlock 
            left={left} 
            right={right} 
            karteDataLevel={karteDataLevel} 
            heelWidthJa={heelWidthJa}
            instepHeightJa={instepHeightJa}
            />
          <OsusumeButton
            dir="previous"
            onClick={() => {
              // Recommendに遷移
              this.props.history.goBack()
            }}
          />
          <FootUuid>{footUuid}</FootUuid>
          <TopButton onClick={this.handleLogout}>TOPに戻る</TopButton>
          {this.state.modal && right  && (
            <Modal
              handleOutsideClick={() => this.handleToggleModal(false)}
              render={() => (
                <FootTypeModal
                  handleClose={() => this.handleToggleModal(false)}
                  type={right.footMeasurement.toeShapeType.replace('ToeShapeType.', '')}
                  dataLayerPush={appActions.dataLayerPush}
                />
              )}
            />
          )}
        </div>
      )}
      </Root>
    )
  }
}

export default Karte

const Root = styled.div`
  background-color: #f3f3f3;
  height: 100vh;
`

const QRCode = styled(AccountPr)`
  position: fixed;
  right: 0;
  top: 100px;
  pointer-events: none;
`

const BackButton = styled(ArrowButton)`
  position: fixed;
  top: 15px;
  left: 15px;
`

const TopButton = styled(Button)`
  position: fixed;
  bottom: 37px;
  left: 15px;
`

const Logo = styled.img`
  position: fixed;
  top: 24px;
  right: 24px;
  pointer-events: none;
`

const Title = styled(KarteTitle)`
  position: fixed;
  top: 78px;
  left: 50%;
  transform: translate(-50%, 0);
  pointer-events: none;
`

const ModalButton = styled(KarteButton)`
  position: fixed;
  top: 205px;
  left: 116px;
`

const KarteDataBlock = styled(KarteData)`
  position: fixed;
  bottom: 30px;
  right: 30px;
`

const OsusumeButton = styled(RecommendButton)`
  position: fixed;
  bottom: 37px;
  left: 378px;
`
const FootUuid = styled.div`
  position: fixed;
  bottom: 0;
  right: 10px;
  color: #999;
`