// @flow
import React from 'react'
import ColorButton from '../buttons/ButtonE'

type Props = {
  colorId: number,
  onSelect?: () => void,
  onRemove?: () => void
}

const ColorList: React$StatelessFunctionalComponent<Props> = React.memo(
  ({ colorId, onSelect, onRemove }) => (
    <li>
      {!!onSelect && <ColorButton colorId={colorId} onClick={onSelect} />}
      {!!onRemove && (
        <ColorButton colorId={colorId} selected onClick={onRemove} />
      )}
    </li>
  )
)

export default ColorList
