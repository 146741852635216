import glConf from './glConf';
import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import glType from './glType';
import glParam from './glParam';
import makingItem from './makingItem';
import makingSceneBase from './makingSceneBase';

export default class makingSceneA extends makingSceneBase {

  constructor(opt) {

    super(opt)

    this._num = undefined
    this._circleNum = undefined

  }


  init() {

    super.init();

    this._num = 11;
    this._circleNum = 4;

    this._showInterval = 0.01;

    for(let i = 0; i < this._num * this._circleNum; i++) {
      this._addItem(i);
    }
    this._getLastItem().setLast();

    this.resize();

  }


  update() {

    if(!glParam.isRender[glType.SCENE.MAKING] || !this.isUpdate) {
      return;
    }

    super.update();

    let radius = this.sh * 0.266666667;
    let key = 0;
    let offset = this._cnt * 0.3;

    for(let i = 0; i < this._circleNum; i++) {
      for(let l = 0; l < this._num; l++) {
        const item = this._getItem(key++);
        let radian = glUtils.radian(offset + l * (360 / this._num));
        if(i % 2 == 0) {
          radian *= -1;
        }
        item.position.set(
          Math.sin(radian) * radius,
          Math.cos(radian) * radius,
          0
        );
        item.setSize(glUtils.map(i, 60, 100, 0, this._circleNum - 1));
      }
      offset += 45;
      radius += this.sh * 0.15;
    }

  }


  resize() {

    super.resize();

  }


}
