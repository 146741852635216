import React, { Fragment } from 'react'
import styled from 'styled-components'

type Props = {
  size: Array,
  favorites: Array<number>,
  item: {
    brandName: string,
    productNumber: string,
    makerSize: string,
    makerWidth: string,
    productName: string,
    makerColor: string,
    makerMaterial: string,
    price: number,
    uni: string
  },
  handleSelectSizeTag: (uuid: number,sameid: number ) => () => void,
}

function Sizelist({size, favorites, item, handleSelectSizeTag, ...rest }: Props) {
    console.log(item)
  
  return (
    <Root {...rest}>
      {size.map(( v, idx) => {
          return (
            <Item
              key={idx}
              onClick={ handleSelectSizeTag(v.itemId)}
              selected={favorites.find(e => e == v.itemId)? true: false}
            >
              {v.label}
            </Item>
          )
      })}
    </Root>
  )
}

export default Sizelist

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
`

const Item = styled.div`
  line-height: 24px;
  background: ${({ theme, selected }) => (selected ? theme.color.yellow : '#fff')};
  padding: 5px 10px;
  border: ${({ theme, selected }) => (selected ? 'none' : '1px solid #ccc')};
  color: #000;
  border-radius: 17px;
  margin: 5px;
  width: 80px;
  text-align: center;
`