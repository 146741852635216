// @flow

// $FlowFixMe
const { pollingSettings } = process.env.CONFIG

function pollingMeasurement(
  asyncFunc: () => Object,
  dispatchFunc:(status:string) => Object,
  timeout: number = pollingSettings.timeout,
  interval: number = pollingSettings.interval
): Promise<any> {


  const endTime = Date.now() + timeout
  const checkCondition = async (resolve, reject) => {
    const response = await asyncFunc()
    if (response.data.statusCode === 200) {
      if ( response.data.data === void 0 || response.data.data.status === void 0 ) {
        return resolve(response)
      }
      if (response.data.data.status == 'result_uploaded' ) {
        return resolve(response)
      } 

      if (response.data.data.status == 'error' ) {
        dispatchFunc(response.data.data.status )
        return reject(new Error(response.data.data.errorDetail))
      }

      if (response.data.data.status == 'scanned' ) {
        dispatchFunc(response.data.data.status )
      } 

      setTimeout(checkCondition, interval, resolve, reject)
    } else if (response.data.statusCode < 500 && Date.now() < endTime) {
      setTimeout(checkCondition, interval, resolve, reject)
    } else {
      reject(new Error('ネットワークエラーが発生しました。'))
    }
  }
  return new Promise(checkCondition)
}

export default pollingMeasurement