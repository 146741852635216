// @flow
import React from 'react'
import styled from 'styled-components'
import QRCode from 'qrcode.react'

import base from './img/base.png'

type Props = {
  value: string
}

function AccountPr({ value, ...rest }: Props) {
  return (
    <Root {...rest}>
      <Wrapper>
        <QRCode value={value} size={70} />
      </Wrapper>
      <img src={base} width={160} alt="" />
    </Root>
  )
}

export default AccountPr

const Root = styled.div`
  width: 160px;
  height: 225px;
  position: relative;
`

const Wrapper = styled.div`
  position: absolute;
  background: #fff;
  padding: 6px;
  border-radius: 8px;
  top: 78px;
  left: 37px;
  > canvas {
    vertical-align: middle;
  }
`
