import glConf from './glConf';
import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import glType from './glType';
import glParam from './glParam';
import makingItem from './makingItem';
import makingSceneBase from './makingSceneBase';

export default class makingSceneH extends makingSceneBase {

  constructor(opt) {

    super(opt)

    this._num = undefined
    this._lineNum = undefined
    this._offset = undefined

  }


  init() {

    super.init();

    this._num = 20;
    this._lineNum = 2;
    this._offset = 0;

    for(let i = 0; i < this._num * this._lineNum; i++) {
      this._addItem(i);
    }
    this._getLastItem().setLast();

    this.resize();

  }


  _startBefore() {

    super._startBefore();

    for(let i = 0; i < this._items.length; i++) {
      this._items[i].setDepthTest(false);
    }

  }


  update() {

    if(!glParam.isRender[glType.SCENE.MAKING] || !this.isUpdate) {
      return;
    }

    super.update();

    let radius = this.sw * 0.2;
    const num = this._items.length;
    for(let i = 0; i < num; i++) {

      const line = ~~(i / this._num);
      const key = i % this._num;

      const item = this._items[i];
      const radian = glUtils.radian(this._offset + 30 * key + line * 180);

      item.position.x = this._offset + -this.sw + (key * this.sw * 0.1);
      item.position.y = Math.sin(radian) * radius;

      item.setSize(80);
    }

    this._offset += 1;

  }


  resize() {

    super.resize();


  }


}
