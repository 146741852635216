// @flow
import React, { Component } from 'react'
import { connect,  useDispatch , useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Route,  Redirect } from 'react-router-dom'
import { actions as authActions, isAuthSelector  }  from '~/redux/modules/auth';
import authState from '~/redux/modules/auth';


/*
type Props = {
  token: string,
  authActions: Object,
  component: Component
}
*/
type Props = {
  auth: Object,
  authActions:  Object
}

type State = {
  isAuth: boolean
}

class PrivateRoute extends Component<Props, State> {

  state = {
    isAuth: false

  };


  static  getDerivedStateFromProps(props) {
    console.log("getDerivedStateFromProps", props.auth)
    if (props.auth.token ) { 

      return {isAuth: true}
    } 
    props.authActions.setRedirect()
    return {isAuth :false }
  }

  render() {
    console.log("render                ")
    let { auth,authActions , ...rest} = this.props
    return this.state.isAuth?   <Route {...rest} />: <Redirect to="/login" />

  }
}


const mapStateToProps = (state,props) => ({
  auth: state.auth
})

const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators({ ...authActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute)




/*
const PrivateRoute =  (props: Object) => {
  let isAuth = useSelector(isAuthSelector);
  if ( isAuth) return (<Route {...props} />)

  if( !localStorage.getItem('flicfitAuthToken')) return (<Redirect to="/login" />)
  return checkAuth(props)
}
const checkAuth  =  async (props: Object) => {
  const dispatch = useDispatch();
  const check = () => dispatch( authActions.checkLsToken() )
  const { token } = await check()
  console.log("aaaa",  token )
  return <Route {...props} />
}
*/

