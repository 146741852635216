// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import Button from './buttons/ButtonA'
import logoPng from '../assets/img/flicfit-logo.png'
import titlePng from './img/try-list/title.png'
import { hot } from 'react-hot-loader'
import { Review, ReviewQnA } from '../types'
import ArrowButton from './iconButtons/Arrow'
import type {RouterHistory, Match} from 'react-router-dom'
import FootPartButtons from './input/FootPartButtons'

const questionId = 'fitting_log_shoe_4'
type Props = {
  appActions: Object,
  recommendActions: Object,
  review: ?Review,
  item: Object,
  history:RouterHistory,
  match: Match
} 

type State = {
  qna: ?ReviewQnA
}

class ReviewTightpart extends Component<Props, State> {
  state = {
    qna:  this.props.review?  this.props.review.content.shoeReview.find( e =>  e.questionId == questionId): null
  }

  handleSelectPart =  (value: string)  => () => {
    // エラー（
    if (this.state.qna == null ) return

    let tmpQna = {...this.state.qna}
    if ( 'answer' in this.state.qna) {
      if (tmpQna.answer.includes(value)) {
        tmpQna.answer = tmpQna.answer.filter( v => v !== value)
      } else {
        tmpQna.answer.push(value)
      }
    } else {
      tmpQna['answer'] = [value]
    }
    this.setState({ qna : tmpQna})
  }

  handleBack =  ()  => () => {
    console.log("キツイ handleBack")
    const { recommendActions, review,history} = this.props
    const itemId = review && review.itemId ?  
          review.itemId : ( review && review.unknownShoeName ? review.unknownShoeName : '')
    history.push(`/review/impressions/${itemId}`)
  }

  handleNext = () => () => {
    console.log("キツイ handleNext")
    // エラー（
    if (this.state.qna == null ) return

    const { recommendActions, review,history} = this.props
    const itemId = review && review.itemId ?  
          review.itemId : ( review && review.unknownShoeName ? review.unknownShoeName : '')

    let tmpReview = {...review}
    let idx = tmpReview.content.shoeReview.findIndex(e => e.questionId == questionId)

    if ( this.state.qna && 'answer' in this.state.qna ) {
      tmpReview.content.shoeReview[idx]['answer'] = this.state.qna.answer 
      recommendActions.onUpdateReview(tmpReview)
    }
    history.push(`/review/impressions/${itemId}`)
  }
  
  componentDidMount() {
    const { match: {params}} = this.props
    const itemId = params.itemId

    // TODO itemIdは必須処理が
    if(!itemId) return

  }

  render() {
    const { appActions, review, item} = this.props
    let unknownShoe = null
    if (review && review.unknownShoeName) {
      unknownShoe = review.unknownShoeName.split(',');
    }
    return (
      <Root>
        <Top>
          <Title>
             きついと感じた部位を教えてください。
          </Title>
          <Products>
            <FootPartButtons 
              values={this.state.qna}
              handleSelect={this.handleSelectPart}
              handleBack={this.handleBack}
              width={''}
            />
          </Products>
          <Thumbnail>
            {item && item.pictureUri[0] && (
            <img src={item.pictureUri[0]} alt="" />
            )}
          </Thumbnail>
          { item && (
          <Description>
            <dl>
               <dt>ブランド</dt>
               <dd>{item.brandName}</dd>
               <dt>品番</dt>
               <dd>{item.productNumber}</dd>
               <dt>サイズ</dt>
               <dd>{`${item.makerSize}${item.unit}`}</dd>
               <dt>色</dt>
               <dd>{item.makerColor}</dd>
            </dl>
          </Description>
          )}
          { unknownShoe && (
          <Description>
            <dl>
               <dt>ブランド</dt>
               <dd>{unknownShoe[0]}</dd>
               <dt>品番</dt>
               <dd>{unknownShoe[2]}</dd>
               <dt>サイズ</dt>
               <dd>{`${unknownShoe[1]}`}</dd>
               <dt>色</dt>
               <dd>{unknownShoe[3]}</dd>
            </dl>
          </Description>
          )}
        </Top>
        {/* 
        <BackButton dir="previous" onClick={this.handleBack() } />
        */}
        <BackButton dir="previous" onClick={this.handleBack() }>
          戻る
        </BackButton>
        <NextButton dir="previous" onClick={this.handleNext() }>
        入力完了
        </NextButton>
      </Root>
    )
  }
}

export default hot(module)(ReviewTightpart)

const Root = styled.div`
  position: relative;
  top: 0;
  left: 0;
  background-color: #f3f3f3;
  color: #707070;
  width: 100vw;
  height: 100vh;
`

const NextButton = styled(Button)`
  position: fixed;
  bottom: 15px;
  right: 25px;
`


const BackButton = styled(Button)`
  position: fixed;
  bottom: 15px;
  left: 15px;
`

const TopButton = styled(Button)`
  position: fixed;
  bottom: 37px;
  left: 15px;
`

const Top = styled.div`
`

const Title = styled.div`
  padding: 25px 0 20px 0;
  text-align: center;
  font-size: 30px;
`

const Products = styled.div`
  margin-left: 80px;
  > dl > dt {
    font-size: 18px;
    margin: 20px 0 5px;
    font-weight: bold;
  }
  > dl > dd {
    font-weight: normal;
  }
`
const Thumbnail = styled.figure`
  position: absolute;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 0 5px;
  overflow: hidden;
  bottom: 400px;
  right: 100px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

const Description = styled.div`
  margin-left: 100px;
  position: absolute;
  bottom: 300px;
  right: 100px;
  margin: auto 0 auto 100px;
  font-size: 14px;
  font-weight: bold;
  > dl > dt {
	  float: left;
	  clear: left;
	  margin-right: 0.5em;
	  width: 60px;
  }
  > dl > dd {
	    float: left;
	    margin-left: 1em;
  }  
`