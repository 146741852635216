import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import glConf from './glConf';


export default class readyDotItem extends object3d {

  constructor(opt) {

    super(opt)

    this._dot = undefined
    this._ring = undefined
    this._isShow = undefined
    this._isChange = undefined

    this.angle = 0

  }


  init() {

    super.init();

    this.opt.bufAngle = 0;

    this._isShow = false;
    this._isChange = false;

    this._dot = new THREE.Mesh(
      new THREE.CircleBufferGeometry(0.5, 32),
      new THREE.MeshBasicMaterial({color:this.opt.color})
    );
    this.add(this._dot);
    this._dot.visible = false;

    this._ring = new THREE.Mesh(
      new THREE.TorusBufferGeometry(0.4, 0.181818182 * 0.5, 16, 100),
      new THREE.MeshBasicMaterial({color:this.opt.color})
    );
    this.add(this._ring);
    this._ring.visible = false;

    this.resize();

  }


  show(opt) {

    if(this._isShow) {
      return
    }

    if(opt.nowAngle >= this.angle) {
      this._isShow = true;
      this._dot.visible = true;
      this._dot.scale.set(glConf.MIN_SCALE, glConf.MIN_SCALE, 1)
      TweenMax.to(this._dot.scale, 1.5, {
        x:1,
        y:1,
        ease:Elastic.easeOut.config(1, 0.2),
        delay:0.5
      })
    }
  }


  change(opt) {

    if(this._isChange && this.opt.bufAngle > opt.nowAngle) {
      this._isChange = false;
    }
    this.opt.bufAngle = opt.nowAngle;

    if(this._isChange) {
      return
    }

    if(opt.nowAngle >= this.angle) {

      this._isChange = true;

      let a = this._dot;
      let b = this._ring;
      if(!a.visible) {
        a = this._ring;
        b = this._dot;
      }

      b.scale.set(glConf.MIN_SCALE, glConf.MIN_SCALE, 1);
      TweenMax.to(b.scale, 1.5, {
        x:1,
        y:1,
        ease:Elastic.easeOut.config(1, 0.2),
        delay:0,
        onStart:() => {
          a.visible = false;
          b.visible = true;
        }
      })
    }

  }


  startComplete(opt) {

    TweenMax.killTweensOf(this._dot.scale);
    TweenMax.killTweensOf(this._ring.scale);

    this._dot.visible = false;
    this._ring.visible = true;
    this._ring.scale.set(1, 1, 1);

    // this._ring.scale.set(glConf.MIN_SCALE, glConf.MIN_SCALE, 1);
    // TweenMax.to(this._ring.scale, 1.5, {
    //   x:1,
    //   y:1,
    //   ease:Elastic.easeOut.config(1, 0.2),
    //   delay:opt.delay,
    //   onStart:() => {
    //     this._ring.visible = true;
    //     // this._dot.visible = false;
    //     // this._ring.scale.set(glConf.MIN_SCALE, glConf.MIN_SCALE, 1);
    //   }
    // })

  }


  startNext(opt) {

    const duration = 2;

    TweenMax.to(this.position, duration, {
      x:0,
      y:0,
      delay:opt.delay,
      ease:Power4.easeInOut
    });

    TweenMax.to(this.scale, duration, {
      x:glConf.MIN_SCALE,
      y:glConf.MIN_SCALE,
      delay:opt.delay,
      ease:Power4.easeInOut,
      onComplete:() => {
        this.visible = false;
        if(opt.onComplete != null) {
          opt.onComplete()
        }
      }
    })

  }


  update() {

    super.update();

  }


  resize() {

    super.resize();

    const size = 22;
    this.scale.set(size, size, 1);

  }





}
