// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import Button from './buttons/ButtonA'
import logoPng from '../assets/img/flicfit-logo.png'
import titlePng from './img/try-list/title.png'
import { hot } from 'react-hot-loader'
import { ReviewQnA } from '../types'
import ArrowButton from './iconButtons/Arrow'
import type {RouterHistory, Match} from 'react-router-dom'
import SelectButtons from './input/SelectButtons'

// $FlowFixMe, 
const { footSurveyTags } = process.env.CONFIG

type Props = {
  appActions: Object,
  resultOfSurvey: Array<ReviewQnA>,
  history:RouterHistory,
  match: Match
} 

type State = {
  footSurveyresult: Array<ReviewQnA>, 
  errors: Array<String>, 
}

class FootSurvey extends Component<Props, State> {
  state = {
    footSurveyresult:  this.props.resultOfSurvey,
    errors: []
  }

  handleSelect =  (questionId: string, value: string)  => () => {
    let tmpSurvey =  [...this.state.footSurveyresult]
    const idx =  tmpSurvey.findIndex((e) => e.questionId == questionId)

    // エラー
    if ( idx == -1) return;

    let tmpResult =   tmpSurvey[idx]
    if ( !tmpResult.answer) {
      // ADD
      if ( tmpResult.type == 'multi_select' ) { 
        tmpResult['answer'] =  [value]
      } else {
        tmpResult['answer'] =  value
      }
    }  else {
      if ( tmpResult.type == 'multi_select' ) { 
        if ( tmpResult.answer.includes(value) ) {
          // 削除
          tmpResult.answer = tmpResult.answer.filter(e => e !== value )
        } else{
          tmpResult.answer.push(value)
        } 
      } else {
          tmpResult.answer = value
      }
    }
    tmpSurvey[idx] = tmpResult 
    this.setState({footSurveyresult: tmpSurvey })

  }
  
  handleBack =  ()  => () => {
    this.props.history.push('/trylist')
  }
  handleNext = () => () => {
    const { match: {params}, history} = this.props
    //this.props.appActions.
    // required　チェック
    const errors = this.state.footSurveyresult.reduce((accumulator, e) => {
      if (e.required &&  !e.answer) {
        accumulator.push(e.questionId);
      }
      return accumulator;
       }, []);
    this.setState({errors: errors})

    if ( errors.length == 0 ) {
      history.push(`/trylist`)
    }

  }

  componentDidMount() {
    this.props.appActions.dataLayerPush({ pageID: 'survey' })
  }

  render() {
    const { appActions, resultOfSurvey } = this.props
    return (
      <Root>
        <Top>
          <Title>
            お客さまについて教えてください
          </Title>
          <Products>
            {footSurveyTags.map((e, i) => (
            <dl key={i}>
              <Qtitle error={this.state.errors.includes(e.questionId)}>{e.title}</Qtitle>
              <dd>
                <SelectButtons 
                  questionId={e.questionId}
                  qna={e.options}
                  values={this.state.footSurveyresult.find( v => v.questionId == e.questionId)}
                  handleSelect={this.handleSelect}
                />
              </dd>
            </dl>
            ))}
          </Products>
        </Top>
        <NextButton onClick={this.handleNext()} color="yellow">完了</NextButton>
        <BackButton dir="previous" onClick={this.handleBack() } color="grey2">戻る</BackButton>


          {/*
        {this.state.selectedProductId !== null && (
          <Modal
            handleOutsideClick={this.handleModalClose}
            render={() => (
              <ProductModal
                onClose={this.handleModalClose}
                handleSelectTag={(
                  selectedTag: string,
                  selectedLabel: string
                ) => {
                  this.handleSelectTag(selectedTag, selectedLabel)
                }}
                handleSelectSizeTag={this.handlefavoriteItems}
                onTry={this.handleTryList}
                item={itemEntities[this.state.selectedProductId]}
                dataLayerPush={appActions.dataLayerPush}
                favorites={favorites}
              />
            )}
          />
        )}
           */}
      </Root>
    )
  }
}

export default hot(module)(FootSurvey)

const Root = styled.div`
  top: 0;
  left: 0;
  background-color: #f3f3f3;
  color: #707070;
  width: 100vw;
  height: 100vh;
`

const NextButton = styled(Button)`
  position: fixed;
  bottom: 10px;
  right: 15px;
`


const BackButton = styled(Button)`
  position: fixed;
  bottom: 10px;
  left: 15px;
`

const TopButton = styled(Button)`
  position: fixed;
  bottom: 37px;
  left: 15px;
`

const Top = styled.div`
`

const Title = styled.div`
  padding: 25px 0 20px 0;
  text-align: center;
  font-size: 30px;
`

const Products = styled.div`
  margin-left: 80px;
  > dl > dd {
    font-weight: normal;
  }
`
const Qtitle = styled.dt`
    font-size: 18px;
    margin: 20px 0 5px;
    font-weight: bold;
    color: ${({ error }) => (error ? '#f00' : '')};
`
