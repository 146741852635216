import React, { Fragment } from 'react'
import styled from 'styled-components'
import FootImg from './img/review_foot.png'

type Props = {
  tight:  any,
  blister: any
}

function ReviewPart ({ tight, blister, ...rest }: Props) {
  const tightAnswer = tight.answer
  const blisterAnswer = blister.answer
  const tightColor =  "#ff0000"
  const blisterColor = '#0045FF'

  let parts =  []
  tight.options.forEach( ( element, idx ) => {
  let top = '', left = '', right='', bottom='', whole = true, color= '#808080'
  if (element == 'なし') return
  switch (element) {
    case '先端' :
      top = '10%'
      left = '0'
      break
    case '小指/外側' :
      left = '60px'
      bottom='-40px'
      break
    case '親指付け根' :
      top = '-40px'
      left = '60px'
      break
    case '履き口' :
      top = '15%'
      left = '30%'
      break
    case '甲外側' :
      right = '27%'
      bottom = '-40px'
      break
    case '土踏まず' :
      top = '-30px'
      right = '27%'
      break
    case '外くるぶし' :
      bottom = '-40px'
      right = '10px'
      break
    case '内くるぶし' :
      top = '-45px'
      right = '10px'
      break
    case 'かかと' :
      top = '15%'
      right = '0'
      break
    default :
      break
  }

  const t =  Array.isArray(tightAnswer)? tightAnswer.find(v => v == element) : element == tightAnswer ;  
  const b =  Array.isArray(blisterAnswer)? blisterAnswer.find(v => v == element) : element == blisterAnswer ;  

  if ( t && b ) {
    whole = false
  } else if ( t && !b ) {
    color=  tightColor
  } else if ( !t && b ) {
    color = blisterColor
  }
  parts.push( { label: element, top: top, left: left, right: right, bottom: bottom,color: color, whole: whole } )
  })

  return (
       <Root>
        <Header>
          <Title color={tightColor}>痛い場所</Title>
          <Title color={blisterColor}>靴ずれしそうな場所</Title>
        </Header>
        <Foot>
          <img src={FootImg} alt="" />
          {parts.map( ( element, idx ) => (
          element.whole ?
            <CircleWhole 
              key={idx}
              color={element.color}
              top={element.top}
              left={element.left}
              right={element.right}
              bottom={element.bottom}
            >{element.label}</CircleWhole>
          : 
            <PartBox 
              top={element.top}
              left={element.left}
              right={element.right}
              bottom={element.bottom}
              key={idx}
            >
            <CircleBox>
              <Circle color={tightColor} />
              <Circle2 color={blisterColor} />
              <PartName>{element.label}</PartName>
            </CircleBox>
            </PartBox>
          ))}
         </Foot>
       </Root>
  )
}
export default ReviewPart

const Root = styled.div`
  border: solid 1px #D9D9D9;
  border-radius: 20px;
  overflow: hidden;
  margin: 20px 10px;

`
const Header = styled.div`
  display: -webkit-flex;
  display: flex;
`
const Title = styled.div`
  background-color:${({ color  }) => color? color : ''}  
  opacity: 60%;
  height: 50px;
  width:50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
`

const Foot = styled.div`
  position: relative;
  margin: 50px 5px;
  > img {
    width: 100%;
    height: auto;
  }
`
const Circle = styled.div`
width:30px;
height:60px;
border-radius: 30px 0 0 30px;
background:${({ color  }) => color? color : ''};
position: relative;
opacity: 60%;
`

const Circle2 = styled.div`
width:30px;
height:60px;
border-radius: 0 30px 30px 0;
background:${({ color  }) => color? color : ''};
position: absolute;
top: 0px;
left: 30px;
opacity: 60%;
`

const CircleBox = styled.div`
  width:60px;
  height:60px;
`
const PartName = styled.p`
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  text-align: center;/*左右中央*/
  display: flex;
  align-items: center; /* 縦方向中央揃え */
  justify-content: center; /* 横方向中央揃え */
  color : #FFF;
`
const PartBox = styled.div` 
  position: absolute;
  top:${({ top }) => top? top: ''};
  left:${({ left }) => left? left: ''};
  right:${({ right }) => right? right: ''};
  bottom:${({ bottom }) => bottom? bottom : ''};
  height: 60px;
  backgroud-color: #ff0;
  
`

const CircleWhole = styled.div` 
  display: inline-block;
  text-align: center;/*左右中央*/
  width: 60px;
  height: 60px;
  line-height: 60px;/*heightと同じ値に*/
  border-radius: 50%;/*角を丸く*/
  background: ${({ color  }) => color? color : ''};
  position: absolute;
  top:${({ top }) => top? top: ''};
  left:${({ left }) => left? left: ''};
  right:${({ right }) => right? right: ''};
  bottom:${({ bottom }) => bottom? bottom : ''};
  color : #FFF;
  opacity: 60%;
  `