// @flow
import React, { Component } from 'react'
import InputConfiguration from '~/components/input/Configuration'
import styled from 'styled-components'
import type {RouterHistory} from 'react-router-dom';

type Props = {
  appActions: Object,
  digitizerCode: string,
  history:RouterHistory,
  fetchInitialize: (digitizerCode: string) => Promise<any>
}

type State = {
  digitizerCode: string,
  error: string
}

export default class Digitizer extends Component<Props, State> {
  state = {
    digitizerCode: '',
    error: ''
  }

  async componentDidMount() {
    const inputDataJson  = localStorage.getItem('input') || '{}'
    const inputData = JSON.parse(inputDataJson)



    this.setState( {digitizerCode: inputData.digitizerCode} )

    // ローディング停止
    this.props.appActions.hideLoading()

    //this.props.appActions.dataLayerPush({ pageID: 'home' })
  }

  changeText = (e: any) => {
    this.setState({digitizerCode: e.target.value});
  }

  nextStep = () => {

    let code = this.state.digitizerCode
    // バリデーション
    if( code.search(/^[0-9]+$/)  == -1) {
      this.setState({error: '半角数字を入力してください。'});
      return
    }

    code = code.padStart(6, '0')

    const inputData = {
      staffName: null,
      footId: null,
      digitizerCode: code
    }

    this.props.appActions.setInput(inputData)
    localStorage.setItem('input', JSON.stringify(inputData))
    // dispacherでデータ保持
    //this.props.history.push('/recommned')
    this.props.history.push('/staff')

  }

  render() {
    return (
      <Root>
      <InputConfiguration
        title='デジタイザーコードを入力してください。' 
        fontColor='#fff'
        placeholder='タッチしてデジタイザーコードを入力'
        value={this.state.digitizerCode}
        changeText={this.changeText}
        nextStep={this.nextStep}
        error={this.state.error}
      />
      </Root>
    )
  }
}

const Root = styled.div`
  position: relative;
  background-color: #404040;
  width: 100vw;
  height: 100vh;
`

