import glConf from './glConf';
import glType from './glType';
import glResize from './glResize';
import glUpdate from './glUpdate';
import glParam from './glParam';
import glTextures from './glTextures';
import image from './image';
import shoes from './shoes';
import webgl from './index';


export default class shoesContents {

  constructor(opt) {

    this.camera = undefined
    this.renderer = undefined
    this.controls = undefined

    this._shoes = undefined
    this._bgColor = undefined
    this._renderCnt = undefined

    this._updateKey = undefined
    this._resizeKey = undefined

    this.opt = opt

    this.mouseupCnt = 0
    this.mouseupCntStart = false

    this._init()

  }


  _init() {

    this._renderCnt = 0;

    this.renderer = new THREE.WebGLRenderer({
      canvas             : this.opt.el,
      alpha              : false,
      antialias          : false,
      stencil            : false,
      depth              : true,
      premultipliedAlpha : false,
      powerPreference    : 'low-power'
    });
    this.renderer.autoClear = true;

    this.camera = new THREE.PerspectiveCamera(45, 1, 0.1, 50000);

    this.controls = new THREE.TrackballControls(this.camera);
    this.controls.enabled = false

    this.mainScene = new THREE.Scene();

    this.resize();

    this._shoes = new shoes({
      camera:this.camera
    });
    this._shoes.init();
    this.mainScene.add(this._shoes);

    this._updateKey = glUpdate.add(() => {
      this.update();
    })

    this._resizeKey = glResize.add(() => {
      this.resize();
    });

    // ダブルタップ
    this.opt.el.addEventListener('mouseup', this._eMouseUp)
  }


  useTrackball(bool) {

    this.controls.enabled = bool

  }


  _eMouseUp(e) {

    if(!webgl.shoes.mouseupCntStart) {
      // １回め
      webgl.shoes.mouseupCntStart = true
    } else {
      // ２回め
      if(webgl.shoes.mouseupCnt <= 18) {
        webgl.shoes.resetCamera()
      }
      webgl.shoes.mouseupCntStart = false
      webgl.shoes.mouseupCnt = 0
    }

  }


  resetCamera() {

    if(this.camera != null) {
      // this.camera.position.set(0, 0, 0)
      // this.camera.rotation.set(0, 0, 0)
      // this.camera.scale.set(1, 1, 1)
      // this.resize()
      this.controls.reset()
      this.resize()
    }

  }


  dispose() {

    if(this.opt.el != null) {
      this.opt.el.removeEventListener('mouseup', this._eMouseUp)
    }

    this._renderOff()

    this.mouseupCntStart = false

    glUpdate.remove(this._updateKey)
    glResize.remove(this._resizeKey)

    this.mainScene.remove(this._shoes)
    this._shoes.dispose()
    this._shoes = null

    this.controls.dispose()
    this.controls = null

    this.camera = null

    this.renderer.dispose()
    this.renderer = null

    this.opt = null

  }


  show (opt) {

    this.setScene(glType.SCENE.SHOES)
    this._shoes.load({
      data:opt.data,
      onComplete:() => {
        this.opt.el.style.display = ''
        this.controls.enabled = true
        if(opt.onComplete != null) {
          opt.onComplete()
        }
      }
    })

  }


  hide (opt) {

    this._renderOff()

    if(opt.onComplete != null) {
      opt.onComplete()
    }

  }



  setScene (scene) {

    this._shoes.visible = false;

    switch(scene) {

      case glType.SCENE.SHOES:
        this._bgColor = 0xf3f3f3;
        this._shoes.visible = true;
        break;
    }

    this._renderOff();
    glParam.isRender[scene] = true;

  }


  _renderOff() {

    glParam.isRender[glType.SCENE.INTRO] = false
    glParam.isRender[glType.SCENE.READY] = false
    glParam.isRender[glType.SCENE.MAKING] = false
    glParam.isRender[glType.SCENE.SHOES] = false
    glParam.isRender[glType.SCENE.ATTENTIONFOOT] = false
    glParam.isRender[glType.SCENE.ATTENTIONHEM] = false

    this.controls.enabled = false

    this.opt.el.style.display = 'none'

  }


  update() {

    if(!glParam.isRender[glType.SCENE.SHOES]) {
      return;
    }

    if(this.mouseupCntStart) {
      this.mouseupCnt++
    }

    this._render();

  }


  _render() {

    if(glParam.isPause || this.opt == null) {
      return
    }

    this.renderer.setClearColor(this._bgColor, 1);

    if(this.controls != null) {
      this.controls.update();
    }

    if(this._renderCnt % 2 == 0) {
      this.renderer.render(this.mainScene, this.camera);
    }

    this._renderCnt++;

  }


  resize() {

    const w = window.innerWidth;
    const h = window.innerHeight;

    this.camera.aspect = w / h;
    this.camera.updateProjectionMatrix();
    this.camera.position.z = h / Math.tan(this.camera.fov * Math.PI / 360) / 2;

    this.renderer.setPixelRatio(window.devicePixelRatio || 1);
    this.renderer.setSize(w, h, true);

    if(this.controls != null) {
      this.controls.handleResize();
    }

    this._render();

  }


}
