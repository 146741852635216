import glConf from './glConf';
import glType from './glType';

class glParam {

  constructor() {

    this._gui = undefined

    this.isRender = [];
    this.isRender[glType.SCENE.INTRO] = true;
    this.isRender[glType.SCENE.READY] = false;
    this.isRender[glType.SCENE.MAKING] = false;
    this.isRender[glType.SCENE.SHOES] = false;
    this.isRender[glType.SCENE.ATTENTIONFOOT] = false;
    this.isRender[glType.SCENE.ATTENTIONHEM] = false;

    this.isPause = false;

    this.intro = {
      speed:{value:3, min:0, max:10, use:true},
      x1:{value:-32, min:-200, max:200, use:true},
      y1:{value:30, min:-200, max:200, use:true},
      x2:{value:-4, min:-200, max:200, use:true},
      y2:{value:-99, min:-200, max:200, use:true},
      x3:{value:-9, min:-200, max:200, use:true},
      y3:{value:34, min:-200, max:200, use:true},
      x4:{value:-23, min:-200, max:200, use:true},
      y4:{value:-54, min:-200, max:200, use:true},
      x5:{value:-39, min:-200, max:200, use:true},
      y5:{value:-100, min:-200, max:200, use:true},
      x6:{value:-3, min:-200, max:200, use:true},
      y6:{value:-29, min:-200, max:200, use:true},
      x7:{value:-34, min:-200, max:200, use:true},
      y7:{value:-16, min:-200, max:200, use:true},
      x8:{value:-4, min:-200, max:200, use:true},
      y8:{value:-70, min:-200, max:200, use:true},
      x9:{value:-13, min:-200, max:200, use:true},
      y9:{value:17, min:-200, max:200, use:true},
      x10:{value:-19, min:-200, max:200, use:true},
      y10:{value:100, min:-200, max:200, use:true},
      x11:{value:-5, min:-200, max:200, use:true},
      y11:{value:-137, min:-200, max:200, use:true},
      x12:{value:-28, min:-200, max:200, use:true},
      y12:{value:-123, min:-200, max:200, use:true}
    };

    this.ready = {
      speed:{value:5, min:0, max:10, use:true}
    };

    this.making = {
      speed:{value:3, min:0, max:10, use:true},
      p1:{value:24, min:0, max:200, use:true},
      p2:{value:33, min:0, max:50, use:true},
      p3:{value:0, min:0, max:50, use:true},
      p4:{value:22, min:0, max:100, use:true},
      p5:{value:60, min:0, max:150, use:true}
    };

    this.shoes = {
      size:{value:100, min:0, max:300, use:true},
      alpha:{value:200, min:0, max:300, use:true},
      lightX:{value:100, min:-200, max:100, use:true},
      lightY:{value:50, min:-200, max:100, use:true},
      lightZ:{value:100, min:-200, max:100, use:true}
    };

    this._init();

  }


  _init() {

    if(!glConf.IS_PREVIEW) {
      return
    }

    // this._gui = new dat.GUI();
    // // this._addGui(this.intro, 'intro');
    // // this._addGui(this.ready, 'ready');
    // // this._addGui(this.making, 'making');
    // this._addGui(this.shoes, 'shoes');

  }


  _addGui(obj, folderName) {

    const folder = this._gui.addFolder(folderName);
    for(let k in obj) {
      if(obj[k].use) {
        folder.add(obj[k], 'value', obj[k].min, obj[k].max).name(k)
      }
    }

  }

}

export default new glParam();
