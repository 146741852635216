// @flow
import * as React from 'react'
import styled from 'styled-components'
import closeImg from './img/icon-close.png'

type Props = {
  children: React.Node,
  onRemove: () => void
}

const ButtonD: React$StatelessFunctionalComponent<Props> = React.memo(
  ({ children, onRemove, ...props }) => (
    <ButtonStyle {...props}>
      {children}
      <CloseIcon onClick={onRemove}>
        <img src={closeImg} width={12} height={12} />
      </CloseIcon>
    </ButtonStyle>
  )
)

export default ButtonD

const ButtonStyle = styled.div`
  display: inline-block;
  line-height: 28px;
  background-color: #fff;
  font-size: 13px;
  padding: 0 32px 0 15px;
  border: ${({ theme }) => `1px solid ${theme.color.grey3}`};
  border-radius: 15px;
  position: relative;
  white-space: nowrap;
`

const CloseIcon = styled.button`
  width: 44px;
  height: 44px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -22px;
  margin-right: -4px;
`
