// @flow
import React from 'react'
import SelectButton from '../buttons/ButtonC'
import RemoveButton from '../buttons/ButtonD'

type Props = {
  tag: string,
  label: string,
  onSelect?: () => void,
  onRemove?: () => void
}

// $FlowFixMe
const { fitTags } = process.env.CONFIG

const StringList: React$StatelessFunctionalComponent<Props> = React.memo(
  ({ tag, label, onSelect, onRemove }) => (
    <li>
      {!!onSelect && (
        <SelectButton onClick={onSelect}>
          {tag === 'fit' ? fitTags[label] : label}
        </SelectButton>
      )}
      {!!onRemove && (
        <RemoveButton onRemove={onRemove}>
          {tag === 'fit' ? fitTags[label] : label}
        </RemoveButton>
      )}
    </li>
  )
)

export default StringList
