// @flow
import React from 'react'
import type { ComponentType, Node } from 'react';
import styled from 'styled-components'


type Props = {
  color?: string,
  children?:Node
}

function Try({ color, children, ...rest }: Props) {
  return (
    <Icon color={color} children={children} {...rest} >
      {children}
    </Icon>
  )
}

export default Try

const Icon = styled.button`
  position: relative;
  background-color: ${({ theme, color }) => color ? theme.color[color] : theme.color.yellow};
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 0 5px;
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`