import * as React from 'react'
import styled from 'styled-components'
import QRCode from 'qrcode.react'

type Props = {
  image: string,
  bordered?: boolean
}

function QRcode({ value, bordered, ...rest }: Props) {
  return (
    <Root bordered={bordered} {...rest}>
      <QRCode value={value} size={100} />
    </Root>
  )
}

export default QRcode

const Root = styled.div`
  width: 160px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ bordered, theme }) =>
    `5px solid ${bordered ? theme.color.yellow : '#fff'}`};
  border-radius: 20px;
  background-color: #fff;
`
