type JisWidth = 'A' | 'B' | 'C' | 'D' | 'E' | 'EE' | 'EEE' | 'EEEE' | 'F' | 'G'

export default (jisWidth: JisWidth) => {
  if (!jisWidth) {
    return undefined
  }
  if (jisWidth.length === 1) {
    return jisWidth
  }
  return `${jisWidth.length}E`
}
