import axios from 'axios'
import humps from 'humps'
import { actions as appActions } from '~/redux/modules/app'

const { CLOUD } = process.env.CONFIG
let store = null

// リクエストとレスポンスの共通処理を設定
export const cloudApi = axios.create({
  baseURL: CLOUD,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/json'
  }
})

// リクエスト処理を共通化
cloudApi.interceptors.request.use(config => {
  if (config.data) {
    config.data = humps.decamelizeKeys(config.data)
  }
  return config
})

// レスポンス処理を共通化
cloudApi.interceptors.response.use(
  response => {
    return humps.camelizeKeys(response)
  },
  error => {
    store.dispatch(appActions.networkError(`エラーが発生しました。<br/>サポートセンターまでご連絡下さい。<br/><br/>CLOUDAPIエラー：${error}`))
    console.error(error)
  }
)

export const registerStore = _store => {
  store = _store
}

export const wait = time => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, time)
  })
}
