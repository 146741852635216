// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import MeasurementReady from '~/components/MeasurementReady'
import { actions } from '~/redux/modules/app'

const mapDispatchToProps = dispatch => ({
  refresh: () => dispatch(actions.refresh()),
  dataLayerPush: params => dispatch(actions.dataLayerPush(params))
})

export default connect(
  null,
  mapDispatchToProps
)(MeasurementReady)
