import webgl from './index';
import glConf from './glConf';
import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import attentionHemNextBtn from './attentionHemNextBtn';
import attentionHemPosition from './attentionHemPosition';

import readyDesc from './readyDesc'

export default class attentionHem extends object3d {


  constructor(opt) {

    super(opt);

    this._fd = undefined
    this._foot = undefined

    this._text = undefined
    this._nextBtn = undefined
    this._btn = undefined

    this.onClick = undefined
    this.onComplete = undefined

  }


  init() {
    super.init();

    this._text = new image({
      tex:glTextures.get(glConf.PATH_IMG + 'attentionHem_text.png')
    })
    this._text.init();
    this._text.setSize(496, 82);
    this._text.alpha(0);
    this.add(this._text)

    this._fd = new image({
      tex:glTextures.get(glConf.PATH_IMG + 'attentionHem_fd.png')
    })
    this._fd.init();
    this._fd.setSize(1127, 808);
    this._fd.alpha(0);
    this.add(this._fd);

    this._nextBtn = new attentionHemNextBtn();
    this._nextBtn.init();
    this.add(this._nextBtn);

    this._foot = new attentionHemPosition()
    this._foot.init()
    this.add(this._foot);


    this._btn = document.createElement('div');
    this._btn.setAttribute('id', 'webgl-nextAttentionHemLengthBtn');
    this._btn.style.position = 'absolute';
    //this._btn.style.backgroundColor = '#FF0000';
    this._btn.style.opacity = 0;
    this._btn.style.display = 'none'
    this._btn.addEventListener('click', () => {
      this.hide()
      webgl.startAttentionFootPosition();
    })
    document.getElementById('app-root').appendChild(this._btn);

    this.resize();

  }

    show() {
      const duration = 1;
      const delay = 0.5;
      const ease = Power4.easeInOut;

      this._fd.fadeIn({
        duration: duration,
        delay: delay,
        ease: ease
      })

      this._foot.show({
        duration: duration,
        delay: delay,
        ease: ease
      })

      this._text.fadeIn({
        duration:duration,
        delay:delay,
        ease:ease
      })
      this._btn.style.display = 'block'

  }

  // 消す
  hide() {
    document.getElementById('app-root').removeChild(this._btn)

    const duration = 1;
    const dx = -this.sw;
    const interval = 0.75;
    const ease = Power4.easeInOut;

/*
    TweenMax.to(this._circle.position, duration, {
      x:this._circle.position.x + dx,
      delay:interval * 0,
      ease:ease
    });
    */

    TweenMax.to(this._text.position, duration, {
      x:this._text.position.x + dx,
      delay:interval,
      ease:ease
    });
    this._text.fadeOut({
      duration:duration,
      delay:interval * 2,
      ease:ease
    });

    TweenMax.to(this._fd.position, duration, {
      x:this._fd.position.x + dx,
      delay: interval,
      ease: ease
    });

    this._fd.fadeOut({
      duration:duration,
      delay:interval * 2,
      ease:ease
    });

    TweenMax.to(this._foot.position, duration, {
      x:this._foot.position.x + dx,
      delay: interval,
      ease: ease
    });

/*
    this._foot.hide({
      duration:duration,
      interval:interval,
      ease:ease
    });
    */

    this._nextBtn.hide({
      delay:0.5,
      onComplete: () => {
        this._eHided()
      }
    });

  }


  _eHided() {

    if(this.onComplete != undefined) {
      this.onComplete()
    }

  }



  update() {

    super.update();
  }


  resize() {

    super.resize();

    this._btn.style.width = '270px';
    this._btn.style.height = '270px';
    this._btn.style.bottom = '96px';
    this._btn.style.right = '40px';

    this._fd.position.set( ((this.sw * 0.5 ) - (-157) ) * -1, ( this.sh * 0.5 ) - 105 , 0);
    this._foot.position.set( (this.sw * 0.5  - 259 ) * -1, ( this.sh * 0.5 ) - 213 , 0);
    this._text.position.set( ((this.sw * 0.5 ) - 182 ) * -1, ( this.sh * 0.5 ) - 96 , 3);

    this._nextBtn.position.set( 334, -154, 4);

  }





}
