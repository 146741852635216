import glConf from './glConf';
import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import introStartBtn from './introStartBtn';
import introRoundRect from './introRoundRect';
import introCircle from './introCircle';
import introHuman from './introHuman';

export default class intro extends object3d {


  constructor(opt) {

    super(opt);

    this._desc = undefined
    this._startBtn = undefined
    this._circle = undefined
    this._human = undefined
    this._btn = undefined

    this.onClick = undefined
    this.onComplete = undefined

  }


  init({ startCallBack }) {
    super.init();

    this._circle = new introCircle({
      color:0xfff000,
      speed:-0.4
    });
    this._circle.init();
    this.add(this._circle);

    this._human = new introHuman()
    this._human.init()
    this.add(this._human);

    this._startBtn = new introStartBtn();
    this._startBtn.init();
    this.add(this._startBtn);

    this._desc = new image({
      tex:glTextures.get(glConf.PATH_IMG + 'intro_desc.png')
    })
    this._desc.init();
    this._desc.setSize(584, 170);
    this.add(this._desc);

    this._btn = document.createElement('div');
    this._btn.setAttribute('id', 'webgl-startBtn');
    this._btn.style.position = 'absolute';
    this._btn.style.backgroundColor = '#FF0000';
    this._btn.style.opacity = 0;
    this._btn.addEventListener('click', () => {
      this.hide()
      startCallBack()
    })
    document.getElementById('app-root').appendChild(this._btn);

    this.resize();

  }


  // 消す
  hide() {

    document.getElementById('app-root').removeChild(this._btn)

    // TweenMax.killTweensOf(this.scale);
    // TweenMax.killTweensOf(this.rotation);
    // TweenMax.killTweensOf(this._container.scale);

    const duration = 1;
    const dx = -this.sw;
    const interval = 0.075;
    const ease = Power4.easeInOut;

    TweenMax.to(this._circle.position, duration, {
      x:this._circle.position.x + dx,
      delay:interval * 0,
      ease:ease
    });

    TweenMax.to(this._human.position, duration, {
      x:this._human.position.x + dx,
      delay:interval * 1,
      ease:ease
    });

    TweenMax.to(this._desc.position, duration, {
      x:this._desc.position.x + dx,
      delay:interval * 2,
      ease:ease
    });

    this._desc.fadeOut({
      duration:duration,
      delay:interval * 2,
      ease:ease
    });

    this._startBtn.hide({
      delay:interval * 2.5,
      onComplete: () => {
        this._eHided()
      }
    });

  }


  _eHided() {

    if(this.onComplete != undefined) {
      this.onComplete()
    }

  }



  update() {

    super.update();




  }


  resize() {

    super.resize();

    const offsetX = 60;

    //this._startBtn.position.set(-24 + offsetX, -150, 0);
    this._startBtn.position.set( 334, -154, 0);
    
    this._desc.position.set( -80, 220, 0);

    this._circle.setSize(this.sw * 0.35);
    this._circle.position.set(-this.sw * 0.5, this.sh * 0.5, 0);

    this._btn.style.width = '270px';
    this._btn.style.height = '270px';
    this._btn.style.bottom = '96px';
    this._btn.style.right = '40px';

  }





}
