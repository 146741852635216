import { URLJoin } from '../helpers/string'
const { CLOUD } = process.env.CONFIG
import glConf from './glConf';

class glTextures {

  constructor() {

    this._list = []
    this._textures = []
    this._loadedNum = 0

    this.resolve = undefined
    this.reject = undefined

  }


  load(opt) {

    this._list = opt.list
    this._loadTex()

    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })

  }


  _loadTex() {

    let src = this._list[this._loadedNum]

    // 環境ごとのURLに変換
    // if(!glConf.IS_PREVIEW) {
    //   src = URLJoin(CLOUD, src)
    // }

    const texture = new THREE.TextureLoader().load(src, () => {
      this._loaded()
    }, undefined, () => {
      // エラーでも進めちゃう
      this._loaded()
    })

    this._textures.push({
      src:src,
      texture:texture
    })

  }


  _loaded() {

    this._loadedNum++
    if(this._loadedNum >= this._list.length) {
      this.resolve()
    } else {
      this._loadTex()
    }

  }


  get(src) {

    for(let i = 0; i < this._textures.length; i++) {
      const t = this._textures[i]
      if(t.src == src) {
        return t.texture
      }
    }

    const texture = new THREE.TextureLoader().load(src)
    this._textures.push({
      src:src,
      texture:texture
    })
    return texture

  }

}

export default new glTextures()
