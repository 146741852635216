import vert from './shader/readyLine.vert';
import frag from './shader/readyLine.frag';

import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import glParam from './glParam';
import glType from './glType';
import glConf from './glConf';
import readyDotItem from './readyDotItem';


export default class readyLine extends object3d {

  constructor(opt) {

    super(opt)

    this._dot = undefined
    this._line = undefined
    this._pos = undefined
    this._posBuffer = undefined

    this.anm = {
      angle:{value:-1},
      radius:{value:1}
    }

  }


  init() {

    super.init();

    this._posBuffer = [];
    this._pos = new THREE.Vector3(0, 0, 0);

/*
    for(let i = 0; i < 100; i++) {
      this._computePosition();
      this.cnt++;
    }

    this._line = new THREE.Mesh(
      this._makeGeo(),
      new THREE.ShaderMaterial({
        transparent:true,
        vertexShader:vert,
        fragmentShader:frag,
        uniforms:{
          alpha:{value:1}
        }
      })
    );
    this.add(this._line);
*/
    this._dot = new THREE.Mesh(
      new THREE.CircleBufferGeometry(0.5, 32),
      new THREE.MeshBasicMaterial({
        color:0xffffff,
        transparent:true,
        opacity:0
      })
    );
    this.add(this._dot);
    this._dot.scale.set(22, 22, 1);

    this.visible = false;

    this.resize();

  }


  getNowAngle() {

    return this.anm.angle.value;

  }



  show(opt) {

    this.anm.angle.value = -90
    TweenMax.to(this.anm.angle, 10, {
      value:this.anm.angle.value + 360 * 3,
      delay:0,
      ease:Power1.easeInOut,
      onStart:() => {
        this.visible = true;
      },
      onComplete:() => {
        this.visible = false;
        if(opt.onComplete != null) {
          opt.onComplete()
        }
      }
    })

    TweenMax.to(this.anm.radius, 3, {
      value:4,
      delay:0,
      ease:Power1.easeInOut
    })


/*
    const delay = 1.5;
    const alpha = this._line.material.uniforms.alpha;
    alpha.value = 0;
    TweenMax.to(alpha, 1.5, {
      value:1,
      delay:delay,
      ease:Power1.easeOut
    })
*/

    /*
    TweenMax.to(this._dot.material, 1.5, {
      opacity:1,
      delay:delay,
      ease:Power1.easeOut
    })
    */


  }




  update() {

    if(!glParam.isRender[glType.SCENE.READY] || !this.visible) {
      return;
    }

    super.update();

/*
    this._computePosition();

    this._line.geometry.dispose();
    this._line.geometry = this._makeGeo();
*/

    // this._dot.position.set(this._pos.x, this._pos.y, 0);
    this._dot.position.x = this._pos.x
    this._dot.position.y = this._pos.y

  }


  _computePosition() {

    // const radius = this.sh * 0.311688312 * this.anm.radius.value;
    const radius = this.sw * glConf.CMN_RADIUS * this.anm.radius.value;
    const speed = glParam.ready.speed.value * 0.01 * 0.5;
    // const radian = this._cnt * speed;
    const radian = glUtils.radian(this.anm.angle.value);
    this._pos.x = Math.sin(radian) * radius;
    this._pos.y = Math.cos(radian) * radius;

    this._posBuffer.push(this._pos.clone());
    if(this._posBuffer.length >= 50) {
      this._posBuffer.shift();
    }

  }


  resize() {

    super.resize();


  }


  _makeGeo() {


    const path = new THREE.CatmullRomCurve3(this._posBuffer);
    const geo = new THREE.TubeBufferGeometry(path, 16, 11.75, 6, false);

    const color = [];
    const len = geo.attributes.position.count;
    for(let i = 0; i < len; i++) {
      color.push(1);
      color.push(1);
      color.push(1);
      color.push(glUtils.map(i, 0, 2, 0, len));
    }

    const colors = new Float32Array(color);
    geo.addAttribute('color', new THREE.BufferAttribute(colors, 4));

    return geo;

  }





}
