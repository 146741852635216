// @flow
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import configureStore, {history} from './redux/configureStore'
import App from './App'
import webgl from './webgl'
import * as api from './api'


const { store } = configureStore({})
const $root = document.getElementById('app-root')

api.registerStore(store)

if ($root) {
  webgl.setRedux(store)
  ReactDOM.render(
    <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
    </Provider>,
    $root
  )
}
