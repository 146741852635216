import glConf from './glConf';
import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import makingItem from './makingItem';

export default class makingItemMgr {

  constructor(opt) {

    this.opt = opt
    this._items = undefined
    this._geo = undefined

  }


  init() {

    this._items = [];

    this._geo = new THREE.PlaneBufferGeometry(1, 1);

    for(let i = 0; i < glConf.NUM_ITEM; i++) {
      this._addItem();
    }

  }


  _addItem() {

    const src = this.opt.images[this._items.length % this.opt.images.length]

    const item = new makingItem({
      tex:glTextures.get(src),
      geo:this._geo
    });
    item.init();

    this._items.push(item);

    return item;

  }


  setAllItem() {

    // ランダムで一個だけ選択
    const src = this.opt.images[glUtils.randomInt(0, this.opt.images.length - 1)]
    const tex = glTextures.get(src);

    for(let i = 0; i < this._items.length; i++) {
      this._items[i].setTex(tex);
    }

  }


  setAllItemRandom() {

    for(let i = 0; i < this._items.length; i++) {
      const src = this.opt.images[glUtils.randomInt(0, this.opt.images.length - 1)];
      const tex = glTextures.get(src);
      this._items[i].setTex(tex);
    }

  }


  getItem(key) {

    return this._items[key];

  }


  getRandItemKey() {

    return glUtils.randomInt(0, this._items.length - 1);

  }

}
