// @flow
import React from 'react'
import imgPng from '~/assets/img/no-result.png'
import styled from 'styled-components'

function NoRecommendItems() {
  return (
    <Root>
      <img src={imgPng} width={488} />
    </Root>
  )
}

export default NoRecommendItems

const Root = styled.div`
  width: 100%;
  height: calc(100vh - 75px);
  display: flex;
  justify-content: center;
  align-items: center;
`
