// @flow
import React, { Component } from 'react'
import RefreshButton from './RefreshButton'

type Props = {
  appActions: Object,
  webglActions: Object,
  recommendActions: Object,
}

type State = {}

export default class Measurement extends Component<Props, State> {
  async componentDidMount() {
    const { appActions, webglActions, recommendActions } = this.props

    const { footUuid } = await appActions.getFootUuid(appActions.digitizerCode)
    appActions.scan(footUuid)
    const [measurement] = await Promise.all([
      appActions.watchMeasurement(footUuid),
      appActions.watchAnimationState()
    ])
    // 3Dデータファイルを登録
    webglActions.registerPlyFileUrl(measurement.file)
    // リコメンドデータを取得
    /*
    const {
      footLength, footBoundingWidth
    } = measurement.left.footMeasurement
    */
    const {
      left, right
    } = measurement
    let footLength = left.footMeasurement.footLength
    let footBoundingWidth = left.footMeasurement.footBoundingWidth

    if( left.footMeasurement.footLength < right.footMeasurement.footLength) {
      footLength = right.footMeasurement.footLength
      footBoundingWidth = right.footMeasurement.footBoundingWidth
    }

    await recommendActions.registerRecommend({
      size: footLength,
      width: footBoundingWidth
    })
    // JISデータを取得
    await appActions.getJisSize(footUuid)
    appActions.dataLayerPush({ pageID: 'measurement' })
  }

  render() {
    const { appActions } = this.props
    return <RefreshButton onClick={appActions.refresh} />
  }
}
