// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import type {Match} from 'react-router-dom'
import { actions as appActions } from '../redux/modules/app'
import { selecteFavorites, selecteReview, selectReviewItem } from '~/redux/modules/recommend'
import { actions as recommendActions } from '~/redux/modules/recommend'
import ReviewImpressions from '../components/ReviewImpressions'

const mapStateToProps = (state, props) => ({
  favoriteItems: selecteFavorites(state),
  review: selecteReview(state, props),
  item: selectReviewItem(state, props)
})

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators({ ...appActions }, dispatch),
  recommendActions: bindActionCreators({ ...recommendActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewImpressions)
