import glConf from './glConf'
import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import glType from './glType';
import glParam from './glParam';


export default class attentionFootPosition extends object3d {

  constructor(opt) {

    super(opt)

    this._fd = undefined
    this._meshLeft = undefined
    this._meshRight= undefined
    this._anm = undefined
    this.STATE = {
      LEFT_POSITION : 1<<0,
      LEFT_ROTATION : 1<<1,
      RIGHT_POSITION : 1<<2,
      RIGHT_ROTATION : 1<<3

    }
  }


  init() {

    super.init();

    console.log("FootPosition INIT")

    this._anm &= ~(this.STATE.LEFT_POSITION | this.STATE.LEFT_ROTATION |  this.STATE.RIGHT_POSITION | this.STATE.RIGHT_ROTATIO);

    this._meshLeft = new image({
      tex:glTextures.get(glConf.PATH_IMG + 'attentionFoot_left.png')
    });
    this._meshLeft.init();
    this._meshLeft.setSize(89, 229);
    this._meshLeft.alpha(0);
    this.add(this._meshLeft);
    

    this._meshRight = new image({
      tex:glTextures.get(glConf.PATH_IMG + 'attentionFoot_right.png')
    });
    this._meshRight.init();
    this._meshRight.setSize(87, 225);
    this._meshRight.alpha(0);
    this.add(this._meshRight);

    this._fd = new image({
      tex:glTextures.get(glConf.PATH_IMG + 'attentionFoot_fd.png')
    })
    this._fd.init();
    this._fd.setSize(800, 1000);
    this._fd.alpha(0);
    this.add(this._fd)

    this.resize();

  }

  show() {
    this._fd.fadeIn({
        duration: 1,
        delay: 0.5,
        ease: Power4.easeInOut
    })

    this._meshLeft.fadeIn({
        duration:1,
        delay:  1,
        ease: Power4.easeInOut
    })
    this._meshRight.fadeIn({
        duration:1,
        delay:  1,
        ease: Power4.easeInOut,
        onComplete:() => {
          this.startAmin()
        }
    })
  }


  hide(opt) {
    this._anm &= ~(this.STATE.LEFT_POSITION | this.STATE.LEFT_ROTATION |  this.STATE.RIGHT_POSITION | this.STATE.RIGHT_ROTATIO);
    const duration = 1;
    const dx = -this.sw;
    const interval = 0.075;
    const ease = Power4.easeInOut;

    TweenMax.to(this._fd.position, duration, {
      x:this._fd.position.x + dx,
      delay:opt.interval * 2,
      ease:ease
    })
    this._fd.fadeOut({
      duration:duration,
      delay:opt.interval * 2,
      ease:ease
    });

    TweenMax.to(this._meshLeft.position, opt.duration, {
      x:this._meshLeft.position.x + opt.dx,
      delay:opt.interval * 2,
      ease:opt.ease
    });
    TweenMax.to(this._meshRight.position, opt.duration, {
      x:this._meshRight.position.x + opt.dx,
      delay:opt.interval * 2,
      ease:opt.ease
    });


    this._meshLeft.fadeOut({
      duration:duration,
      delay:opt.interval * 2,
      ease:ease
    })
    this._meshRight.fadeOut({
      duration:duration,
      delay:opt.interval * 2,
      ease:ease,
        onComplete:() => {
          this.visible = false;
        }
    })
  }


  startAmin() {
    TweenMax.to(this._meshLeft.rotation, 1.5, {
      x:0,
      y:0,
      z:0,
      delay: 0.5,
      ease:Power2.easeOut,
      onComplete: () => {
        this._anm |=  this.STATE.LEFT_ROTATION
      }
    })

    TweenMax.to(this._meshLeft.position, 1.5, {
      x: (this.sw * 0.5 - 276 ) * -1,
      y: this.sh * 0.5 - 374,
      delay: 0.5,
      ease:Power2.easeOut,
      onComplete: () => {
        this._anm |=  this.STATE.LEFT_POSITION
      }
    })

    TweenMax.to(this._meshRight.rotation, 1.5, {
      x:0,
      y:0,
      z:0,
      delay: 0.5,
      ease:Power2.easeOut,
      onComplete: () => {
        this._anm |=  this.STATE.RIGHT_ROTATION
      }
    })

    TweenMax.to(this._meshRight.position, 1.5, {
      x:(this.sw * 0.5 - 430 ) * -1,
      y:this.sh * 0.5 - 378,
      delay: 0.5,
      ease:Power2.easeOut,
      onComplete: () => {
        setTimeout(() => {
          this._anm |=  this.STATE.RIGHT_POSITION
        },1000)
      }
    })
  }




  update() {

    super.update();

    if(!glParam.isRender[glType.SCENE.ATTENTIONFOOT] || !this.visible) {
      return;
    }

    if( this._anm.toString(2) == '1111') {
      this._anm &= ~(this.STATE.LEFT_POSITION | this.STATE.LEFT_ROTATION |  this.STATE.RIGHT_POSITION | this.STATE.RIGHT_ROTATIO);
      this.resize()
      this.startAmin()
    }

  }


  resize() {
    this.reset()
    this._fd.position.set( (this.sw * 0.5 ) * -1, this.sh * 0.5  , 0);
    super.resize();
  }

  reset() {
    this._meshLeft.rotation.z =Math.PI/180 * 36;  
    this._meshLeft.position.set( (this.sw * 0.5 - 203 ) * -1, this.sh * 0.5 - 450, 1)
    this._meshRight.rotation.z =Math.PI/180 * 31 * -1;  
    this._meshRight.position.set( -30, this.sh * 0.5 - 360, 1)
  }

}
