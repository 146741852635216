// @flow
import React from 'react'
import SelectButton from '../buttons/ButtonC'
import RemoveButton from '../buttons/ButtonD'

type Props = {
  tag: string,
  label: string,
  onSelect?: () => void,
  onRemove?: () => void
}

const BooleanList: React$StatelessFunctionalComponent<Props> = React.memo(
  ({ tag, label, onSelect, onRemove }) => {
    if (tag === 'shoelace') {
      tag = '靴紐'
    } else if (tag === 'strap') {
      tag = 'ストラップ'
    }
    const labelStr = label ? 'あり' : 'なし'
    return (
      <li>
        {!!onSelect && (
          <SelectButton onClick={onSelect}>{`${tag}${labelStr}`}</SelectButton>
        )}
        {!!onRemove && (
          <RemoveButton onRemove={onRemove}>{`${tag}${labelStr}`}</RemoveButton>
        )}
      </li>
    )
  }
)

export default BooleanList
