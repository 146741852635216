import React from 'react'
import styled, { keyframes } from 'styled-components'
import ScrollLock from 'react-scrolllock'
import { Transition } from 'react-spring'

const loaderSize = 180
const dotSize = 8
const points = 8
const slice = (2 * Math.PI) / points
const radius = loaderSize / 2 - dotSize / 2
const centerX = loaderSize / 2 - dotSize / 2
const centerY = loaderSize / 2 - dotSize / 2

function Loading({ loading, ...rest }: { loading: boolean }) {
  return (
    <Transition
      items={loading}
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
    >
      {show =>
        show &&
        (props => (
          <Root {...rest} style={props}>
            <Wrapper>
              <Inner>
                {[...Array(10).keys()].map(idx => {
                  const n = idx + 1
                  const angle = slice * n
                  const x = centerX + radius * Math.cos(angle)
                  const y = centerY + radius * Math.sin(angle)
                  return (
                    <Dot
                      key={n}
                      style={{
                        top: `${Math.round(x)}px`,
                        left: `${Math.round(y)}px`
                      }}
                    />
                  )
                })}
              </Inner>
            </Wrapper>
            <ScrollLock />
          </Root>
        ))
      }
    </Transition>
  )
}

export default Loading

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
`

const rotate360 = keyframes`
  from {
    transfrom: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
`

const spin = keyframes`
  from {
    transfrom: rotate(0deg)
  }
  to {
    transform: rotate(180deg)
  }
`

const Wrapper = styled.div`
  width: ${loaderSize}px;
  height: ${loaderSize}px;
  position: relative;
  animation: ${rotate360} 5s linear infinite;
`

const Inner = styled.div`
  width: ${loaderSize}px;
  height: ${loaderSize}px;
  position: relative;
  animation: ${spin} 2s cubic-bezier(0.165, 0.84, 0.44, 1) 1s infinite;
`

const Dot = styled.div`
  width: ${dotSize}px;
  height: ${dotSize}px;
  background-color: ${({ theme }) => theme.color.yellow};
  border-radius: 50%;
  position: absolute;
`
