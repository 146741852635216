// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as appActions } from '../redux/modules/app'
import { actions as recommendActions } from '~/redux/modules/recommend'
import { actions as webglActions } from '~/redux/modules/webgl'
import Karte from '../components/Karte'

const mapStateToProps = state => ({
  introAnimationed: state.app.introAnimationed,
  plyFileUrl: state.app.file? state.app.file: null,
  choicesAndKarteQrCodeUrl: state.app.choicesAndKarteQrCodeUrl,
  left: state.app.measurement? state.app.measurement.left: null,
  right: state.app.measurement? state.app.measurement.right: null,
  jis: state.app.jis? state.app.jis: null,
  heelWidthJa: state.app.heelWidthJa,
  instepHeightJa: state.app.instepHeightJa,
  jisWidthDisplay: state.app.jisWidthDisplay,
  karteDataLevel: state.app.karteDataLevel,
  footUuid: state.app.footUuid
})

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators({ ...appActions }, dispatch),
  webglActions: bindActionCreators({ ...webglActions }, dispatch),
  recommendActions: bindActionCreators({ ...recommendActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Karte)
