import glConf from './glConf';
import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import glType from './glType';
import glParam from './glParam';
import makingItem from './makingItem';
import makingSceneBase from './makingSceneBase';

export default class makingSceneG extends makingSceneBase {

  constructor(opt) {

    super(opt)

    this._num = undefined
    this._lineNum = undefined
    this._offset = undefined

  }


  init() {

    super.init();

    this._num = 50;
    this._lineNum = 1;
    this._offset = 0;

    for(let i = 0; i < this._num * this._lineNum; i++) {
      this._addItem(i);
    }
    this._getLastItem().setLast();

    this.resize();

  }


  update() {

    if(!glParam.isRender[glType.SCENE.MAKING] || !this.isUpdate) {
      return;
    }

    super.update();

    let radius = this.sw * glParam.making.p4.value * 0.01;
    const num = this._items.length;
    for(let i = 0; i < num; i++) {

      const item = this._items[i];

      const key = i % this._num;

      const radian = glUtils.radian(this._offset + glParam.making.p1.value * key);

      item.position.x = Math.sin(radian) * radius;
      item.position.y = Math.cos(radian) * radius;

      item.position.z = (-this.sw * 0.5) + i * (this.sw * glParam.making.p2.value * 0.001);

      radius += this.sw * glParam.making.p3.value * 0.001;

      item.setSize(glParam.making.p5.value);
    }



    this._offset += 0.5;

  }


  resize() {

    super.resize();


  }


}
