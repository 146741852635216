// @flow
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  children: React.Node
}

const ButtonA: React$StatelessFunctionalComponent<Props> = React.memo(
  ({ children, ...rest }) => <ButtonStyle {...rest}>{children}</ButtonStyle>
)

export default ButtonA

const ButtonStyle = styled.button`
  font-size: 13px;
  font-family: ${({ theme }) => theme.font.bold};
  height: 50px;
  background-color: ${({ theme, color }) => color ? theme.color[color] : '#fff'};
  border: 1px solid ${({ theme, color }) => color ? theme.color[color] : theme.color['gray']};
  padding: 0 27px;
  border-radius: 25px;
  color: #000;
  &:disabled {
    color: ${({ theme }) => theme.color.grey};
  }
`
