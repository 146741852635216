// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as appActions } from '~/redux/modules/app'
import { actions as recommendActions } from '~/redux/modules/recommend'
import { selecteFavorites, selecteReview } from '~/redux/modules/recommend'
import ReviewComplete from '../components/ReviewComplete'

const mapStateToProps = (state,props) => ({
  favoriteItems: selecteFavorites(state),
  review: selecteReview(state, props)
})

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators({ ...appActions }, dispatch),
  recommendActions: bindActionCreators({ ...recommendActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewComplete)
