import glConf from './glConf';
import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import makingItem from './makingItem';

export default class makingSceneBase extends object3d {

  constructor(opt) {

    super(opt)

    this._items = undefined
    this._itemMgr = undefined
    this._offset = undefined
    this._showInterval = undefined
    this._jumpInterval = undefined
    this._offsetInterval = undefined

    this._fixItemKey = undefined

    this.isUpdate = false

  }


  init() {

    super.init();

    this._offset = 0;
    this._showInterval = 0.02;
    this._jumpInterval = 0.01;
    this._offsetInterval = 200;

    this._items = [];
    this._itemMgr = this.opt.itemMgr;

    this._fixItemKey = this._itemMgr.getRandItemKey();

  }


  show(opt) {

    const interval = this._showInterval;
    const type = glUtils.randomInt(0, 1);

    for(let i = 0; i < this._items.length; i++) {
      this._items[i].show({
        delay:opt.delay + i * interval,
        type:type
      });
    }

  }


  hide(opt) {

    for(let i = 0; i < this._items.length; i++) {
      this._items[i].hide({
        onComplete:(i == this._items.length - 1 ? () => {
          if(opt.onComplete != null) {
            opt.onComplete()
          }
        } : null)
      });
    }

  }


  start() {

    this._startBefore();

    this.visible = true;

    this.isUpdate = true;
    for(let i = 0; i < this._items.length; i++) {
      const item = this._items[i];
      this.add(item);
      item.visible = true;
    }

    this.show({
      delay:0.2
    });

    this.opt.loading.jump2({
      delay:0
    })

    this._startAfter();

  }


  _startBefore() {

    // 画像はランダムに
    this._itemMgr.setAllItemRandom();

  }


  _startAfter() {

    // let delay = 0.5;
    //
    // this.jump({
    //   delay:delay
    // });
    //
    // this.opt.loading.jump({
    //   delay:delay
    // })

  }


  peakAction() {

    this._jumpInterval = 0;
    this.jump({
      delay:0
    });

    this.opt.loading.jump({
      delay:0
    })

  }


  reset() {

    TweenMax.killTweensOf(this);

    this.isUpdate = false;
    for(let i = 0; i < this._items.length; i++) {
      const item = this._items[i];
      this.remove(item);
      item.reset();
    }

    this.visible = false;
    this._offset = 0;

  }


  jump(opt) {

    const len = this._items.length;
    for(let i = 0; i < len; i++) {
      this._items[i].jump({
        delay:opt.delay,
        delay:0,
        odd:(i % 2 != 0),
        onComplete:(item) => {
          this._eJumped(item);
        }
      })
    }

  }

  _eJumped(item) {

    // if(!this.isUpdate) {
    //   return;
    // }
    //
    // if(item.opt.isLast) {
    //   this.jump({
    //     delay:1
    //   })
    //   this.opt.loading.jump({
    //     delay:1
    //   })
    // }

  }


  move(opt) {

    TweenMax.to(this, 1, {
      _offset:this._offset + this._offsetInterval,
      // ease:Elastic.easeOut.config(1, 0.8),
      ease:Power4.easeOut,
      delay:opt.delay
    });

  }


  _eMoved() {

    this.move({
      delay:1
    });

  }


  _addItem(key) {

    const item = this._itemMgr.getItem(key);
    this._items.push(item);

  }


  _getItem(key) {

    return this._items[key];

  }


  _getLastItem() {

    return this._items[this._items.length - 1];

  }


  update() {

    super.update();

  }


  resize() {

    super.resize();

  }

}
