// @flow
import { cloudApi } from '~/api'
import humps from 'humps'
import qs from 'qs'
import polling from '~/helpers/polling'
import pollingMeasurement from '~/helpers/pollingMeasurement'
import confirm from '~/factories/confirm'
import { URLJoin } from '~/helpers/string'
import { formatSize } from '~/helpers/number'
import { Measurement, ReviewQnA, Filter } from '../../types'
import formatJisWidth from '~/helpers/formatJisWidth'

// $FlowFixMe
const { USER_APP, footSurveyTags } = process.env.CONFIG

// Actions
const SHOW_LOADING = 'app/showLoading'
const HIDE_LOADING = 'app/hideLoading'
const SET_MEASUREMENT_STATUS = 'app/SET_MEASUREMENT_STATUS'
const REGISTER_MEASUREMENT = 'app/REGISTER_MEASUREMENT'
const INTRO_ANIMATIONED = 'app/INTRO_ANIMATIONED'
const SET_SCENE = 'app/SET_SCENE'
const SET_INPUT_DATA = 'app/SET_INPUT_DATA'
const SET_FOOT_SURVEY = 'app/SET_FOOT_SURVEY'
const NETWORK_ERROR = 'app/NETWORK_ERROR'
const SET_FOOT_UUID = 'app/SET_FOOT_UUID'
const SET_ALLOW_REFRESH = 'app/SET_ALLOW_REFRESH'
const GENERATE_3D_DATA_ON_READY = 'app/GENERATE_3D_DATA_ON_READY'
const REGISTER_JIS = 'app/REGISTER_JIS'
const SET_STORE_INFO = 'app/SET_STORE_INFO'

type Scene = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7

export type GaParams = {
  pageID: string
}

export const actions = {
  showLoading: () => {
    return { type: SHOW_LOADING }
  },
  hideLoading: () => {
    return { type: HIDE_LOADING }
  },
  setStoreInfo: ({clientCode, jisWidthDisplay, karteDataLevel }:{ clientCode: string, jisWidthDisplay: boolean, karteDataLevel: string }) => (
    dispatch: any
  ) =>  {
    dispatch({
          type: SET_STORE_INFO,
          payload: { clientCode, jisWidthDisplay, karteDataLevel}
    })
  },
  getMeasurement: (footUuid: string) => (dispatch: any, getState: any ): Promise<any> => {
    return new Promise(async (resolve: any, reject: any) => {
      const { data } = await  cloudApi({
        method: 'get',
        url: `/foot/measurement/status/${footUuid}`
      })

      if (data.statusCode === 200) {
        const { left, right, filtering } = data.data.measValue
        const file = data.data["3dUrl"] 
        dispatch({
          type: REGISTER_MEASUREMENT,
          payload: {
            measurement: { left, right },
            file: file,
            filter: filtering
          }
        })
        resolve({ left, right, file: file, filter: filtering})
      } else {
        console.error(data)
        dispatch(actions.networkError('測定データの取得に失敗しました'))

        let { app: { staffName, footId, digitizerCode, visitDate} } = getState()
        dispatch({
          type: SET_INPUT_DATA,
          payload: { staffName, footId,  digitizerCode, visitDate }
        })
        reject()
      }
    })
  },

  setFootUuid: (footUuid: string ) => (
    dispatch: any,
    getState: any
  ) => {
        const choicesAndKarteQrCodeUrl = URLJoin(
          USER_APP,
          'choices_and_karte',
          window.btoa(footUuid)
        )
        dispatch({
          type: SET_FOOT_UUID,
          payload: { footUuid, choicesAndKarteQrCodeUrl }
        })
  },


  setFootSurvey: ( footSurveyresult :Array<ReviewQnA> ) => (
    dispatch: any,
    getState: any
  ) => {
    dispatch({
      type: SET_FOOT_SURVEY,
      payload: { footSurvey : footSurveyresult}
    })
  },

  getAnimationState: () => (dispatch: any, getState: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      const {
        app: { generate3dDataOnReady }
      } = getState()
      if (generate3dDataOnReady) {
        return resolve({ ok: true })
      }
      return reject({ ok: false })
    })
  },
  watchAnimationState: () => (dispatch: any, getState: any): Promise<any> => {
    return new Promise(async resolve => {
      await polling(() => {
        return new Promise(resolve => {
          const {
            app: { generate3dDataOnReady }
          } = getState()
          if (generate3dDataOnReady) {
            const response = { data: { statusCode: 200 } }
            return resolve({ ...response })
          }
          const response = { data: { statusCode: 400 } }
          return resolve({ ...response })
        })
      })
      return resolve({ ok: true })
    })
  },
  getJisSize: (footUuid: string) => (  dispatch: any ): Promise<void> => {
    return new Promise(async (resolve: any, reject: any) => {
      const { data } = await  cloudApi({
        method: 'get',
        //url: `/storeapp/feet/${footUuid}/jis`
        url: `/foot/${footUuid}`
      })
      if (data.statusCode === 200) {
        const { foot } = data.data
        let jisSize = foot.left.jisSize
        let jisWidth = foot.left.jisWidth
        let jisWidthJa = foot.left.jisWidthJa
        if ( foot.left.length < foot.right.length ){
          jisSize = foot.right.jisSize
          jisWidth = foot.right.jisWidth
          jisWidthJa = foot.right.jisWidthJa
        }

        const heelWidthJa = {right: foot.right.heelWidthJa, left: foot.left.heelWidthJa}
        const instepHeightJa = {right: foot.right.instepHeightJa, left: foot.left.instepHeightJa}


        dispatch({
          type: REGISTER_JIS,
          payload: {
            jis: {
              jisSize: `${formatSize(jisSize)}cm`,
              jisWidth: formatJisWidth(jisWidth),
              jisWidthJa: jisWidthJa
            },
            heelWidthJa,
            instepHeightJa,
            filter: foot.measValue.filtering
          }
        })
        resolve()
      } else {
        console.error(data)
        dispatch(actions.networkError('JISデータの取得に失敗しました'))
        reject()
      }
    })
  },
  watchMeasurement: (footUuid: string) => (dispatch: any): Promise<any> => {
    return new Promise(async resolve => {
      try {
        const { data } = await pollingMeasurement(
          () => {
          return cloudApi({
            method: 'get',
            url: `/foot/measurement/status/${footUuid}`
          }).catch(() => {
            throw new Error()
          })
        },
        (status) => {
          return dispatch({
            type: SET_MEASUREMENT_STATUS,
             payload: {
              scanStatus: status
            }
          })
        })

        const { left, right, filter } = data.data.measValue
        console.log("FILTER" , filter)
        const file = data.data["3dUrl"] 
        const { status } = data.data
        dispatch({
          type: REGISTER_MEASUREMENT,
          payload: {
            measurement: { left, right },
            filter: filter,
            file: file,
            scanStatus: status
          }
        })
        resolve({ left, right, filter, file: data.data["3dUrl"] })
      } catch (error) {
        console.log(error.message)

        const rsError = error.message.match(/Realsense/);

        if(rsError === null) {
          //dispatch(actions.networkError(`計測情報の取得に失敗しました。<br>再撮影してください。<br/><br/>${error}`))
          dispatch(actions.networkError(`計測情報の取得に失敗しました。<br>再撮影してください。<br/><br/>・スボンの裾は上げてください。<br />・靴下が黒色の場合は撮影できない場合があります。<br/>問い合わせNO：${footUuid}`))
          return
        }
        dispatch(actions.networkError(`カメラのエラーが発生しました<br/><br/>管理ツールからサービスの再起動を実行してください。<br/>問い合わせNO：${footUuid}`))
      }
    })
  },


  checkStaffCode: (staffCode: string) => (  dispatch: any, getState: any ): Promise<void> => {
    return new Promise(async (resolve: any, reject: any) => {
      const { auth: {token} }= getState()
      const authToken: string = token || ''

      const { data } = await  cloudApi({
        headers: {
          Authorization: `JWT ${authToken}`
        },
        method: 'get',
        url: `/reviewers?code=${staffCode}`,
      })
        console.log(data)
      if (data.statusCode === 200) {
        if ( data.totalCount == 0 ) {
          reject()
          return
        }
        resolve()
      } else if (data.statusCode === 401 ) {
        // TODO LSのデータを消す処理が必要
        dispatch(actions.networkError(`認証エラー`))
      } else {
        console.error(data)
        reject()
      }
    })
  },


  // シーン変更
  setScene: (scene: Scene) => {
    return {
      type: SET_SCENE,
      payload: { scene }
    }
  },
  // スキャンステータスの変更
  setScanStatus: (status: ?string) => {
    return {
      type: SET_MEASUREMENT_STATUS,
      payload: { scanStatus: status }
    }
  },
  // イントロアニメーション終了
  introAnimationed: () => {
    return { type: INTRO_ANIMATIONED }
  },

  // 入力情報
  setInput: (data: {
    staffName: string,
    footId?: string,
    digitizerCode?: string,
    visitDate?: string
  }) => {
    const { staffName, footId, digitizerCode, visitDate } = data
    console.log("setInput",data)
    return {
      type: SET_INPUT_DATA,
      payload: { staffName, footId,  digitizerCode, visitDate }
    }
  },
  // ネットワークエラー
  networkError: (message: string) => {
    return {
      type: NETWORK_ERROR,
      payload: { message }
    }
  },
  setAllowAutoRefresh: (condition: boolean) => {
    return {
      type: SET_ALLOW_REFRESH,
      payload: { condition }
    }
  },
  //自動リロードの実行
  refresh: () => async (dispatch: any, getState: any) => {
    actions.setAllowAutoRefresh(false)
    actions.dataLayerPush({ pageID: 'modal-refresh' })
    const message = refreshConfirmText(getState())
    const isConfirm = await confirm.show(message)
    if (isConfirm) {
      location.reload()
    } else {
      actions.setAllowAutoRefresh(true)
    }
  },
  // 3Dデータ生成の準備完了
  generate3dDataOnReady: () => {
    return { type: GENERATE_3D_DATA_ON_READY }
  },
  // アクセス解析
  dataLayerPush: (params: GaParams) => (dispatch: any, getState: any) => {
    const {
      app: { digitizerCode, clientCode }
    } = getState()
    const sendData = {
      ...params,
      digitizerCode,
      clientCode,
      event: 'loadready'
    }
    // $FlowFixMe
    dataLayer.push(sendData)
  }
}

// Reducer
export type AppState = {
  staffName: ?string,
  digitizerCode: ?string,
  visitDate: ?string,
  clientCode: ?string,
  loading: boolean,
  measurement: ?Measurement,
  footUuid: ?string,
  filter: ?Filter,
  file: ?string,
  footId: ?string,
  footSurvey: ?Array<ReviewQnA>,
  scene: Scene,
  introAnimationed: boolean, // イントロアニメーション終了フラグ
  networkError: string,
  allowAutoRefresh: boolean, // 自動リロードの許可
  generate3dDataOnReady: boolean,
  choicesAndKarteQrCodeUrl: ?string,
  jis: {
    jisSize: string,
    jisWidth: string,
    jisWidthJa: string
  }
}

const initialState = {
  staffName: '',
  digitizerCode: null,
  footId: null,
  visitDate: null,
  clientCode: null,
  footUuid: null,
  measurement: null,
  filter: null,
  loading: true,
  file: null,
  networkError: '',
  jisWidthDisplay: true,
  karteDataLevel: "1",
  footSurvey: [...footSurveyTags],
  scene: 0,
  introAnimationed: false,
  allowAutoRefresh: true,
  generate3dDataOnReady: false,
  choicesAndKarteQrCodeUrl: null,
  jisWidthDisplay: true,
  karteDataLevel: "1",
  jis: {
    jisSize: '',
    jisWidth: '',
    jisWidthJa: ''
  }
}

export default function reducer(
  state: AppState = initialState,
  action: Object
) {
  switch (action.type) {
    case SHOW_LOADING: {
      return { ...state, loading: true }
    }
    case HIDE_LOADING: {
      return { ...state, loading: false }
    }
    case SET_FOOT_UUID: {
      const { footUuid, choicesAndKarteQrCodeUrl } = action.payload
      return { ...state, footUuid, choicesAndKarteQrCodeUrl }
    }
    case SET_STORE_INFO: {
      const { clientCode, jisWidthDisplay, karteDataLevel} = action.payload
      return { ...state, clientCode, jisWidthDisplay, karteDataLevel}
       
    }
    case SET_FOOT_SURVEY: {
      const { footSurvey } = action.payload
      return { ...state, footSurvey}
    }
    case SET_MEASUREMENT_STATUS: {
      const { scanStatus } = action.payload
      return { ...state, scanStatus }
    }
    case REGISTER_MEASUREMENT: {
      const { measurement, filter, file } = action.payload
      return { ...state, measurement, filter,file }
    }
    case REGISTER_JIS: {
      const { jis, heelWidthJa, instepHeightJa, filter } = action.payload
      return { ...state, jis, heelWidthJa, instepHeightJa}
    }
    case INTRO_ANIMATIONED: {
      return { ...state, introAnimationed: true }
    }
    case SET_SCENE: {
      const { scene } = action.payload
      return { ...state, scene }
    }
    case SET_INPUT_DATA: {
      const { staffName, footId, digitizerCode, visitDate } = action.payload
      return { ...state, staffName, footId, digitizerCode, visitDate }
    }
    case NETWORK_ERROR: {
      const { message } = action.payload
      return { ...state, networkError: message }
    }
    case SET_ALLOW_REFRESH: {
      const { condition } = action.payload
      return { ...state, allowAutoRefresh: condition }
    }
    case GENERATE_3D_DATA_ON_READY: {
      return { ...state, generate3dDataOnReady: true }
    }
  }
  return state
}

// Helpers

// ホームに戻るConfirm画面のテキスト
export const refreshConfirmText = ({
  app: { measurement }
}: {
  app: { measurement: ?Measurement }
}) => {
  return measurement != null
    ? 'TOPに戻ります。<br/>撮影データは保存されません。'
    : 'TOPに戻ります。'
}
