import glConf from './glConf';
import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import glType from './glType';
import glParam from './glParam';
import makingItem from './makingItem';
import makingSceneBase from './makingSceneBase';

export default class makingSceneE extends makingSceneBase {

  constructor(opt) {

    super(opt)

    this._num = undefined
    this._lineNum = undefined
    this._offset = undefined

  }


  init() {

    super.init();

    this._num = 10;
    this._lineNum = 5;

    this._showInterval = 0.01;
    this._jumpInterval = 0;

    for(let i = 0; i < this._num * this._lineNum; i++) {
      this._addItem(i);
    }

    this._items.reverse();

    this._getLastItem().setLast();

    this.resize();

  }


  _startBefore() {

    // 同じ画像にしておく
    this._itemMgr.setAllItem();

  }


  peakAction() {

    this.update();

    this.move({
      delay:0.25
    });

    super.peakAction();

  }



  _eJumped(item) {

    // jump後なにもしない

  }


  _eMoved() {

    this.move({
      delay:0.25
    });

    // だいたい同じタイミングでジャンプ
    this.jump({
      delay:0
    });

    this.opt.loading.jump({
      delay:0
    })

  }


  update() {

    if(!glParam.isRender[glType.SCENE.MAKING] || !this.isUpdate) {
      return;
    }

    super.update();

    const interval = this.sh * 0.35;
    const totalH = interval * this._num * 0;
    const intervalW = this.sw * 0.225;
    const num = this._items.length;
    for(let i = 0; i < num; i++) {
      const item = this._items[i];
      const key = i % this._num;
      const line = ~~(i / this._num);
      let offset = this._offset;
      if(line % 2 != 0) {
        offset *= -1;
        offset -= interval * 0.5;
      }
      item.position.y = key * interval + offset - interval * 4;
      item.position.x = line * intervalW - (intervalW * 4) * 0.5;
      item.setSize(120);
    }

    this._offsetInterval = interval;

    // this._offset += 0.75;

  }


  resize() {

    super.resize();

  }


}
