
class glUpdate {

  constructor() {

    this.cnt = 0;
    this._list = [];

    window.requestAnimationFrame(() => {
      this.update();
    });

  }

  // -----------------------------------------------
  // 実行したい関数を登録
  // @func : 関数
  // -----------------------------------------------
  add(func) {
    this._list.push(func)
    return this._list.length - 1
  }


  // -----------------------------------------------
  // 実行したい関数を削除
  // @func : 関数
  // -----------------------------------------------
  // remove(func) {
  //
  //   const arr = [];
  //   const len = this._list.length;
  //   for(let i = 0; i < len; i++) {
  //     if (this._list[i] !== func) {
  //       arr.push(func);
  //     }
  //   }
  //   this._list = arr;
  //
  // }
  remove(key) {

    this._list[key] = null

  }


  // -----------------------------------------------
  // 更新
  // -----------------------------------------------
  update() {

    this.cnt++;

    // 登録してる関数を実行
    const len = this._list.length;
    for(let i = 0; i < len; i++) {
      if (this._list[i] != null) {
        this._list[i]();
      }
    }

    window.requestAnimationFrame(() => {
      this.update();
    });

  }

}

export default new glUpdate();
