import React from 'react'
import styled from 'styled-components'
import checkOnPng from './img/cart.png'

type Props = {
  dir: 'on' | 'off',
  color?: string
}

function Check({ dir, color, ...rest }: Props) {
  return (
    <Root dir={dir} color={color} {...rest}>
      <img src={checkOnPng} alt="" width={40} height={40} />
    </Root>
  )
}

export default Check

const Root = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow:hidden;
  background-color:${({ theme, dir }) => dir == 'on'? theme.color.yellow: theme.color.grey4};
`
