// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as appActions } from '~/redux/modules/app'
import Footid from '../components/Footid'

const mapStateToProps = state => ({
  staffName: state.app.staffName,
  digitizerCode: state.app.digitizerCode
})

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators({ ...appActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footid)
