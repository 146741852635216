// @flow
import React from 'react'
import SelectButton from '../buttons/ButtonC'
import RemoveButton from '../buttons/ButtonD'
import { formatSize } from '~/helpers/number'

type Props = {
  tag: string,
  label: number,
  onSelect?: () => void,
  onRemove?: () => void
}

const HeelHeightList: React$StatelessFunctionalComponent<Props> = React.memo(
  ({ label, onSelect, onRemove }) => (
    <li>
      {!!onSelect && (
        <SelectButton onClick={onSelect}>{`ヒール高 ${formatSize(
          label
        )} cm`}</SelectButton>
      )}
      {!!onRemove && (
        <RemoveButton onRemove={onRemove}>{`ヒール高 ${formatSize(
          label
        )} cm`}</RemoveButton>
      )}
    </li>
  )
)

export default HeelHeightList
