// @flow
import React from 'react'
import styled from 'styled-components'

import TitlePng from './img/title.png'

type Props = {
  jis: {
    jisSize: string,
    jisWidth: string,
    jisWidthJa: string,
  },
  jisWidthDisplay: boolean
}

function KarteTitle({ jis, jisWidthDisplay,...rest }: Props) {
  return (
    <Root {...rest}>
      <Title>
        <img src={TitlePng} width={231} alt="あなたの足カルテ" />
      </Title>
      <Title2>おすすめのサイズ</Title2>
      <Title2>
        {jis.jisSize} {jis.jisWidthJa} { jisWidthDisplay && `(${jis.jisWidth})`}
      </Title2>
      <Title3>（flicfit独自基準での目安サイズです）</Title3>
    </Root>
  )
}

export default KarteTitle

const Root = styled.div`
  text-align: center;
`

const Title = styled.div`
  margin-bottom: 20px;
`

const Title2 = styled.div`
  font-weight: bold;
  size: 12px;
  margin-top: 6px;
  font-family: ${({ theme }) => theme.font.bold};
`

const Title3 = styled.div`
  color: #666666;
  font-size: 10px;
`
