import React, { Fragment } from 'react'
import styled from 'styled-components'
import { ReviewQnA } from '../../types'


type Props = {
  values: ReviewQnA,
  handleSelect: (value: string ) => () => void,
  width: ?number,
}

function SelectButtons({ values, handleSelect, width,  ...rest }: Props) {
  let select = new Array
  if ( values && 'answer' in values) {
    if ( values.type == 'multi_select' ) {
      select = [...values.answer]
    } else {
      select.push(values.answer)
    }
  }
  return (
    <Root {...rest}>
      {values.options.map(( v, idx) => {
          return (
            <Item
              key={idx}
              onClick={ handleSelect( values.questionId , v)}
              selected={(select.length > 0 && select.includes(v))? true: false}j
              width={width}
            >
              {v}
            </Item>
          )
      })}
    </Root>
  )
}

export default SelectButtons

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
`

const Item = styled.div`
  line-height: 40px;
  background: ${({ theme, selected }) => (selected ? theme.color.yellow : '#fff')};
  padding: 5px 10px;
  border: ${({ theme, selected }) => (selected ? 'none' : '1px solid #ccc')};
  color: #707070;
  border-radius: 25px;
  margin: 5px;
  width: ${({ width }) => (width ? `${width}px` : '200px')};
  text-align: center;
  font-size: 17px;
`