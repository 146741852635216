import glConf from './glConf';
import glParam from './glParam';
import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';


export default class readyRadar extends object3d {

  constructor(opt) {

    super(opt)

    this._img = undefined

    this.isHide = undefined
    this.anm = undefined

  }


  init() {

    super.init();

    this.isHide = true;

    this.anm = {
      alpha:{value:0}
    };

    const tex = glTextures.get(glConf.PATH_IMG + 'ready_radar.png');
    tex.minFilter = tex.magFilter = THREE.NearestFilter

    this._img = new image({
      tex:tex,
      origin:'center'
    })
    this._img.init();
    this.add(this._img);

    this.visible = false;

    this.resize();

  }


  show(opt) {

    this.isHide = false;

    TweenMax.to(this.anm.alpha, 2, {
      value:1,
      delay:opt.delay,
      ease:Power2.easeOut,
      onStart:() => {
        this.visible = true;
        const a = new Date()
        console.log("Radar show start" , a)

      },
      onComplete: () => {
        const a = new Date()
        console.log("Radar show complete" , a)

      }
    })

  }


  hide(opt) {

    this.isHide = true;

    TweenMax.to(this.anm.alpha, 1, {
      value:0,
      delay:opt.delay,
      ease:Power2.easeOut,
      onComplete:() => {
        this.visible = false;
      }
    });

  }


  getNowAngle() {

    return ~~(Math.abs(glUtils.degree(this._img.rotation.z))) % 360;

  }


  update() {

    super.update();

    if(!this.visible) {
      return
    }

    const speed = glParam.ready.speed.value * 0.01;
    this._img.rotation.z -= speed;
    this._img.alpha(glUtils.map(Math.sin(this._cnt * 0.1), 0.5, 1, -1, 1) * this.anm.alpha.value);

  }


  resize() {

    super.resize();

    this._img.setSize(this.sw, this.sw);

  }

}
