// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import Description from './modal/Description'
import IconClose from './iconButtons/Close'
import Slider from 'react-slick'
import ArrowButton from './buttons/ButtonF'
import Thumbnail from './modal/Thumbnail'
import Categories from './modal/Categories'
import Sizelist from './modal/SizeList'
import SliderItem from './modal/SliderItem'
import TryButton from './buttons/ButtonB'
import Button from './buttons/ButtonA'
import { RecommendEntity } from '../types'
import labelFittingPng from './searchResult/img/label-fitting.png'
import labelBestSizePng from '~/assets/img/label-best-size.png'
import noimgPng from '~/assets/img/no_shoes.png'

import Review from './modal/Review'
import ReviewPart from './modal/ReviewPart'
import ReviewComment from './modal/ReviewComment'

// $FlowFixMe
const { fitTags } = process.env.CONFIG

type Props = {
  getSameSizeItemsAndReviews: (itemId: number) =>  any, 
  dataLayerPush: (params: { pageID: string }) => void,
  item: RecommendEntity,
  favorites: Array<number>,
  onClose: () => void,
  handleSelectTag: (handleSelectTag: string, selectedLabel: string) => void,
  handleSelectItems: (items: Array<number> ) =>  () => void,
  dataLayerPush: (params: { pageID: string }) => void,
}

type State = {
  slickSettings: Object,
  slideIndex: number,
  size: Array<any>,
  reviews: ?any,
  selectItems: Array<number>,
  accordionItems: Array<number>,
  shoeFeatures: Array<Object>
}

export default class ProductModal extends Component<Props, State> {
  slider: any

  state = {
    slickSettings: {
      nextArrow: <ArrowButton dir="next" />,
      prevArrow: <ArrowButton dir="prev" />,
      beforeChange: (oldIndex: number, newIndex: number) => {
        this.setState({ slideIndex: newIndex })
      }
    },
    slideIndex: 0,
    size : [],
    reviews: null,
    shoeFeatures: [],
    selectItems:[],
    accordionItems: []
  }
  // 試着リスト（facoriteItems）に追加
  handlefavoriteItems = (itemId: number) => () => {
    console.log("お気に入りに追加する")
    let items = [...this.state.selectItems]
    if (items.includes(itemId) ) {
      items = items.filter(e => e != itemId)
    } else {
      items.push(itemId)
    }
    this.setState({selectItems: items})
  }

  handleAccordionItems = (reviewId: number) => () => {
    let ids = [...this.state.accordionItems]
    if (ids.includes(reviewId) ) {
      ids = ids.filter(e => e != reviewId)
    } else {
      ids.push(reviewId)
    }
    this.setState({accordionItems: ids})
  }

  async componentDidMount() {
   const { size, reviews,  shoeFeatures} = await this.props.getSameSizeItemsAndReviews(this.props.item.itemId)
   
   let selectedItems = []
   size.forEach(e => { 
     if( this.props.favorites.includes(e.itemId) ) selectedItems.push(e.itemId)
   })
   
   if (selectedItems.length == 0 ){
     selectedItems.push(this.props.item.itemId)
   }
    this.props.dataLayerPush({
      pageID: `modal-detail/${this.props.item.itemId}`
    })
    this.setState({ 
      size: size, 
      reviews:reviews, 
      shoeFeatures: shoeFeatures,
      selectItems:  selectedItems })
  }

  render() {
    const { item, favorites, onClose, handleSelectTag, handleSelectItems } = this.props
    const { pictureUri } = item
    const mainPic = pictureUri[0]?pictureUri[0]:noimgPng
    let  empty = (this.state.selectItems.length > 0) ? false : true
    //const pictureUriMainImage = [ pictureUri[0]]
    const pictureUriMainImage = [ mainPic ]

    return (
      <Root>
        <CloseButton onClick={onClose} />
        <StyledDescription item={item} />
        <StyeldCategories
          items={[
            { label: item.targetName, tag: 'targetName', key: item.targetName },
            {
              label: item.categoryName,
              tag: 'categoryName',
              key: item.categoryName
            },
            { label: fitTags[item.fit], tag: 'fit', key: item.fit },
            {
              label: item.strap ? 'ストラップ' : '',
              tag: 'strap',
              key: item.strap
            },
            {
              label: item.shoelace ? '靴紐' : '',
              tag: 'shoelace',
              key: item.shoelace
            },
            { colorId: item.ffColorId, tag: 'ffColorId', key: item.ffColorId }
          ]}
          handleSelectTag={handleSelectTag}
        />
        <CarouselWrapper>
          <Slider
            ref={(slider: any) => (this.slider = slider)}
            {...this.state.slickSettings}
          >
            {pictureUriMainImage.map((picture, idx) => (
              <SliderItem key={idx} picture={picture} />
            ))}
          </Slider>
          <BestSizeWrapper>
            <img src={labelBestSizePng} width={90} height={19} /><br />
            {item.makerSize}{item.unit}
          </BestSizeWrapper>
        </CarouselWrapper>
        {/* 
        <Thumbnails>
          <ul>
            {pictureUri.map((picture, idx) => (
              <li
                key={idx}
                onClick={() => {
                  this.slider.slickGoTo(idx)
                }}
              >
                <Thumbnail
                  src={picture}
                  index={idx}
                  slideIndex={this.state.slideIndex}
                />
              </li>
            ))}
          </ul>
        </Thumbnails>
        */}

        <StyeldSizelist
          size={this.state.size}
          item={item}
          favorites={this.state.selectItems}
          handleSelectSizeTag={this.handlefavoriteItems}
        />


        <ShoeFeature>
        {this.state.shoeFeatures.map ((e, idx ) => (
               <Review key={idx}
                 title={e.title}
                 options={e.options}
                 answer={e.answer}
                 type={e.type}
               />
        ))}
        </ShoeFeature>

        {/* Review--- */}
        {/* 
        { this.state.reviews && this.state.reviews.map(( review, idx ) => {
          if ( review.type == 'fitting_log' ) return false
          const {content : { shoeReview} } = review
          const reviewDisplay = this.state.accordionItems.includes(review.id)

          const tight =shoeReview.find (e => e.questionId == 'tester_review_shoe_8')
          const blister =shoeReview.find (e => e.questionId == 'tester_review_shoe_9')

          const brand =shoeReview.find (e => e.questionId == 'tester_review_shoe_1')
          const size =shoeReview.find (e => e.questionId == 'tester_review_shoe_4')
          const sizeOther =shoeReview.find (e => e.questionId == 'tester_review_shoe_5')
          const memo =shoeReview.find (e => e.questionId == 'tester_review_shoe_7')
          const star =shoeReview.find (e => e.questionId == 'tester_review_shoe_15')

          const testerReviewShoe6 =shoeReview.find (e => e.questionId == 'tester_review_shoe_6')
          const testerReviewShoe11 =shoeReview.find (e => e.questionId == 'tester_review_shoe_11')
          const testerReviewShoe12 =shoeReview.find (e => e.questionId == 'tester_review_shoe_12')
          const testerReviewShoe13 =shoeReview.find (e => e.questionId == 'tester_review_shoe_13')
          const testerReviewShoe14 =shoeReview.find (e => e.questionId == 'tester_review_shoe_14')

          let survey = review.reviewer.survey.map(e => { 
            if ( e.questionId != 'fitting_log_foot_7' ) {
              if ( e.answer ) return `${e.shortTitle}:${e.answer}`
            }
          })
          //足の特徴
          const foot =review.reviewer.survey.find (e => e.questionId == 'fitting_log_foot_7')


        return (
          <ReviewDescription key={idx}>
            <ReviewHead>
              <div><p>スタッフ</p> {survey.join('/').replace("//","/")} </div>
              <div><p>足の特徴</p>{foot.answer.join('/')}</div>
            </ReviewHead>
            <ReviewBody>
              <ReviewLeft>
               <ReviewComment 
                 makerSize={review.item.measurement.makerSize}
                 unit={item.unit}
                 fit={testerReviewShoe6}
                 size={size}
                 sizeOther={sizeOther}
                 memo={memo}
                 star={star}
               />
              {reviewDisplay && (
               <ReviewPart
                 tight={tight}
                 blister={blister}
               />
              )}
              </ReviewLeft>
              {reviewDisplay && (
              <ReviewRight>
               <Review
                 title={testerReviewShoe11.title}
                 options={testerReviewShoe11.options}
                 answer={testerReviewShoe11.answer}
                 type={testerReviewShoe11.type}
               />
               <Review
                 title={testerReviewShoe12.title}
                 options={testerReviewShoe12.options}
                 answer={testerReviewShoe12.answer}
                 type={testerReviewShoe12.type}
               />
               <Review
                 title={testerReviewShoe13.title}
                 options={testerReviewShoe13.options}
                 answer={testerReviewShoe13.answer}
                 type={testerReviewShoe13.type}
               />
               <Review
                 title={testerReviewShoe14.title}
                 options={testerReviewShoe14.options}
                 answer={testerReviewShoe14.answer}
                 type={testerReviewShoe14.type}
               />
              </ReviewRight>
              )}
              {!reviewDisplay && (
              <ReviewRight>
              </ReviewRight>
              )}
              {!reviewDisplay && (
                <ReviewMore>
                  <Button dir="previous" onClick={this.handleAccordionItems(review.id) }>
                    もっとみる
                  </Button>
                </ReviewMore>
              )}
            </ReviewBody>
          </ReviewDescription>
        )
        })}
        */}

        <StyledTryButton disabled={empty} onClick={handleSelectItems(this.state.selectItems)}>
          <img src={labelFittingPng} width={83} height={21} alt="試着する" />
        </StyledTryButton>
      </Root>
    )
  }
}

const Root = styled.div`
  width: 95%;
  height: 95%;
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  padding-bottom: 80px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
`

const CloseButton = styled(IconClose)`
  position: absolute;
  top: 12px;
  right: 12px;
`

const StyledDescription = styled(Description)`
  padding: 25px 25px 0 25px;
`

const CarouselWrapper = styled.div`
  width: 612px;
  height: 306px;
  margin: 25px auto;
  position: relative;
  .slick-track {
    display: flex;
  }
  .slick-slider {
    overflow: hidden;
  }
  .slick-arrow {
    position: absolute;
    top: 50%;
    margin-top: -22px;
  }
  .slick-prev {
    left: -44px;
  }
  .slick-next {
    right: -44px;
  }
`
const BestSizeWrapper = styled.div`
  position: absolute;
  transform: rotate( -5deg );
  font-size: 19px;
  font-weight: bold;
  text-align: center;
  top: ${({ type }) => (type === 0 ? '14px' : '10px')};
  left: ${({ type }) => (type === 0 ? '10px' : '0px')};
  pointer-events: none
`




const Thumbnails = styled.div`
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-left: 20px;
  ul {
    display: flex;
  }
  li {
    margin: 0 5px;
  }
`

const StyeldCategories = styled(Categories)`
  padding: 0 25px;
  margin-top: 10px;
`

const StyeldSizelist = styled(Sizelist)`
  padding: 0 25px;
  margin-top: 10px;
`
const StyledTryButton = styled(TryButton)`
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0)
`

const ShoeFeature = styled.div`
  border: solid 2px #D9D9D9;
  border-radius: 20px;
  margin: 10px 20px;
`

const ReviewDescription = styled.div`
  border: solid 2px #D9D9D9;
  border-radius: 20px;
  overflow: hidden;
  margin: 10px 20px;
`
const ReviewHead = styled.div`
  display: flex;
  background-color: #d9d9d9;
  padding: 5px 10px;
  size: 14px;
  > div {
    padding: 5px 5px;
    width: 50%;
  }
  > div >  p {
    font-weight: bold;
  }
`
const ReviewBody = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const ReviewLeft = styled.div`
  width: 50%;
`
const ReviewRight = styled.div`
  width: 50%;
`
const ReviewMore = styled.div`
  width: 100%;
  text-align: center;
`