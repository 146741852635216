import vert from './shader/readyLine.vert';
import frag from './shader/readyLine.frag';

import vert2 from './shader/makingIcon.vert';
import frag2 from './shader/makingIcon.frag';

import vertDest from './shader/base.vert';
import fragDest from './shader/dest.frag';

import glConf from './glConf';
import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import glType from './glType';
import glParam from './glParam';
import capture from './capture';
import webgl from './index'


export default class makingLoading extends object3d {


  constructor(opt) {

    super(opt);

    this._roter = undefined
    this._wrapper = undefined
    this._container = undefined
    this._line = undefined
    this._edgeA = undefined
    this._edgeB = undefined
    this._bg = undefined

    this._iconScene = undefined
    this._iconCon = undefined
    this._iconCon2 = undefined
    this._icon = undefined
    this._iconDest = undefined

  }


  init() {

    super.init();

    this._roter = new THREE.Object3D();
    this.add(this._roter);

    this._wrapper = new THREE.Object3D();
    this._roter.add(this._wrapper);

    this._bg = new THREE.Mesh(
      new THREE.CircleBufferGeometry(0.5, 32),
      new THREE.MeshBasicMaterial({color:0xffffff})
    );
    this._wrapper.add(this._bg);
    this._bg.scale.set(200, 200, 1);

    this._container = new THREE.Object3D();
    this._wrapper.add(this._container);

    this._edgeA = new THREE.Mesh(
      new THREE.CircleBufferGeometry(0.5, 32),
      new THREE.MeshBasicMaterial({color:0xff3100})
    );
    this._container.add(this._edgeA);

    this._edgeB = new THREE.Mesh(
      new THREE.CircleBufferGeometry(0.5, 32),
      new THREE.MeshBasicMaterial({color:0xfc6b15})
    );
    this._container.add(this._edgeB);

    const edgeSize = 12;
    this._edgeA.scale.set(edgeSize, edgeSize, 1);
    this._edgeB.scale.set(edgeSize, edgeSize, 1);

    this._line = new THREE.Mesh(
      this._makeGeo(),
      new THREE.ShaderMaterial({
        transparent:true,
        vertexShader:vert,
        fragmentShader:frag,
        uniforms:{
          alpha:{value:1}
        }
      })
    );
    this._container.add(this._line);

    this._iconScene = new capture();
    this._iconScene.init();

    this._iconDest = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(1, 1),
      new THREE.ShaderMaterial({
        transparent:true,
        vertexShader:vertDest,
        fragmentShader:fragDest,
        uniforms:{
          tDiffuse:{value:this._iconScene.texture()},
          alpha:{value:1}
        }
      })
    );
    this.add(this._iconDest);

    // ぐるぐる
    TweenMax.to(this._roter.rotation, 1.5, {
      z:glUtils.radian(-360),
      ease:Power4.easeInOut,
      repeat:-1
    });

    // this.visible = false;

    this._load();

    this.resize();

  }


  _load() {

    const loader = new THREE.PLYLoader()
    loader.load(glConf.PATH_IMG + 'making.ply', (geometry) => {
      // if(this.opt == null) {
      //   return
      // }

      this._makeIconMesh(geometry)

      // geometry.computeVertexNormals()
      // this._icon = new THREE.Mesh(
      //   geometry,
      //   new THREE.MeshBasicMaterial({
      //     color:0x21bafc,
      //     side:THREE.DoubleSide,
      //     transparent:true
      //   })
      // )
      // this.add(this._icon)
      this._icon.position.z = 10

      const scale = 0.475
      this._icon.scale.set(scale, scale, scale)

    })

  }


  _makeIconMesh(data) {

    const geometry = new THREE.InstancedBufferGeometry();
    geometry.copy(new THREE.CircleBufferGeometry(1, 8));

    const arr = data.attributes.position.array
    const num = data.attributes.position.count
    const translate = new Float32Array(num * 3);

    const t = new THREE.Vector3(0, 0, 0);

    for(let i = 0; i < num; i++) {

      const x = Number(arr[i*3+0]);
      const y = Number(arr[i*3+1]);
      const z = Number(arr[i*3+2]);

      translate[i*3+0] = x;
      translate[i*3+1] = y;
      translate[i*3+2] = z;

    }

    geometry.addAttribute('translate', new THREE.InstancedBufferAttribute(translate, 3, false, 1));

    const material = new THREE.RawShaderMaterial({
      uniforms:{
        color:{value:new THREE.Color(0x21bafc)},
        alpha:{value:1}
      },
      vertexShader:vert2,
      fragmentShader:frag2,
      side:THREE.DoubleSide,
      transparent:true,
      depthTest:true
    })

    this._iconCon = new THREE.Object3D()
    this._iconScene.add(this._iconCon)

    this._iconCon2 = new THREE.Object3D()
    this._iconCon.add(this._iconCon2)

    this._icon = new THREE.Mesh(geometry, material)
    this._iconCon2.add(this._icon)
    // this._icon.rotation.x = glUtils.radian(20)

    // this._icon.rotation.z = glUtils.radian(-20)
    this._icon.rotation.y = glUtils.radian(-90)

    // ぐるぐる
    TweenMax.to(this._iconCon.rotation, 1.5, {
      y:glUtils.radian(-360),
      // z:glUtils.radian(-360),
      ease:Power4.easeInOut,
      repeat:-1,
      repeatDelay:2
    });

  }


  _guruguruIcon() {

    // TweenMax.killTweensOf(this.rotation);
    // this.rotation.z = 0;
    // TweenMax.to(this.rotation, opt.duration, {
    //   z:glUtils.radian(-360),
    //   ease:Power4.easeInOut
    // });

  }


  guruguru(opt) {

    // TweenMax.killTweensOf(this.rotation);
    // this.rotation.z = 0;
    // TweenMax.to(this.rotation, opt.duration, {
    //   z:glUtils.radian(-360),
    //   ease:Power4.easeInOut
    // });

  }


  show(opt) {

    this.scale.set(glConf.MIN_SCALE, glConf.MIN_SCALE, 1)
    TweenMax.to(this.scale, 1.5, {
      x:1,
      y:1,
      ease:Elastic.easeOut.config(1, 0.7),
      delay:opt.delay,
      onStart:() => {
        this.visible = true;
      },
      onComplete:() => {
        opt.onComplete();
      }
    });

  }


  hide(opt) {

    TweenMax.to(this.scale, 0.75, {
      x:glConf.MIN_SCALE,
      y:glConf.MIN_SCALE,
      ease:Back.easeIn.config(1),
      onComplete:() => {
        this.visible = false
        if(opt.onComplete != null) {
          opt.onComplete()
        }
      }
    });

  }


  jump(opt) {

    this.guruguru({
      duration:1.1
    });

    TweenMax.killTweensOf(this.scale);

    const s = 1.3;
    TweenMax.to(this.scale, 0.4, {
      x:s,
      y:s,
      ease:Back.easeIn,
      delay:opt.delay,
      onComplete: () => {
        TweenMax.to(this.scale, 0.8, {
          x:1,
          y:1,
          ease:Elastic.easeOut.config(1, 0.8),
          onComplete:() => {
            if(opt.onComplete != null) {
              opt.onComplete(this);
            }
          }
        });
      }
    })

  }


  jump2(opt) {

    this.guruguru({
      duration:1.25
    });

    TweenMax.killTweensOf(this._wrapper.scale);
    TweenMax.killTweensOf(this._wrapper.rotation);

    const s = 1.75;
    TweenMax.to(this._wrapper.scale, 0.4, {
      x:s,
      y:s,
      ease:Back.easeIn,
      delay:opt.delay,
      onComplete: () => {
        TweenMax.to(this._wrapper.scale, 0.8, {
          x:1,
          y:1,
          ease:Elastic.easeOut.config(1, 0.8),
          onComplete:() => {
            if(opt.onComplete != null) {
              opt.onComplete(this);
            }
          }
        });
      }
    })

    // this._wrapper.rotation.z = glUtils.radian(-360)
    // TweenMax.to(this._wrapper.rotation, 1.5, {
    //   z:0,
    //   ease:Back.easeIn,
    //   delay:opt.delay
    // })

  }


  update() {

    if(!glParam.isRender[glType.SCENE.MAKING] || !this.visible) {
      return;
    }

    super.update();

    this._container.rotation.z -= 0.02;

    this._line.geometry.dispose();
    this._line.geometry = this._makeGeo();

    if(this._icon != null && this._cnt % 1 == 0) {
      // this._iconCon2.rotation.x = glUtils.radian(glUtils.degree(this._iconCon2.rotation.y) + 90)
      this._iconCon2.rotation.y -= 0.01
      // this._icon.rotation.z -= -0.009

      this._icon.rotation.z = glUtils.radian(glUtils.map(Math.sin(this._cnt * 0.05), -30, 5, -1, 1))

      webgl.renderer.setClearColor(0xffffff, 0);
      this._iconScene.render(webgl.renderer, webgl.camera);
    }

  }


  _makeGeo() {

    const arr = [];
    const radius = 100;
    const num = 20;
    const radian = glUtils.radian(glUtils.degree(Math.abs(this.rotation.z)) * 0.5);
    const size = glUtils.map(Math.sin(radian), 20, 160, -1, 1)
    for(let i = 0; i < num; i++) {
      const radian = glUtils.radian(i * (size / num));
      arr.push(new THREE.Vector3(
        Math.sin(radian) * radius,
        Math.cos(radian) * radius,
        0
      ));
    }

    this._edgeA.position.set(
      arr[0].x,
      arr[0].y,
      arr[0].z
    )

    this._edgeB.position.set(
      arr[arr.length - 1].x,
      arr[arr.length - 1].y,
      arr[arr.length - 1].z
    )

    const path = new THREE.CatmullRomCurve3(arr);
    const geo = new THREE.TubeBufferGeometry(path, 16, 7, 6, false);

    const color = [];
    const len = geo.attributes.position.count;
    for(let i = 0; i < len; i++) {

      let colorA = new THREE.Color(0xff3100);
      let colorB = new THREE.Color(0xfc6b15);

      colorA = colorA.lerp(colorB, glUtils.map(i, 0, 1, 0, len));

      color.push(colorA.r);
      color.push(colorA.g);
      color.push(colorA.b);
      color.push(1);
    }

    const colors = new Float32Array(color);
    geo.addAttribute('color', new THREE.BufferAttribute(colors, 4));

    return geo;

  }


  resize() {

    super.resize();

    this._iconScene.size(this.sw, this.sh);
    this._iconDest.scale.set(this.sw, this.sh, 1);

    this._iconDest.position.y = -15

  }





}
