// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as appActions } from '../redux/modules/app'
import Purchase from '../components/Purchase'
import { selecteFavorites } from '~/redux/modules/recommend'
import { actions as recommendActions } from '~/redux/modules/recommend'

const mapStateToProps = state => ({
  favoriteItems: selecteFavorites(state),
  reviews: state.recommend.reviews,
  unknownItems: state.recommend.unknownItems

})

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators({ ...appActions }, dispatch),
  recommendActions: bindActionCreators({ ...recommendActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Purchase)
